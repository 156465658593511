

import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_HEADER, get_ta_tr_experts } from "../../config";
import { get_assesment_url, team_change_url, tm_team_change_url, get_project_team_info, BASE_DOCUMENT, get_sectoralscope_url } from "../../config";
import {
    get_project_details_url,
    edit_assessment_team_url,
} from "../../config";
import { get_trsbu_url } from "../../config";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Form, Tabs, Upload, message, Input, Alert } from "antd";
import { MultiSelect } from "react-multi-select-component";

const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM",
];


const TeamChangeDisable = ({teamId}) => {
    const { id } = useParams();
console.log(teamId)
    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size

    const navigate = useNavigate();

    const userdata = JSON.parse(sessionStorage.getItem("user"));
    const designation_id = sessionStorage.getItem("designation_id");
    const [projectid, setProjectId] = useState(null);
    const [atlist, setAtList] = useState([]);
    const [trlist, setTRList] = useState([]);
    const [isTL, setIsTL] = useState(false);
    const [isAT, setIsAT] = useState(false);
    const [member, setMember] = useState();
    const [projectstatus, setProjectStatus] = useState(null);
    const [status, SetStatus] = useState(null);

    const [teamLeader, setTeamLeader] = useState("");
    const [taExpert, setTAExpert] = useState("");
    const [validator, setValidator] = useState("");
    const [financeExpert, setFinanceExpert] = useState("");
    const [localExpert, setLocalExpert] = useState("");
    const [methExpert, setMethExpert] = useState("");
    const [traineeExpert, setTraineeExpert] = useState("");
    const [technicalReviewer, setTechnicalReviewer] = useState("");
    const [teamChangeDoc, setTeamChangeDoc] = useState([]);
    const [action, setAction] = useState('')
    const [tlRemarks, setTlRemarks] = useState('')
    const [teamChangeStatus, setTeamChangeStatus] = useState('')

    const [taTrExpert, setTATRExpert] = useState([]);
    const [mytaexpert, setMyTaexpert] = useState([]);
    const [myexperttr, setMyExpertTr] = useState([]);
    const [trExpert, setTRExpert] = useState([]);
    const [selectedSector, setSelectedSector] = useState('')
    const [mysectorscope, setMySectorScope] = useState([]);

    const [fileteamChangeDocSizeError, SetFileteamChangeDocSizeError] = useState(false)
    const [teamDocName, setTeamDocName] = useState(null);
    const [teamDoc, setTeamDoc] = useState("");
    const [record, setRecord] = useState("");
    const [sbuRemarks, setSbuRemarks] = useState('')
    const [tmsRemarks, setTmsRemarks] = useState('')
    const [tmRemarks, setTmRemarks] = useState('')
    const [apiData, setAPIData] = useState('')

    const [formData, setFormData] = useState({
        team_leader: "",
        ta_expert: "",
        program: '',
        validator_verifier: "",
        finance_expert: "",
        local_expert: "",
        meth_expert: "",
        trainee_validator: "",
        technical_reviewer: "",
        expert_tr: "",
        sbu_head: "",
        pdd_documents: "",
        er_documents: "",
        mr_documents: "",
        evidence_name: [],
    });


    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_team_info}/${ids}?team_id=${teamId}`,
                API_HEADER
            );
            
            // setAPIData(response.data.record)
            // setFormData({
            //     program: response.data.record.program
            // })

            // const sectorscopes = response.data.record.sectoral_scope?.split(",").map(Number);
            // const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
            //     sectorscopes?.includes(sectorscope.value)
            // );
            // setSelectedSector(filteredSectorScopes);

            const record1 = response.data.record.program

            setTeamChangeStatus(response.data.record.team_change_status)

            if (response && response.data && response.data.teamsummary) {
                const record = response.data.teamsummary

                setProjectId(record.project_id);
                setAPIData(response.data.record)

                const responseteamleader = await axios.get(
                    `${get_assesment_url}?program_id=${record1}`
                );
                setRecord(record)
// console.log("ddddd",record)
                setAtList(responseteamleader.data.data);

                setTeamLeader(record.team_leader);
                setTAExpert(record.ta_expert);
                setValidator(record.validator_verifier);
                setFinanceExpert(record.finance_expert);
                setLocalExpert(record.local_expert);
                setMethExpert(record.meth_expert);
                setTraineeExpert(record.trainee_validator);
                setTechnicalReviewer(record.technical_reviewer)
                setTlRemarks(record.tl_team_remarks)
                setMember(record.team_change_status)
                setSbuRemarks(record.team_sbu_remarks)
                setTmRemarks(record.tm_team_remarks)
                setTmsRemarks(record.team_tms_remarks)

                let url8 = `${BASE_DOCUMENT}/projects/${id}/${record.team_change_document}`;
                setTeamDoc(url8);
                setTeamDocName(record.team_change_document);

                setFormData({
                    expert_tr: record.expert_tr,
                    status: record.status,
                    program:record.program
                });

                const isTeamLeader = record.team.some((item) => item.role_id == 1);
                setIsTL(isTeamLeader);

                const isTeamMember = record.team.some((item) => item.role_id > 1);
                setIsAT(isTeamMember);

                setProjectStatus(record.project_status);
                SetStatus(record.status);

            }
        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    useEffect(() => {
        if (apiData) {
        //   const scopes = apiData.scope.split(",").map(Number);
        //   const filteredScopes = myscope.filter((scope) =>
        //     scopes.includes(scope.value)
        //   );
        //   setSelected(filteredScopes);
    
    
          const sectorscopes = apiData.sectoral_scope?.split(",").map(Number);
          const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
            sectorscopes.includes(sectorscope.value)
          );
          setSelectedSector(filteredSectorScopes);
    
        }
      }, [apiData,  mysectorscope])


    useEffect(() => {
        const fetchDataTrData = async () => {
            try {
                const responsetrdata = await axios.get(`${get_trsbu_url}`);
                setTRList(responsetrdata.data.data);
            } catch (error) { }
        };

        fetchDataTrData();
    }, []);

    useEffect(() => {
        const fetchSectoralScope = async () => {
            try {
                const responsesectoralscope = await axios.get(
                    `${get_sectoralscope_url}`
                );

                setMySectorScope(
                    responsesectoralscope.data.data.map((scope) => ({
                        value: scope.id,
                        label: scope.scope,
                    }))
                );
            } catch (error) { }
        };

        fetchSectoralScope();
    }, []);

// console.log("fff",formData.program)
    useEffect(() => {
        const fetchDataTATRExpert = async () => {
            try {
                const responsetatrexpert = await axios.get(
                    `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=7`
                );
                setTATRExpert(responsetatrexpert.data.data.map((taexpert) => ({
                    value: taexpert.id,
                    label: taexpert.name,
                }))
                )

            } catch (error) { }
        };

        if (formData.program && selectedSector.length > 0) {
            fetchDataTATRExpert();
        }
    }, [formData.program, selectedSector]);

    useEffect(() => {
        if (apiData && taTrExpert.length > 0) {
            const taExpert = record.ta_expert?.split(",").map(Number);
            const filteredTaExpert = taTrExpert.filter((taexpert) =>
                taExpert?.includes(taexpert.value)
            );
            setMyTaexpert(filteredTaExpert);
        }
    }, [apiData, taTrExpert]);


    useEffect(() => {
        const fetchDataTRExpert = async () => {
            try {
                // console.log(formData.program,selectedSector)
                const responsetatrexperts = await axios.get(
                    `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=8`
                );
                setTRExpert(responsetatrexperts.data.data.map((trexpert) => ({
                    value: trexpert.id,
                    label: trexpert.name,
                }))
                )
            } catch (error) { }
        };
        // console.log(formData.program,selectedSector)

        if (formData.program && selectedSector.length > 0) {
            fetchDataTRExpert();
        }
    }, [formData.program, selectedSector]);

    useEffect(() => {
        if (apiData && trExpert.length > 0) {
            const trExperts = record.expert_tr?.split(",").map(Number);
            const filteredTrExpert = trExpert.filter((taexpert) =>
                trExperts?.includes(taexpert.value)
            );
            setMyExpertTr(filteredTrExpert);
        }
    }, [apiData, trExpert]);

    return (
        <div>
            <fieldset disabled={true}>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="teamleader"
                            className="form-label lightgreen fs-6"
                        >
                            Team Leader
                            <span style={{ color: "red" }}>*</span>
                        </label>

                        <select
                            id="team_leader"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="team_leader"
                            required
                            value={teamLeader}
                            readOnly={true}
                            disabled={true}
                        >
                            <option selected value={""}>
                                Select
                            </option>
                            {atlist
                                .filter((option) => option.skill_id === 1)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="taexpert"
                            className="form-label lightgreen fs-6"
                        >
                            TA Expert
                            <span style={{ color: "red" }}>*</span>
                        </label>

                        <MultiSelect
                            options={taTrExpert}
                            value={mytaexpert}
                            labelledBy="Select"
                            className="multi"
                        />


                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="teamleader"
                            className="form-label lightgreen fs-6"
                        >
                            Local Expert
                            <span style={{ color: "red" }}>*</span>
                        </label>

                        <select
                            id="local_expert"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="local_expert"
                            value={localExpert}
                            readOnly={true}
                            disabled={true}
                        >
 
                           <option value={""}>To be added later</option>

                            {atlist
                                .filter((option) => option.skill_id === 6)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="financeexpert"
                            className="form-label lightgreen fs-6"
                        >
                            Finance Expert
                        </label>

                        <select
                            id="finance_expert"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="finance_expert"
                            value={financeExpert}
                            readOnly={true}
                            disabled={true}
                        >
                            <option selected value={""}>
                                Select
                            </option>
                            {atlist
                                .filter((option) => option.skill_id === 4)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>


                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="validator"
                            className="form-label lightgreen fs-6"
                        >
                            Validator/verifier
                        </label>

                        <select
                            id="validator_verifier"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="validator_verifier"
                            value={validator}
                            readOnly={true}
                            disabled={true}
                        >
                            <option selected value={""}>
                                Select
                            </option>
                            {atlist
                                .filter((option) => option.skill_id === 2)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="methexpert"
                            className="form-label lightgreen fs-6"
                        >
                            Meth Expert
                        </label>

                        <select
                            id="meth_expert"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="meth_expert"
                            value={methExpert}
                            readOnly={true}
                            disabled={true}
                        >
                            <option selected value={""}>
                                Select
                            </option>
                            {atlist
                                .filter((option) => option.skill_id === 5)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>



                </div>

                <div className="row">

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="taexpert"
                            className="form-label lightgreen fs-6"
                        >
                            Trainee Validator/verifier
                        </label>

                        <select
                            id="trainee_validator"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="trainee_validator"
                            value={traineeExpert}
                            readOnly={true}
                            disabled={true}
                        >
                            <option selected value={""}>
                                Select
                            </option>
                            {atlist
                                .filter((option) => option.skill_id == 3)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="technicalreviewer"
                            className="form-label lightgreen fs-6"
                        >
                            Technical Reviewer
                            <span style={{ color: "red" }}>*</span>
                        </label>

                        <select
                            id="technical_reviewer"
                            className="form-select borderlightgreen form-select-sm"
                            aria-label="Default select example"
                            name="technical_reviewer"
                            disabled={true}
                            value={technicalReviewer}
                        >
                            <option selected value={""}>
                                Select
                            </option>
                            {trlist
                                .filter(
                                    (option) => option.designation_id == 7
                                )
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>


                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                        <label
                            htmlFor="technicalreviewer"
                            className="form-label lightgreen fs-6"
                        >
                            Expert to Technical Reviewer
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <MultiSelect
                            options={trExpert}
                            value={myexperttr}
                            onChange={setMyExpertTr}
                            labelledBy="Select"
                            className="multi"
                        />
                    </div>

                </div>
            </fieldset>


            <div className="row">
                <div className="col-6 my-4 ">
                    <label
                        htmlFor="Member"
                        className="form-label lightgreen mb-4 fs-6"
                    >
                        Send To
                        <span style={{ color: "red" }}>*</span>
                    </label>

                    <select
                        id="member"
                        className="form-select borderlightgreen form-select-sm"
                        aria-label="Default select example"
                        name="status"
                        value={member}
                        disabled
                        onChange={(e) => {
                            setMember(e.target.value);
                        }}
                    >
                        <option value=''>Select</option>

                        <option value={2}>Send to Team Members</option>
                        <option value={4}>Send to TM Support</option>
                        <option value={1}>SBU Sent to TL</option>
                        <option value={3}>Team Members Send to TL</option>
                        <option value={5}>TM Support Send to TL</option>
                        <option value={6}>TM support Send to TM</option>
                        <option value={7}>TM Sent To TM Support</option>
                        <option value={8}>TM approved</option>


                    </select>
                </div>
            </div>

            {
                // teamChangeStatus >= 2 ?
                tlRemarks &&

                    <div className="row mt-4">
                        <div>
                            <label className="form-label lightgreen fs-6 mb-3">
                                Team Leader Remarks<span style={{ color: "red" }}>*</span>
                            </label>
                            <ReactQuill
                                theme="snow"
                                value={tlRemarks}
                                name="remarks"
                                readOnly={true}
                                style={{
                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>
                  
            }

            {
                // teamChangeStatus >= 1 && sbuRemarks != null ?
                sbuRemarks &&
                    <div className="row mt-4">
                        <div>
                            <label className="form-label lightgreen fs-6 mb-3">
                                SBU Remarks<span style={{ color: "red" }}>*</span>
                            </label>
                            <ReactQuill
                                theme="snow"
                                value={sbuRemarks}
                                name="remarks"
                                readOnly={true}
                                style={{
                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>
                   
            }

            {
                // teamChangeStatus == 5 || teamChangeStatus == 6 ?
                tmsRemarks &&

                    <div className="row my-4">
                        <div>
                            <label className="form-label lightgreen fs-6 ">
                                TM Support Remark
                            </label>
                            <hr className="lightgreen" />
                            {teamChangeStatus == 6 || teamChangeStatus ==5 ?
                            <div className="row  my-4">
                                <span >
                                    {teamChangeStatus == 6 ? (
                                        <Alert className="col-12" message="Approved" type="success" showIcon />
                                    ) : teamChangeStatus == 5 ? (
                                        <Alert className="col-12" message="Clarification Required" banner />
                                    ) : (
                                        ''
                                    )}
                                </span>

                            </div>
                            :""}
                            <ReactQuill
                                theme="snow"
                                value={tmsRemarks}
                                name="remarks"
                                readOnly={true}
                                style={{

                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>

                   
            }


            {
                // teamChangeStatus == 7 || teamChangeStatus == 8 ?
                tmRemarks &&
                    <div className="row my-4">
                        <div>
                            <label className="form-label lightgreen fs-6 mb-3">
                                TM Remark
                            </label>
                            <hr className="lightgreen" />
                            <div className="row  my-4">
                                <span >
                                    {teamChangeStatus == 8 ? (
                                        <Alert className="col-12" message="Approved" type="success" showIcon />
                                    ) : teamChangeStatus == 7 ? (
                                        <Alert className="col-12" message="Clarification Required" banner />
                                    ) : (
                                        ''
                                    )}
                                </span>

                            </div>

                            <ReactQuill
                                theme="snow"
                                value={tmRemarks}
                                name="remarks"
                                readOnly={true}
                                style={{

                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>

                   
            }

        </div>
    )
}

export default TeamChangeDisable
