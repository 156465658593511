import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, dvr_report_url, tl_rfi_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import MultipleFileUploader from "../Pages/MultipleFileUploader";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";



import {
    API_HEADER,

} from "../config";



const ATRFI = (props) => {

    const { id } = useParams();
    const { isTL } = props;

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size



    const [remark, setRemark] = useState("")
    const [remarkTLRFI, setRemarkTLRFI] = useState("")
    const [remarkATRFI, setRemarkATRFI] = useState("")

    const [rfiStatus, setRfiStatus] = useState("")
    const [tmRfiStatus, setTmRfiStatus] = useState("")


    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');
    const [file4, setFile4] = useState('');
    const [file5, setFile5] = useState('');
    const [fileFVRSizeError, setFileFVRSizeError] = useState(false);

    const [fvrName, setFVRName] = useState(null)
    const [fvr, setFvr] = useState("")
    const [pddName, setPDDName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setERName] = useState(null);
    const [er, setER] = useState("");
    const [verificationDeedName, setVerificationDeedName] = useState(null);
    const [verificationDeed, setVerificationDeed] = useState("");
    const [validationDeedName, setValidationDeedName] = useState(null);
    const [validationDeed, setValidationDeed] = useState("");
    const [status, setStatus] = useState('')


    const [remarkTMRFI, setRemarkTMRFI] = useState("")
    const [remarkMD, setRemarkMD] = useState("")

    const [registrarRFIDateTo, setRegistrarRFIDateTo] = useState(null)
    const [registrarRFIRemark, setRegistrarRFIRemark] = useState(null)


    const [loading, setLoading] = useState(false);
    const [member, setMember] = useState();

    const [otherDocFile, setOtherDocFile] = useState([]);
    const [otherDocName, setOtherDocName] = useState(null);
    const [otherDoc, setOtherDoc] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);






    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${ids}`,
                API_HEADER
            );
            const { record } = response.data;



            setRfiStatus(record.status)
            setRemarkTLRFI(record.rfi_tlremarks)
            // setRemarkATRFI(record.rfi_at_remarks)
            setRemarkATRFI((record.status == 13 || record.status == 15 || record.status == 17 || record.status == 19) ? record.rfi_at_remarks == "" : record.rfi_at_remarks)

            setRemarkTMRFI(record.rfi_tmremarks)
            setRemarkMD(record.rfi_mdremarks)
            setTmRfiStatus(record.rfi_tmaction)
            setRegistrarRFIDateTo(record.rfi_registrar_uploading_date)
            setRegistrarRFIRemark(record.rfi_registrar_remarks)
            setStatus(record.status)
            setMember(record.status)
            setFile1(record.fvr_report)




            // setAction(record.status)


            let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.fvr_report}`;
            setFVRName(url1);
            setFvr(record.fvr_report);

            let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
            setERName(url2);
            setER(record.tr_er_doc);


            let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
            setPDDName(url3);
            setPdd(record.tr_pdd_doc);

            let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
            setVerificationDeedName(url4);
            setVerificationDeed(record.verification_deed);


            let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
            setValidationDeedName(url5);
            setValidationDeed(record.validation_deed);

            let otherDocsUrls = record.rfi_other_docs.split(',').map(doc => `${BASE_DOCUMENT}/projects/${record.id}/${doc.trim()}`);

            console.log("otherDocsUrls", otherDocsUrls)
            let otherDocsNames = record.rfi_other_docs.split(',');
            console.log("otherDocsNames", otherDocsNames)


            setOtherDocName(otherDocsUrls);
            setOtherDoc(otherDocsNames);


        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const combined = otherDoc?.map((name, index) => ({
        name: name,
        url: otherDocName[index]
    }));


    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };


    const handleRemarksChange = (content) => {
        setRemarkATRFI(content);
    };

    const handleNewOtherDocChange = (file) => {
        setOtherDocFile(file);
    };

    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(false);

        // if (remarkATRFI == null || remarkATRFI == '<p><br></p>' || remarkATRFI == '') {
        //     toast.error('Please Add Some Remarks')
        // }
        if ((remarkATRFI == null || remarkATRFI == '<p><br></p>' || remarkATRFI == '') || (file1 == null || file1 == "")) {
            toast.error('Please upload the document and remarks.')
        }
        else {
            try {
                // setLoading(true); // Show loader
                setIsSubmitting(true);

                const formDataToSend = new FormData();
                formDataToSend.append("project_id", id);
                formDataToSend.append("fvr_report", file1);
                formDataToSend.append("tr_er_doc", file2);
                formDataToSend.append("tr_pdd_doc", file3);
                formDataToSend.append("verification_deed", file4);
                formDataToSend.append("validation_deed", file5);
                formDataToSend.append("rfi_at_remarks", remarkATRFI);
                formDataToSend.append("status", member);
                for (let i = 0; i < otherDocFile.length; i++) {
                    formDataToSend.append("rfi_other_docs[]", otherDocFile[i]);
                }




                const response = await axios.post(
                    `${tl_rfi_url}`,
                    formDataToSend,
                    CONFIG_Token2
                );

                if (!response.data.status) {
                    toast.error(response.data.message);
                } else if (
                    fileFVRSizeError
                ) {
                    toast.error(`File size must below ${dynamicMaxSize}MB`);
                } else {
                    toast.success("Document Submitted Successfully");
                    setFile1(null);

                    setRemarkTLRFI("")
                    navigate("/dashboard");
                }
            } catch (error) {
                toast.error("Error occurred while submitting data");
            } finally {
                // setLoading(false); // Hide loader
                setIsSubmitting(false);

            }
        }
    };



    return (

        <>
            {
                (!isTL && (rfiStatus == 13 || rfiStatus == 15 || rfiStatus === 17 || rfiStatus === 19)) ?
                    <>
                        <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
                        <form
                            method="post"
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                        >
                            <table className="table table-bordered  table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            S.No
                                        </th>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            Document Name
                                        </th>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                            Upload Document
                                        </th>
                                        {
                                            rfiStatus === 17 || rfiStatus === 19 || rfiStatus === 13 || rfiStatus === 15 ?
                                                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                                    Uploaded Document
                                                </th>
                                                :
                                                ""
                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">1</td>
                                        <td className="text-center lightgreen p-3">
                                            FVR Report
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <FileUploaderComponent file={file1} setFile={setFile1} />

                                        {
                                            rfiStatus === 17 || rfiStatus === 19 || rfiStatus === 13 || rfiStatus === 15 ?
                                                <td>
                                                    <a href={fvrName} target="_blank">{fvr}</a>
                                                </td>
                                                :
                                                ''
                                        }

                                    </tr>

                                    <tr>
                                        <td class="text-center">2</td>


                                        <td className="text-center lightgreen p-3">
                                            ER
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <FileUploaderComponent file={file2} setFile={setFile2} />

                                        {
                                            rfiStatus === 17 || rfiStatus === 19 || rfiStatus === 13 || rfiStatus === 15 ?
                                                <td>
                                                    <a href={erName} target="_blank">{er}</a>
                                                </td>
                                                :
                                                ''
                                        }

                                    </tr>

                                    <tr>
                                        <td class="text-center">3</td>

                                        <td className="text-center lightgreen p-3">
                                            PDD/MR
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <FileUploaderComponent file={file3} setFile={setFile3} />

                                        {
                                            rfiStatus === 17 || rfiStatus === 19 || rfiStatus === 13 || rfiStatus === 15 ?
                                                <td>
                                                    <a href={pddName} target="_blank">{pdd}</a>
                                                </td>
                                                :
                                                ''
                                        }

                                    </tr>



                                    <tr>
                                        <td class="text-center">4</td>

                                        <td className="text-center lightgreen p-3">
                                            Verification Deed
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <FileUploaderComponent file={file4} setFile={setFile4} />

                                        {
                                            rfiStatus === 17 || rfiStatus === 19 || rfiStatus === 13 || rfiStatus === 15 ?
                                                <td>
                                                    <a href={verificationDeedName} target="_blank">{verificationDeed}</a>
                                                </td>
                                                :
                                                ''
                                        }

                                    </tr>

                                    <tr>
                                        <td class="text-center">5</td>

                                        <td className="text-center lightgreen p-3">
                                            Validation Deed
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <FileUploaderComponent file={file5} setFile={setFile5} />

                                        {
                                            rfiStatus === 17 || rfiStatus === 19 || rfiStatus === 13 || rfiStatus === 15 ?
                                                <td>
                                                    <a href={validationDeedName} target="_blank">{validationDeed}</a>
                                                </td>
                                                :
                                                ''
                                        }

                                    </tr>

                                    <tr>
                                        <td class="text-center">6</td>
                                        <td className="text-center lightgreen p-3">
                                            Other Docs
                                        </td>

                                        <MultipleFileUploader
                                            dynamicMaxSize={dynamicMaxSize}
                                            onFilesChange={handleNewOtherDocChange}
                                        />
                                        <td>
                                            <div>
                                                {combined.map((item) => (
                                                    <div>
                                                        <a target="_blank" href={item.url}>
                                                            {item.name}

                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <div className="col-4 my-4">
                                <label
                                    htmlFor="Member"
                                    className="form-label lightgreen mb-4 fs-6"
                                >
                                    Send To
                                    <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                    id="member"
                                    className="form-select borderlightgreen form-select-sm"
                                    aria-label="Default select example"
                                    name="status"
                                    value={member}
                                    required
                                    onChange={(e) => {
                                        setMember(e.target.value);
                                    }}
                                >
                                    <option value=''>Select</option>
                                    <option value={14}>Forward to TL</option>

                                </select>
                            </div>

                            {/* <div style={{ marginTop: '50px' }}>
                            {
                                rfiStatus < 16 ?
                                    (
                                        <div style={{ marginTop: '50px' }}>
                                            <p className="lightgreen fw-bold  fs-6">
                                                Team Remarks<span style={{ color: "red" }}>*</span>
                                            </p>
                                            <hr className="mb-3 lightgreen" />
                                            <div>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={remarkATRFI}
                                                    name="remarks"
                                                    dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                                                    onChange={handleRemarksChange}
                                                    style={{

                                                        height: "20vh",
                                                        marginBottom: "2rem",
                                                    }}
                                                    className="mb-5"
                                                />
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        rfiStatus === 17 ?
                                            (
                                                <>
                                                    <div style={{ marginTop: '50px' }}>
                                                        <p className="lightgreen fw-bold my-3 fs-6">
                                                            Team Remarks<span style={{ color: "red" }}>*</span>
                                                        </p>
                                                        <hr className="mb-3 lightgreen" />
                                                        <div>
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={remarkATRFI}
                                                                name="remarks"
                                                                dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                                                                onChange={handleRemarksChange}
                                                                style={{

                                                                    height: "20vh",
                                                                    marginBottom: "2rem",
                                                                }}
                                                                className="mb-5"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="lightgreen fw-bold my-3 fs-6">
                                                            TM RFI Remarks
                                                        </p>
                                                        <hr className="mb-3 lightgreen" />
                                                        <div className="row mt-4">
                                                            {status === 17 ? (
                                                                <span>
                                                                    <Alert className="col-12" message="Clarification Required" banner />
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={remarkTMRFI}
                                                                    name="remarks"
                                                                    readOnly={true}
                                                                    style={{

                                                                        height: "20vh",
                                                                        marginBottom: "2rem",
                                                                    }}
                                                                    className="mb-5"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) :
                                            (
                                                rfiStatus === 19 ? (
                                                    <>
                                                        <div style={{ marginTop: '50px' }}>
                                                            <p className="lightgreen fw-bold my-3 fs-6">
                                                                Team Remarks<span style={{ color: "red" }}>*</span>
                                                            </p>
                                                            <hr className="mb-3 lightgreen" />
                                                            <div>
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={remarkATRFI}
                                                                    name="remarks"
                                                                    dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                                                                    onChange={handleRemarksChange}
                                                                    style={{

                                                                        height: "20vh",
                                                                        marginBottom: "2rem",
                                                                    }}
                                                                    className="mb-5"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-3">
                                                            <p className="lightgreen fw-bold my-3 fs-6">
                                                                MD Remarks
                                                            </p>
                                                            <hr className="mb-3 lightgreen" />
                                                            <div className="row mt-4">
                                                                {status === 19 ? (
                                                                    <span>
                                                                        <Alert className="col-12" message="Clarification Required" banner />
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div>
                                                                    <ReactQuill
                                                                        theme="snow"
                                                                        value={remarkMD}
                                                                        name="remarks"
                                                                        readOnly={true}
                                                                        style={{

                                                                            height: "20vh",
                                                                            marginBottom: "2rem",
                                                                        }}
                                                                        className="mb-5"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) :
                                                    null
                                            )
                                    )
                            }


                            <div className="container mt-4 d-flex justify-content-end pr-0">
                                <button className="btn  fs-5"
                                    style={{
                                        backgroundColor: "#07b6af",
                                        color: "white",
                                    }}
                                    type="submit">
                                    Submit
                                </button>
                            </div>
                        </div> */}

                            <div style={{ marginTop: '50px' }}>
                                <p className="lightgreen fw-bold  fs-6">
                                    Team Member Remarks<span style={{ color: "red" }}>*</span>
                                </p>
                                <hr className="mb-3 lightgreen" />
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={remarkATRFI}
                                        name="remarks"
                                        dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                                        onChange={handleRemarksChange}
                                        style={{

                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>

                            {remarkTLRFI &&
                                <div style={{ marginTop: '50px' }}>
                                    <p className="lightgreen fw-bold  fs-6">
                                        Team Leader Remarks
                                    </p>
                                    <hr className="mb-3 lightgreen" />
                                    <div>
                                        <ReactQuill
                                            theme="snow"
                                            value={remarkTLRFI}
                                            name="remarks"
                                            readOnly={true}

                                            dangerouslySetInnerHTML={{ __html: remarkTLRFI }}
                                            // onChange={handleRemarksChange}
                                            style={{

                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>
                                </div>}

                            {remarkTMRFI &&
                                <div className="mt-3">
                                    <p className="lightgreen fw-bold my-3 fs-6">
                                        TM Remarks
                                    </p>
                                    <hr className="mb-3 lightgreen" />
                                    <div className="row mt-4">
                                        {status === 17 ? (
                                            <span>
                                                <Alert className="col-12" message="Clarification Required" banner />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <ReactQuill
                                                theme="snow"
                                                value={remarkTMRFI}
                                                name="remarks"
                                                readOnly={true}
                                                style={{

                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                        </div>
                                    </div>
                                </div>}

                            {remarkMD &&
                                <div className="mt-3">
                                    <p className="lightgreen fw-bold my-3 fs-6">
                                        MD Remarks
                                    </p>
                                    <hr className="mb-3 lightgreen" />
                                    <div className="row mt-4">
                                        {status === 19 ? (
                                            <span>
                                                <Alert className="col-12" message="Clarification Required" banner />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="row mt-4">
                                        <div>
                                            <ReactQuill
                                                theme="snow"
                                                value={remarkMD}
                                                name="remarks"
                                                readOnly={true}
                                                style={{

                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                        </div>
                                    </div>
                                </div>}

                            <div className="container mt-4 d-flex justify-content-end pr-0">
                                <button className="btn my-4 fs-5"
                                    style={{
                                        backgroundColor: "#07b6af",
                                        color: "white",
                                    }}
                                    type="submit">
                                    Submit
                                </button>
                            </div>
                            {loading && <div className="loader"></div>}

                        </form>
                    </>


                    :


                    <form
                        method="post"
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                    >
                        <table className="table table-bordered  table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                        S.No
                                    </th>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                        Document Name
                                    </th>

                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                        Uploaded Document
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">1</td>
                                    <td className="text-center lightgreen p-3">
                                        FVR Report
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={fvrName} target="_blank">{fvr}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">2</td>
                                    <td className="text-center lightgreen p-3">
                                        ER
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={erName} target="_blank">{er}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">3</td>
                                    <td className="text-center lightgreen p-3">
                                        PDD/MR
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={pddName} target="_blank">{pdd}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">4</td>
                                    <td className="text-center lightgreen p-3">
                                        Verification Deed

                                    </td>

                                    <td>
                                        <a href={verificationDeedName} target="_blank">{verificationDeed}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">5</td>
                                    <td className="text-center lightgreen p-3">
                                        Validation Deed

                                    </td>

                                    <td>
                                        <a href={validationDeedName} target="_blank">{validationDeed}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">6</td>
                                    <td className="text-center lightgreen p-3">
                                        Other Docs

                                    </td>

                                    <td>
                                        {combined.map((item) => (
                                            <div>
                                                <a target="_blank" href={item.url}>
                                                    {item.name}

                                                </a>
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {rfiStatus < 21 ?

                            <div className="col-4 my-4">
                                <label
                                    htmlFor="Member"
                                    className="form-label lightgreen mb-4 fs-6"
                                >
                                    Send To
                                    <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                    id="member"
                                    className="form-select borderlightgreen form-select-sm"
                                    aria-label="Default select example"
                                    name="status"
                                    value={member}
                                    disabled
                                    readOnly={true}
                                // onChange={(e) => {
                                //   setMember(e.target.value);
                                // }}
                                >
                                    <option value=''>Select</option>
                                    <option value={14}>Forward to TL</option>
                                    <option value={15}>Send to Team</option>
                                    <option value={16}>Forward to TM</option>
                                    <option value={18}>TM Send to MD</option>
                                    <option value={20}>MD Send to Registrar</option>
                                </select>
                            </div>
                            : ""}

                        {rfiStatus == 21 ? (
                            <div className="row" style={{ marginTop: '40px' }} >
                                <div className="col-3 mb-3 ">
                                    <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                                        RFI Submission Date<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="col-4 mb-3 ">
                                    <input
                                        type="date"
                                        className={`form-control borderlightgreen`}
                                        id="rfidate"
                                        required
                                        disabled
                                        name="rfidate"
                                        value={registrarRFIDateTo}
                                    />
                                </div>
                            </div>
                        ) : ''}



                        {/* {
                            rfiStatus === 18 || (rfiStatus == 16 && remarkTMRFI != null && remarkMD != null) ?

                                <>


                                    <div style={{ marginTop: '50px' }}>
                                        <p className="lightgreen fw-bold my-3 fs-6">
                                            TL RFI Remarks
                                        </p>
                                        <hr className="mb-3 lightgreen" />
                                        <div >

                                            <ReactQuill
                                                theme="snow"
                                                value={remarkTLRFI}
                                                name="remarks"
                                                readOnly={true}
                                                dangerouslySetInnerHTML={{ __html: remark }}

                                                style={{

                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '50px' }}>
                                        <p className="lightgreen fw-bold my-3 fs-6">
                                            TM Remarks
                                        </p>
                                        <hr className="mb-3 lightgreen" />

                                        <div className="row my-4">
                                            <span >
                                                {status === 18 ? (
                                                    <Alert className="col-12" message="Approved" type="success" showIcon />
                                                ) : status === 17 ? (
                                                    <Alert className="col-12" message="Clarification Required" banner />
                                                ) : (
                                                    ''
                                                )}
                                            </span>

                                        </div>

                                        <div >

                                            <ReactQuill
                                                theme="snow"
                                                value={remarkTMRFI}
                                                name="remarks"
                                                readOnly={true}
                                                style={{

                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                        </div>

                                    </div>

                                    <div className="col-12 border-0 mx-auto" style={{ marginTop: '50px' }}>
                                        <p className="lightgreen fw-bold my-3 fs-6">
                                            MD Remarks
                                        </p>
                                        <hr className="mb-3 lightgreen" />

                                        <div className="row  my-4">
                                            <span >
                                                {status === 20 ? (
                                                    <Alert className="col-12" message="Approved" type="success" showIcon />
                                                ) : status === 19 ? (
                                                    <Alert className="col-12" message="Clarification Required" banner />
                                                ) : (
                                                    ''
                                                )}
                                            </span>

                                        </div>

                                        <div>

                                            <ReactQuill
                                                theme="snow"
                                                value={remarkMD}
                                                name="remarks"
                                                readOnly={true}
                                                style={{

                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                        </div>

                                    </div>
                                </>
                                :
                                rfiStatus == 20 ? (
                                    <>
                                        <div style={{ marginTop: '50px' }}>
                                            <p className="lightgreen fw-bold  fs-6">
                                                TL RFI Remarks
                                            </p>
                                            <hr className="mb-3 lightgreen" />
                                            <div>

                                                <ReactQuill
                                                    theme="snow"
                                                    value={remarkTLRFI}
                                                    name="remarks"
                                                    readOnly={true}
                                                    dangerouslySetInnerHTML={{ __html: remarkTLRFI }}

                                                    style={{

                                                        height: "20vh",
                                                        marginBottom: "2rem",
                                                    }}
                                                    className="mb-5"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '50px' }}>
                                            <p className="lightgreen fw-bold my-3 fs-6">
                                                TM Remarks
                                            </p>
                                            <hr className="mb-3 lightgreen" />

                                            

                                            <div >

                                                <ReactQuill
                                                    theme="snow"
                                                    value={remarkTMRFI}
                                                    name="remarks"
                                                    readOnly={true}
                                                    style={{

                                                        height: "20vh",
                                                        marginBottom: "2rem",
                                                    }}
                                                    className="mb-5"
                                                />
                                            </div>

                                        </div>
                                        {rfiStatus == 16 ? '' :
                                            <div className="col-12 border-0 mx-auto" style={{ marginTop: '50px' }}>
                                                <p className="lightgreen fw-bold my-3 fs-6">
                                                    MD Remarks
                                                </p>
                                                <hr className="mb-3 lightgreen" />

                                                <div className="row  my-4">
                                                    <span >
                                                        {status === 20 ? (
                                                            <Alert className="col-12" message="Approved" type="success" showIcon />
                                                        ) : status === 19 ? (
                                                            <Alert className="col-12" message="Clarification Required" banner />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>

                                                </div>

                                                <div>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={remarkMD}
                                                        name="remarks"
                                                        readOnly={true}
                                                        style={{

                                                            height: "20vh",
                                                            marginBottom: "2rem",
                                                        }}
                                                        className="mb-5"
                                                    />
                                                </div>

                                            </div>
                                        }

                                    </>)
                                    :

                                    <>
                                        {rfiStatus == 14 && remarkTLRFI == null ? '' :
                                            <div style={{ marginTop: '20px' }}>
                                                <p className="lightgreen fw-bold  fs-6">
                                                    TL RFI Remarks
                                                </p>
                                                <hr className="mb-3 lightgreen" />
                                                <div>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={remarkTLRFI}
                                                        name="remarks"
                                                        readOnly={true}
                                                        dangerouslySetInnerHTML={{ __html: remarkTLRFI }}

                                                        style={{

                                                            height: "20vh",
                                                            marginBottom: "2rem",
                                                        }}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {rfiStatus == 21 ?
                                            <div style={({ marginTop: '60px' })} >
                                                <p className="lightgreen fw-bold  fs-6">
                                                    Registrar Remarks
                                                </p>
                                                <hr className="mb-3 lightgreen" />
                                                <div>
                                                    <ReactQuill
                                                        theme="snow"
                                                        value={registrarRFIRemark}
                                                        name="remarks"
                                                        readOnly={true}
                                                        style={{
                                                            height: "20vh",
                                                            marginBottom: "2rem",
                                                        }}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            </div>
                                            : ''}
                                        {rfiStatus == 16 || rfiStatus == 14 || rfiStatus == 15 ? '' :
                                            <div style={{ marginTop: '60px' }}>
                                                <p className="lightgreen fw-bold my-3 fs-6">
                                                    MD Remarks
                                                </p>
                                                <hr className="mb-3 lightgreen" />

                                                <div className="row mt-4 mb-4">
                                                    <span >

                                                        <Alert className="col-12" message="Approved" type="success" showIcon />

                                                    </span>

                                                </div>

                                                <div>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={remarkMD}
                                                        name="remarks"
                                                        readOnly={true}
                                                        style={{

                                                            height: "20vh",
                                                            marginBottom: "2rem",
                                                        }}
                                                        className="mb-5"
                                                    />
                                                </div>

                                            </div>
                                        }

                                        <div style={{ marginTop: '50px' }}>
                                            <p className="lightgreen fw-bold  fs-6">
                                                Team Remarks
                                            </p>
                                            <hr className="mb-3 lightgreen" />
                                            <div>

                                                <ReactQuill
                                                    theme="snow"
                                                    value={remarkATRFI}
                                                    name="remarks"
                                                    readOnly={true}
                                                    dangerouslySetInnerHTML={{ __html: remarkATRFI }}

                                                    style={{

                                                        height: "20vh",
                                                        marginBottom: "2rem",
                                                    }}
                                                    className="mb-5"
                                                />
                                            </div>
                                        </div>

                                    </>

                        } */}

                        <div style={{ marginTop: '50px' }}>
                            <p className="lightgreen fw-bold  fs-6">
                                Team Member Remarks<span style={{ color: "red" }}>*</span>
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    value={remarkATRFI}
                                    name="remarks"
                                    dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                                    // onChange={handleRemarksChange}
                                    readOnly={true}

                                    style={{

                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>

                        {remarkTLRFI &&
                            <div style={{ marginTop: '50px' }}>
                                <p className="lightgreen fw-bold  fs-6">
                                    Team Leader Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={remarkTLRFI}
                                        name="remarks"
                                        readOnly={true}

                                        dangerouslySetInnerHTML={{ __html: remarkTLRFI }}
                                        // onChange={handleRemarksChange}
                                        style={{

                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>}

                        {remarkTMRFI &&
                            <div className="mt-3">
                                <p className="lightgreen fw-bold my-3 fs-6">
                                    TM Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                                <div className="row mt-4">
                                    {status === 17 ? (
                                        <span>
                                            <Alert className="col-12" message="Clarification Required" banner />
                                        </span>
                                    ) : null}
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <ReactQuill
                                            theme="snow"
                                            value={remarkTMRFI}
                                            name="remarks"
                                            readOnly={true}
                                            style={{

                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>
                                </div>
                            </div>}

                        {remarkMD &&
                            <div className="mt-3">
                                <p className="lightgreen fw-bold my-3 fs-6">
                                    MD Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                                <div className="row mt-4">
                                    {status === 19 ? (
                                        <span>
                                            <Alert className="col-12" message="Clarification Required" banner />
                                        </span>
                                    ) : null}
                                </div>
                                <div className="row mt-4">
                                    <div>
                                        <ReactQuill
                                            theme="snow"
                                            value={remarkMD}
                                            name="remarks"
                                            readOnly={true}
                                            style={{

                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>
                                </div>
                            </div>}



                    </form>
            }
        </>



    )
}

export default ATRFI