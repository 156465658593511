import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { at_site_visit_url, BASE_DOCUMENT, sbu_action_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../config";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const SiteVisitReview = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [remarks, setRemarks] = useState("");
  const [remarkSbu, setRemarkSbu] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [action, setAction] = useState("");
  const [program, setProgram] = useState([]);
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [file3, setFile3] = useState([]);
  const [file4, setFile4] = useState([]);
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [fileAuditPlanSizeError, setFileAuditPlanSizeError] = useState(false);
  const [fileAuditSizeError, setFileAuditSizeError] = useState(false);
  const [fileRTFSizeError, setFileRTFSizeError] = useState(false);
  const [fileNovsSizeError, setFileNovsSizeError] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [auditPlanName, setAuditPlanName] = useState(null);
  const [auditPlan, setAuditPlan] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null);
  const [auditFinding, setAuditFinding] = useState("");
  const [rtfDocumentName, setRTFDocumentName] = useState(null);
  const [rtfDocument, setRTFDocument] = useState("");
  const [novsDocumentName, setNOVSDocumentName] = useState(null);
  const [novsDocument, setNOVSDocument] = useState("");
  const [siteVisitType, setSiteVisitType] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    member_name1: "",
    member_name2: "",
    member_name3: "",
    member_name4: "",
    nobs_document: "",
    audit_document: "",
    visit_date_from: "",
    visit_date_to: "",
  });

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;

      setFormData({
        ...formData,
        member_name1: record.site_visit_member1,
        member_name2: record.site_visit_member2,
        member_name3: record.site_visit_member3,
        member_name4: record.site_visit_member4,
        visit_date_from: record.site_visit_date_from,
        visit_date_to: record.site_visit_date_to,
        informed_registry: record.informed_registry,
      });

      setTeamList(teams);
      setProgram(record.program);
      setSiteVisitStatus(record.site_visit_status);
      setSiteVisitType(record.site_visit_type);

      setRemarkSbu(record.sbu_remarks);
      setRemarkSbu((record.site_visit_status == 6 || record.site_visit_status == 10) ? record.sbu_remarks = "" :record.sbu_remarks)
      setRemarkTL(record.sbu_tl_remarks);

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_audit_plan}`;
      setAuditPlanName(url1);
      setAuditPlan(record.site_audit_plan);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_audit_finding}`;
      setAuditFindingName(url2);
      setAuditFinding(record.site_audit_finding);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_rtf_document}`;
      setRTFDocumentName(url3);
      setRTFDocument(record.site_rtf_document);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_novs_document}`;
      setNOVSDocumentName(url4);
      setNOVSDocument(record.site_novs_document);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  const handleRemarksChange = (content) => {
    setRemarkSbu(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const handleFromDateChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, visit_date_from: e.target.value });
  };

  const handleToDateChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, visit_date_to: e.target.value });
  };

  const handleMember1Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name1: selectedValue,
      member_name2:
        formData.member_name2 === selectedValue ? "" : formData.member_name2,
    });
  };

  const handleMember2Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name2: selectedValue,
      member_name1:
        formData.member_name1 === selectedValue ? "" : formData.member_name1,
    });
  };

  const handleMember3Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name3: selectedValue,
    });
  };

  const handleMember4Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name4: selectedValue,
    });
  };

  const navigate = useNavigate();

  const handleSubmitAction = async (e) => {
    e.preventDefault();

    if (
      (remarkSbu == null || remarkSbu == "<p><br></p>" || remarkSbu == "") &&
      action
    ) {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        setLoading(true); // Show loader
        let payload = {
          project_id: id,
          site_visit_status: action,
          sbu_remarks: remarkSbu,
        };
        const response = await axios.post(
          `${sbu_action_url}`,
          payload,
          API_HEADER
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemarks("");
          setAction("");
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  return (
    <>
      <form
        method="post"
        onSubmit={handleSubmitAction}
        encType="json/form-data"
      >
        {siteVisitStatus > 0 && siteVisitType == 1 ? (
          <h4 className="my-3 head-center">Physical Site Visit</h4>
        ) : siteVisitStatus > 0 && siteVisitType == 2 ? (
          <h4 className="my-3 head-center">Remote Site Visit </h4>
        ) : (
          ""
        )}
        <div className="row mb-2">
          <div className="col-6 mb-3">
            <label htmlFor="visit Date" className="form-label lightgreen fs-6">
              Site Visit Date From<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="date"
              className={`form-control borderlightgreen`}
              id="visitdatefrom"
              disabled
              name="visit_date_from"
              value={formData.visit_date_from}
              onChange={handleFromDateChange}
            />
          </div>
          <div className="col-6 mb-3">
            <label htmlFor="visit Date" className="form-label lightgreen fs-6">
              Site Visit Date To<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="date"
              className={`form-control borderlightgreen`}
              id="visitdatefrom"
              disabled
              name="visit_date_to"
              value={formData.visit_date_to}
              onChange={handleToDateChange}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6 mb-3">
            <label
              htmlFor="member1"
              className="form-label lightgreen fs-6"
              required
            >
              Member 1<span style={{ color: "red" }}>*</span>
            </label>

            <select
              id="member1"
              className="form-select borderlightgreen form-select-sm"
              aria-label="Default select example"
              name="member1"
              disabled
              value={formData.member_name1}
              onChange={handleMember1Change}
            >
              <option value={""}>Select</option>
              {teamList.map(
                (option) =>
                  option.user_id !== null && (
                    <option
                      key={option.id}
                      value={option.user_id}
                      disabled={option.user_id === formData.member_name2}
                    >
                      {option.user_name.name}
                    </option>
                  )
              )}
            </select>
          </div>
          <div className="col-6  mb-3">
            <label htmlFor="member2" className="form-label lightgreen fs-6">
              Member 2
            </label>

            <select
              id="member2"
              className="form-select borderlightgreen form-select-sm"
              aria-label="Default select example"
              name="member2"
              disabled
              value={formData.member_name2}
              onChange={handleMember2Change}
            >
              <option value={""}>Select</option>
              {teamList.map(
                (option) =>
                  option.user_id !== null && (
                    <option
                      key={option.id}
                      value={option.user_id}
                      disabled={option.user_id === formData.member_name1}
                    >
                      {option.user_name.name}
                    </option>
                  )
              )}
            </select>
          </div>

          <div className="col-6  mb-3">
            <label htmlFor="member3" className="form-label lightgreen fs-6">
              Member 3
            </label>

            <select
              id="member3"
              className="form-select borderlightgreen form-select-sm"
              aria-label="Default select example"
              name="member3"
              disabled
              // value={siteVisitMember2}
              value={formData.member_name3}
              onChange={handleMember3Change}
            >
              <option value={""}>Select</option>
              {teamList.map(
                (option) =>
                  option.user_id !== null && (
                    <option
                      key={option.id}
                      value={option.user_id}
                      disabled={option.user_id === formData.member_name1}
                    >
                      {option.user_name.name}
                    </option>
                  )
              )}
            </select>
          </div>

          <div className="col-6  mb-3">
            <label htmlFor="member2" className="form-label lightgreen fs-6">
              Member 4
            </label>

            <select
              id="member4"
              className="form-select borderlightgreen form-select-sm"
              aria-label="Default select example"
              name="member4"
              disabled
              // value={siteVisitMember2}
              value={formData.member_name4}
              onChange={handleMember4Change}
            >
              <option value={""}>Select</option>
              {teamList.map(
                (option) =>
                  option.user_id !== null && (
                    <option
                      key={option.id}
                      value={option.user_id}
                      disabled={option.user_id === formData.member_name1}
                    >
                      {option.user_name.name}
                    </option>
                  )
              )}
            </select>
          </div>
        </div>
    

        <table className="table table-bordered  table-hover">
          <thead>
            <tr>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                S.No
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Document Name
              </th>

              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Uploaded Document
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>
              <td className="text-center lightgreen p-3">
                Audit Plan
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={auditPlanName} target="_blank">
                  {auditPlan}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">2</td>

              <td className="text-center lightgreen p-3">Audit Finding</td>

              <td>
                <a href={auditFindingName} target="_blank">
                  {auditFinding}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">3</td>

              <td className="text-center lightgreen p-3">
                TRF (Travel Request Form)
              </td>

              <td>
                <a href={rtfDocumentName} target="_blank">
                  {rtfDocument}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">4</td>

              <td className="text-center lightgreen p-3">NOVS
              {/* <span style={{ color: "red" }}>*</span> */}
              </td>

              <td>
                <a href={novsDocumentName} target="_blank">
                  {novsDocument}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        {siteVisitStatus === 10 ? (
          <>
            <div>
              <p className="lightgreen fw-bold fs-6">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTL}
                  name="remarksTl"
                  readOnly={true}
             
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div style={{ marginTop: "60px" }}>
              <p className="lightgreen fw-bold mt-5 fs-6">
                SBU Remarks <span className="text-danger">*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="approve"
                    name="sbu_head"
                    value={12}
                    onChange={handleActionChange}
                    className=" text-success"
                  />
                  <label className="text-success mx-1" htmlFor="approve">
                    Approved
                  </label>
                </span>

                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="clarification_required"
                    name="sbu_head"
                    value={11}
                    onChange={handleActionChange}
                    className="text-danger "
                  />
                  <label
                    className="text-danger mx-1"
                    htmlFor="clarification_required"
                  >
                    Clarification Required
                  </label>
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkSbu}
                    name="remarkSbu"
                    // dangerouslySetInnerHTML={{ __html: remarks }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <div className="container my-4 d-flex justify-content-end pr-0">
                <button
                  className="btn my-4 px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
            <hr className="mb-3 lightgreen" />

            <ReactQuill
              theme="snow"
              value={remarkTL}
              name="remarksTl"
              readOnly={true}
              dangerouslySetInnerHTML={{ __html: remarks }}
              style={{
                height: "20vh",
                marginBottom: "2rem",
              }}
              className="mb-5"
            />

            <p className="lightgreen fw-bold my-3 fs-6">SBU Remarks</p>
            <hr className="mb-3 lightgreen" />

            <div className="row mt-4 mb-4">
              <span>
                {siteVisitStatus === 12 ? (
                  <Alert
                    className="col-12"
                    message="Approved"
                    type="success"
                    showIcon
                  />
                ) : siteVisitStatus === 11 ? (
                  <Alert
                    className="col-12"
                    message="Clarification Required"
                    banner
                  />
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="row mt-4">
              <ReactQuill
                theme="snow"
                value={remarkSbu}
                name="remarksSbu"
                readOnly={true}
                dangerouslySetInnerHTML={{ __html: remarks }}
                // onChange={handleRemarksChange}
                style={{
                  height: "20vh",
                  marginBottom: "2rem",
                }}
                className="mb-5"
              />
            </div>
          </>
        )}

        {loading && <div className="loader"></div>}
      </form>
    </>
  );
};

export default SiteVisitReview;
