// please install npm install react-apexcharts apexcharts
import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER, commonproject_graphs_url } from "../../config";
import axios from "axios";



function ProposalMonthWiseValue()
{
   const [proposalSignedValue, setProposalSignedValue]= useState([]);
   const [proposalLostValue, setproposalLostValue]= useState([]);
   const [proposalOpenValue, setproposalOpenValue] = useState([]);
   const [dateCreatedValue, setDateCreatedValue] = useState([])
   const [createdYear, setCreatedYear] = useState([]);


   useEffect( ()=>{
       const proposalsignvalue=[];
       const proposallostvalue=[];
       const proposalopenvalue=[];
       const datecreationvalue = [];
        const YearCreated = [];

       const getproposalMonthWise= async()=>{
       const reqData= await axios.get(`${commonproject_graphs_url}`, API_HEADER);
    
       const Myproposalmonthwise = reqData.data.proposal_month_wise
       
       for(let i=0; i< Myproposalmonthwise.length; i++)
       {
        proposalopenvalue.push(((Myproposalmonthwise[i].proposal_open_value)/1000000).toFixed(2));
        proposallostvalue.push(((Myproposalmonthwise[i].proposal_lost_value)/1000000).toFixed(2));
        proposalsignvalue.push(((Myproposalmonthwise[i].proposal_signed_value)/1000000).toFixed(2));
        const dateString = Myproposalmonthwise[i].month_year_created;
        const [month, year] = dateString.split('-');
        const date = new Date(year, parseInt(month) - 1);
        const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
        const formattedYear = date.getFullYear();
        datecreationvalue.push(formattedMonth);
        YearCreated.push(formattedYear)
       }
       setProposalSignedValue(proposalsignvalue);
       setproposalOpenValue(proposalopenvalue);
       setproposalLostValue(proposallostvalue);
       setDateCreatedValue(datecreationvalue);
       setCreatedYear(YearCreated)
       }

       getproposalMonthWise();

   },[]);

   return(
    <React.Fragment>
        <div className="container-fluid">
           
            <Chart
            type="bar"
            width={"100%"}
            height={400}
            series={[
                {
                    name:"Signed",
                    data: proposalSignedValue,
                    color: '#1c0159'
                },
                {
                    name:"Lost",
                    data: proposalLostValue,
                   color: '#b35496'
                },
                {
                    name:"Open",
                    data: proposalOpenValue,
                   color: '#b697ff'
                },

            ]}

            options={{
                chart:{
                    toolbar: {
                        show: false,  // This will hide the icons on the top right corner
                      },
                    stacked:true,
                },
                plotOptions:{
                    bar:{
                        horizontal:false,
                        columnWidth:'70%',
                        dataLabels : {
                            total: {
                                enabled: true,
                                style: {
                                  fontSize: '13px',
                                  fontWeight: 900
                                },
                                formatter: function (val) {
                                    return val.toFixed(2); 
                                },
                              }
                          }
                      }
                },
                stroke: {
                    width: 1,
                },
                xaxis:{
                    title:{
                        text: `Month`,
                        style:{fontSize:'16px',fontWeight:'bold'},
                    },
                    
                    categories: dateCreatedValue
                },
                yaxis:{
                    title:{
                        text:"Deal Value in Millions",
                        style:{fontSize:'16px',fontWeight:'bold'}
                    },
                    labels:{
                        style: { fontSize: "15"},

                    }
                },
                legend:{
                    position: 'bottom'
                },
                dataLabels:{
                    enabled:false,
                },
                grid: {
                    show:true,
                    xaxis:{
                        lines:{
                            show:false
                        }
                    },
                    yaxis:{
                        lines:{
                            show:false
                        }
                    }

                },
               
                  grid: {
                    borderColor: '#f1f1f1',
                    row: {
                      colors: ['transparent'], 
                      opacity: 1,
      
                    },
                  },

            }}

            />
        </div>
    </React.Fragment>
);
}
export default ProposalMonthWiseValue;