import { Pagination, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HEADER, team_change_listing_url } from "../../config";
import { Tooltip as Tip } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProjectTeamChangeSummary = ({ id }) => {
    const navigate = useNavigate();

    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); 
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                setLoading(true);

                let payload = {
                    project_id: id,
                    team_change_status: 8
                };

                const response = await axios.post(`${team_change_listing_url}`, payload, API_HEADER);
                console.log(response, "jdsfjds");
                const data = response.data.records.data || [];
                setActivityData(data);
                setTotalRecords(response.data.records.total);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchActivity();
    }, [id]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12;
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const paginatedData = activityData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    console.log(paginatedData);

    const handleTeamChange = (id, teamId) => {
        navigate(`/teamchangemodule/${id}`, { state: { teamId } });
    };

    return (
        <>
           <Spin spinning={loading}>
            <table className="table table-bordered table-hover team-change-table">
                <thead>
                    <tr>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">S.No.</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Project Name</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Earthood ID</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Team Leader/SBU</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Request Date</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">TM Name</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">TM Approved Date</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.length > 0 ? (
                        paginatedData.map((data, index) => (
                            <tr key={index}>
                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                <td>{data.project_name}</td>
                                <td>{data.earthood_id}</td>
                                <td>{data.request_by}</td>
                                <td>{formatDate(data.request_date)}</td>
                                <td>{data.action_by}</td>
                                <td>{formatDate(data.action_date)}</td>
                                <td>
                                    <EyeOutlined
                                        onClick={() => handleTeamChange(data.project_id, data.id)}
                                        style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" className="text-center "><span>No Data</span></td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
                showSizeChanger
                showQuickJumper
                pageSizeOptions={['5', '10', '20', '50']}
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'end' }}
            />
            </Spin>
        </>
    );
};

export default ProjectTeamChangeSummary;
