import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Form, Tabs, Select, Upload, message, Input, Alert, Spin } from "antd";
import axios from "axios";
import {
  site_finding_url,
  get_project_details_url,
  API_HEADER,
  BASE_DOCUMENT,
  client_sitefinding_url,
} from "../config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";



const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const SiteFinding = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const [projectid, setProjectId] = useState(null);

  // const [remark, setRemark] = useState("");
  const [tlRemark, setTLRemark] = useState("");
  const [atRemark, setATRemark] = useState("");

  const [clientRemark, setClientRemark] = useState("");
  const [member, setMember] = useState();
  const [formDisable, setFormDisable] = useState(false);
  const [fileSiteFindingSizeError, setFileSiteFindingSizeError] =
    useState(false);
  const [filesitefinding, setFileSiteFinding] = useState('');
  const [siteVisitFindingClient, setSiteVisitFindingClient] = useState("");
  const [siteVisitFindingClientName, setSiteVisitFindingClientName] =
    useState(null);
  const [siteVisitFindingTl, setSiteVisitFindingTl] = useState("");
  const [siteVisitFindingTlName, setSiteVisitFindingTlName] = useState(null);
  const [siteFindingStatus, setSiteFindingStatus] = useState(null);


  const [formData, setFormData] = useState({ evidence_name: [] })
  const [rowCount, setRowCount] = useState(1);


  const [dynamicname, setDynamicName] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [pddName, setPddName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setErName] = useState(null);
  const [lodName, setlodName] = useState(null);
  const [er, setEr] = useState("");
  const [mrName, setMrName] = useState(null);
  const [mr, setMr] = useState("");
  const [irrName, setirrName] = useState(null);
  const [irr, setIrr] = useState("");

  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");
  const [cpaName, setcpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [scope, setScope] = useState("");

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);


  const fetchFindingData = async (id) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${id}`,
        API_HEADER
      );
      const { record } = response.data;

      if (response && response.data && response.data.record) {
        const { record } = response.data;

        setFormData({
          evidence_name: record.evidences || [],
          finding_status: record.finding_status
        })

        setSiteFindingStatus(record.site_finding_status);
        setProjectId(record.id);
        setScope(record.scope_name.sector_name);

        setClientRemark(record.site_finding_client_remarks);
        setTLRemark((record.site_finding_status == 0 || record.site_finding_status == 1 || record.site_finding_status == 5) ? record.site_finding_tl_remarks = "" : record.site_finding_tl_remarks)
        setATRemark(record.site_finding_at_remarks)
        setFileSiteFinding(record.site_finding_document)
        setMember(record.site_finding_status);
        setRowCount(record.evidences ? (record.evidences.length == 0 ? 1 : record.evidences.length) : 1);

        let findingUrlclient = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_finding_client_document}`;
        setSiteVisitFindingClientName(findingUrlclient);
        setSiteVisitFindingClient(record.site_finding_client_document);

        let findingUrltl = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_finding_document}`;
        setSiteVisitFindingTlName(findingUrltl);
        setSiteVisitFindingTl(record.site_finding_document);

        const dynamicUrl = record.evidences.map((evidence) => `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`)
        setDynamicName(dynamicUrl)

        const dName = record.evidences.map((evidence) => evidence.name);
        setDynamic(dName)

        let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;

        setPddName(url4);
        setPdd(record.pdd_documents);

        let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;

        setErName(url5);
        setEr(record.er_documents);

        let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;

        setMrName(url6);
        setMr(record.mr_documents);

        let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
        setirrName(url7);
        setIrr(record.irr_documents);

        let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
        setcpaName(url8);
        setCpa(record.cpa_documents);

        let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.other_documents}`;
        setOtherName(url9);
        setOther(record.other_documents);


      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchFindingData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const handleRemarksChange = (content) => {
    setTLRemark(content);
  };


  const handleSubmitSiteVisitFinding = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);


    if ((tlRemark == null || tlRemark == "<p><br></p>" || tlRemark == "") || (filesitefinding == null || filesitefinding == '') || (filesitefinding == null || filesitefinding == '')) {
      toast.error("Please upload the document and remarks.");
    } else {

      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append("project_id", projectid);
        formData.append("site_finding_status", member);
        formData.append("site_finding_document", filesitefinding);
        formData.append("site_finding_tl_remarks", tlRemark);

        const response = await axios.post(
          `${site_finding_url}`,
          formData,
          CONFIG_Token2
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (fileSiteFindingSizeError) {
          toast.error(`File size must below ${dynamicMaxSize}`);
        } else {
          toast.success("Remarks Submitted Successfully");
          setTLRemark("");
          setFileSiteFinding(null);
          setMember("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Documents and Remarks are mandatory");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);

      }
    }
  };


  return (
    <>


      <table className="table table-bordered table-hover table-responsive-sm">
        <thead>
          <tr>
            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
              S.No
            </th>
            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
              Document Name
            </th>
            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
              Uploaded Document
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">1</td>
            {/* {scope == "Val" ? (
              <td className="text-center lightgreen p-3">
                PDD Document
                <span style={{ color: "red" }}>*</span>
              </td>
            ) : (
              <td className="text-center lightgreen p-3">PDD Document</td>
            )} */}
            <td className="text-center lightgreen p-3">
              PDD Document
              {scope == "VAL" ?
                <span style={{ color: "red" }}>*</span>
                : ''
              }
            </td>
            <td>
              <a target="_blank" href={pddName}>
                {pdd}
              </a>
            </td>
          </tr>
          <tr>
            <td class="text-center">2</td>

            {/* <td className="text-center lightgreen p-3">
              ER Document
              <span style={{ color: "red" }}>*</span>
            </td> */}
            <td className="text-center lightgreen p-3">
              ER Document
              {scope == "VER" || scope == "VAL" ?
                <span style={{ color: "red" }}>*</span>
                : ''
              }
            </td>

            <td>
              <a target="_blank" href={erName}>
                {er}
              </a>
            </td>
          </tr>
          <tr>
            <td class="text-center">3</td>

            {/* {scope == "Ver" ? (
              <td className="text-center lightgreen p-3">
                MR Document
                <span style={{ color: "red" }}>*</span>
              </td>
            ) : (
              <td className="text-center lightgreen p-3">MR Document</td>
            )} */}

            <td className="text-center lightgreen p-3">
              MR Document
              {scope == "VER" ?
                <span style={{ color: "red" }}>*</span>
                : ''
              }

            </td>

            <td>
              <a target="_blank" href={mrName}>
                {mr}
              </a>
            </td>
          </tr>

          <tr>
            <td class="text-center">4</td>

            <td className="text-center lightgreen p-3">
              CPA Document
              <span style={{ color: "red" }}></span>
            </td>

            <td>
              <a target="_blank" href={cpaName}>
                {cpa}
              </a>
            </td>
          </tr>

          <tr>
            <td class="text-center">5</td>

            <td className="text-center lightgreen p-3">
              IRR Document
              <span style={{ color: "red" }}></span>
            </td>

            <td>
              <a target="_blank" href={irrName}>
                {irr}
              </a>
            </td>
          </tr>

          <tr>
            <td class="text-center">6</td>

            <td className="text-center lightgreen p-3">Other Document</td>

            <td>
              <a target="_blank" href={otherName}>
                {other}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered my-4 table-hover">
        <thead>
          <tr>
            <th className="form-label lightgreen fs-6 text-center table-heading-color">
              S.No.
            </th>
            <th className="form-label lightgreen fs-6 text-center  table-heading-color">
              Evidence Name
            </th>
            <th className="form-label lightgreen fs-6 text-center  table-heading-color">
              Uploaded Documents
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(rowCount)].map((_, index) => (
            <tr key={index}>
              <td class="text-center">{index + 1}</td>
              <td>
                <input
                  type="text"
                  name="evidence"
                  className="form-control"
                  disabled={true}
                  placeholder=""

                  value={
                    formData.evidence_name[index]
                      ? formData.evidence_name[index].remarks
                      : ""
                  }
                />
              </td>

              <td>
                <a target="_blank" href={dynamicname[index]}>
                  {/* {JSON.stringify(dynamic)} */}
                  {/* { `${dynamic}${[index]}`} */}
                  {dynamic[index]}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {isTL && (siteFindingStatus === null ||
        siteFindingStatus === 5 ||
        siteFindingStatus === 0) ? (


        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmitSiteVisitFinding}
            encType="multipart/form-data"
          >


            <div className="row ">
              <div className="col-12 d-flex ">
                <div className="col-4 my-4 ">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >

                    <option value=''>Select</option>



                    <option value={4}>Reply to Client</option>
                    <option value={3}>Send to Team Members</option>
                    <option value={6}>Findings Closed</option>

                  </select>
                </div>
                <div className="col-6 my-4">
                  {siteFindingStatus === 5 ? (
                    <label
                      className="form-label lightgreen fs-6"
                      htmlFor="remarks"
                    >
                      Upload Findings
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  ) : (
                    <label
                      className="form-label lightgreen fs-6"
                      htmlFor="remarks"
                    >
                      Upload Findings <span style={{ color: "red" }}>*</span>
                    </label>
                  )}

                  {siteFindingStatus === 5 ? (

                    <FileUploaderComponent file={filesitefinding} setFile={setFileSiteFinding} />

                  ) : (


                    <FileUploaderComponent file={filesitefinding} setFile={setFileSiteFinding} />

                  )}

                </div>
              </div>
              {siteFindingStatus === null ? '' :
                <div className="row">
                  <div className="col-12 d-flex ">

                    <div className="col-6" style={{ marginTop: "25px" }}>
                      <p className="form-label lightgreen text-bold fs-6">
                        Uploaded Findings
                      </p>
                      <span>
                        <a target="_blank" href={siteVisitFindingTlName}>
                          {siteVisitFindingTl}
                        </a>
                      </span>
                    </div>
                    <div className="col-6" style={{ marginTop: "25px" }}>
                      <p className="form-label lightgreen text-bold fs-6">
                        Client Uploaded Findings
                      </p>
                      <span>
                        <a target="_blank" href={siteVisitFindingClientName}>
                          {siteVisitFindingClient}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              }
            </div>


            <div className="row mt-4">
              <div className="col-12">
                <label className="form-label lightgreen fs-6 mb-2">
                  Team Leader Remarks
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={tlRemark}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: tlRemark }}
                  onChange={handleRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {siteFindingStatus == 5 ? (
              <div className="row mt-4">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Client Remarks
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={clientRemark}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: clientRemark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {atRemark &&
              <div className="row mt-4">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Team Member Remarks
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={atRemark}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: atRemark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>}



            <div className="container my-4 pr-0">
              <div className="row">
                <div className="d-flex justify-content-end col-12">
                  <button
                    className="btn px-3 fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {loading && <div className="loader"></div>}

          </form>
        </>

      ) : (isTL && siteFindingStatus === 1) ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmitSiteVisitFinding}
            encType="multipart/form-data"
          >


            <div className="row ">
              <div className="col-12 d-flex ">
                <div className="col-5 my-4 ">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value=''>Select</option>



                    <option value={4}>Reply to Client</option>
                    <option value={3}>Send to Team Members</option>
                    <option value={6}>Findings Closed</option>

                  </select>
                </div>

                <div className="col-6 my-4">
                  <label className="form-label lightgreen fs-6" htmlFor="remarks">
                    Upload Findings<span style={{ color: "red" }}>*</span>
                  </label>

                  <FileUploaderComponent file={filesitefinding} setFile={setFileSiteFinding} />

                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ marginTop: "25px" }}>
                  <p className="form-label lightgreen text-bold fs-6">
                    Uploaded Findings
                  </p>
                  <span>
                    <a target="_blank" href={siteVisitFindingTlName}>
                      {siteVisitFindingTl}
                    </a>
                  </span>
                </div>

                {
                  (siteFindingStatus == 1 && siteVisitFindingClient == null) ? "" :
                    <div className="col-6" style={{ marginTop: "25px" }}>
                      <p className="form-label lightgreen text-bold fs-6">
                        Client Uploaded Findings
                      </p>
                      <span>
                        <a target="_blank" href={siteVisitFindingClientName}>
                          {siteVisitFindingClient}
                        </a>
                      </span>
                    </div>

                }

              </div>
            </div>

            <div className="row mt-4 mb-4">
              <div className="col-12">
                <label className="form-label lightgreen fs-6 mb-3">
                  Team Leader Remarks <span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={tlRemark}
                  name="remarks"
                  required={false}
                  dangerouslySetInnerHTML={{ __html: tlRemark }}
                  onChange={handleRemarksChange}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {/* {siteFindingStatus == 1 && clientRemark != null ?
            <div className="row my-4">
              <div className="col-12">
                <label className="form-label lightgreen fs-6 mb-2">
                  Client Remarks
                </label>
                <ReactQuill
                  theme="snow"
                  value={clientRemark}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: clientRemark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            : ''} */}

            {clientRemark &&
              <div className="row my-4">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Client Remarks
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={clientRemark}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: clientRemark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>}

            {atRemark &&
              <div className="row mt-4">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Team Member Remarks
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={atRemark}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: atRemark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>}





            <div className="container my-4 pr-0">
              <div className="row">
                <div className="d-flex justify-content-end  col-12">
                  <button
                    className="btn px-3 fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {loading && <div className="loader"></div>}

          </form>
        </>

      ) : (
        <form
          method="post"
          onSubmit={handleSubmitSiteVisitFinding}
          encType="multipart/form-data"
        >
          <div className="row " style={{ width: '100%' }}>
            <div className="col-12 d-flex ">

              <div className="col-4 my-4 ">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  disabled
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value=''>Select</option>


                  <option value={4}>Reply to Client</option>
                  <option value={3}>Send to Team Members</option>
                  <option value={6}>Findings Closed</option>

                </select>
              </div>

              <div className="col-4" style={{ marginTop: "25px" }}>
                <p className="form-label lightgreen text-bold fs-6">
                  Uploaded Findings
                </p>
                <span>
                  <a target="_blank" href={siteVisitFindingTlName}>
                    {siteVisitFindingTl}
                  </a>
                </span>
              </div>

              {
                (siteFindingStatus == 2 && siteVisitFindingClient == null) || (siteFindingStatus == 3 && siteVisitFindingClient == null) || (siteFindingStatus == 4 && siteVisitFindingClient == null) ? "" :

                  <div className="col-4" style={{ marginTop: "25px" }}>
                    <p className="form-label lightgreen text-bold fs-6">
                      Client Uploaded Findings
                    </p>
                    <span>
                      <a target="_blank" href={siteVisitFindingClientName}>
                        {siteVisitFindingClient}
                      </a>
                    </span>
                  </div>

              }

            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
              <label className="form-label lightgreen fs-6 mb-3">
                Team Leader Remarks<span style={{ color: "red" }}>*</span>
              </label>
              <ReactQuill
                theme="snow"
                value={tlRemark}
                name="remarks"
                readOnly={true}
                style={{
                  height: "20vh",
                  marginBottom: "2rem",
                }}
                className="mb-5"
              />
            </div>
          </div>
          {/* {(siteFindingStatus == 3 && clientRemark == null) ||
            (siteFindingStatus == 1 && clientRemark == null) ||
            (siteFindingStatus == 4 && clientRemark == null) ? (
            ""
          ) : (
            <div className="row mt-4">
              <div className="col-12">
                <label className="form-label lightgreen fs-6 mb-3">
                  Client Remarks{" "}
                </label>
                <ReactQuill
                  theme="snow"
                  value={clientRemark}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )} */}

          {clientRemark &&
            <div className="row my-4">
              <div className="col-12">
                <label className="form-label lightgreen fs-6 mb-2">
                  Client Remarks
                </label>
                <ReactQuill
                  theme="snow"
                  value={clientRemark}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: clientRemark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}

          {atRemark &&
            <div className="row mt-4">
              <div className="col-12">
                <label className="form-label lightgreen fs-6 mb-2">
                  Team Member Remarks
                </label>
                <ReactQuill
                  theme="snow"
                  value={atRemark}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: atRemark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}
          {loading && <div className="loader"></div>}

        </form>
      )}
    </>
  );
};

export default SiteFinding;
