import React from 'react'

const Footer = () => {
    return (
        <>
            <div>
                <footer className="main-footer mt-3 text-dark">
                    <strong>Copyright © 2024 <a href="https://www.earthood.com/">Earthood</a>.&nbsp;</strong>
                     All rights reserved.
                    
                </footer>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>

        </>
    )
}

export default Footer