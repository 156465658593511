import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Tabs,
} from "antd";
import axios from "axios";
import { Tooltip } from 'antd';
import { API_HEADER, BASE_DOCUMENT } from "../../config";
import { get_assesment_url } from "../../config";
import { toast } from "react-toastify";
import Header from "../../Pages/Header";
import SideNavbar from "../SideNavbar";
import Footer from "../Footer";
import {
  get_project_details_url,
  get_KickoutInfo_url,
} from "../../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import TrackProjectTab from "./TrackProjectTab";
import TrackDocument from "./TrackDocument";
import TrackKickoutTab from "./TrackKickoutTab";
import TrackTR from "./TrackTR";
import TrackCC from "./TrackCC";
import TrackRFI from "./TrackRFI";
import ActivityChain from "../ActivityChain";

const KickoutTrack = () => {
  const { project_id,kickout_id } = useParams();

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const cc_id = userdata.id;

  const [projectid, setProjectId] = useState(null);
 
  const [activeTab, setActiveTab] = useState("1");
 
  const [kickoutStatus, setKickoutStatus] = useState('')
  
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row pb-5 ">
              <div className="col-12 ">
                <div className="d-flex justify-content-between">
                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    Project Details
                  </h4>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  className="mx-5"
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                  size="large"
                >

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 1 ? "box_sh" : ""
                          }`}
                      >
                        <p>Project Details</p>
                      </div>
                    }
                    key="1"
                  >

                    <TrackProjectTab project_id={project_id} kickout_id={kickout_id}/>
                  
                  </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 2 ? "box_sh" : ""
                            }`}
                        >
                          <p>Project Documents</p>
                        </div>
                      }
                      key="2"
                    >

                    <TrackDocument project_id={project_id} kickout_id={kickout_id}/>

                    </Tabs.TabPane>
                

                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 5 ? "box_sh" : ""
                            }`}
                        >
                          <p>KickOut</p>
                        </div>
                      }
                      key="5"
                    >
                    

                    <TrackKickoutTab project_id={project_id} kickout_id={kickout_id}/>

                    </Tabs.TabPane>
                  

                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 7 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out TR Round</p>
                        </div>
                      }
                      key="7"
                    >

                    <TrackTR project_id={project_id} kickout_id={kickout_id}/>

                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 8 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out CC Round</p>
                        </div>
                      }
                      key="8"
                    >

                    <TrackCC project_id={project_id} kickout_id={kickout_id}/>               

                    </Tabs.TabPane>

        
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 9 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out RFI Round</p>
                        </div>
                      }
                      key="9"
                    >

                     
                    <TrackRFI project_id={project_id} kickout_id={kickout_id}/>               

                    </Tabs.TabPane>


                    <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 12 ? "box_sh" : ""
                          }`}
                      >
                        <p>Project Summary</p>
                      </div>
                    }
                    key="12"
                  >
                    <ActivityChain id={project_id} />
                  </Tabs.TabPane>
                 
                
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default KickoutTrack;
