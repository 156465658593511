import React, { useEffect, useState } from 'react';
import { API_HEADER, get_proposal_detail_url, getNotificationList, markNotificationAsRead } from '../config';
import axios from 'axios';
import { Tooltip as Tip } from "antd";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faBell
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function NotificationTile() {
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const designation_id = sessionStorage.getItem("designation_id");

  const getNotifications = async () => {
    try {
      const result = await axios.get(`${getNotificationList}?limit=${5}`, API_HEADER);
      if (result.data.records && result.data.records.data) {
        setItems(result.data.records.data);
      }
      setRefresh(false);
    } catch (error) {
      console.log(error);
    }
  };
  const redirectPage = async (item) => {
    try {

      if (item.type === 1) {
        
        const payload = {
          proposal_id: item.reference_id,
        };
        const response = await axios.post(`${get_proposal_detail_url}`, payload, API_HEADER);
        const data = response.data.record;

        if(designation_id==3){
          navigate("/ptactions", { state: { data } });
        }else{
          navigate("/tmsactions", { state: { data } });
        }

      } else if (item.type === 2 && designation_id == 13) {
        navigate(`/clientprojectdetails/${item.reference_id}`);
      } else if (item.type === 2) {
        navigate(`/atdeskreview/${item.reference_id}`);
      }else if (item.type === 3) {
        navigate(`/kickoutmodule/${item.reference_id}`);
      }else if (item.type === 4) {
        // navigate(`/teamchangemodule/${id}`, { state: { teamId } })
        navigate(`/teamchangemodule/${item.reference_id}`, { state: { teamId: item.pid } });
        // window.location.reload();
      }

    } catch (error) {
      console.log(error);
    }
  };
  
  const markAsRead = async (item,type) => {
    try {
      
      let payload = {
        id: item.id,
        type:type
      }

      const result = await axios.post(`${markNotificationAsRead}`, payload, API_HEADER);
  
      if (result.data.status) {
        redirectPage(item);

        if(type==2){
          toast.success("Notification has been marked as read successfully!");
        }

        setRefresh(true);

      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const markAllasRead = async (item) => {
    try {
      const result = await axios.post(`${markNotificationAsRead}`, {
        type:2
      }, API_HEADER);

      if (result.data.status) {
        toast.success("All Notification has been marked as read successfully!");
        setRefresh(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewAllNotifications = async () => {
    try {
      navigate(`/viewallnotifications`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [refresh]);

  return (
    <>
      <a href="#" data-toggle="dropdown" 
        className={`nav-link notification-toggle nav-link-lg ${items.length > 0 ? 'notification-icon' : ''}`} 
        style={{display: 'flex', alignItems: 'center', position: 'relative'}}>

        <FontAwesomeIcon
          className="nav-icon bell_icon"
          icon={faBell}
          style={{ marginRight: '5px' }}
        />
        {items.length > 0 && (
          <span className="notification-count">
            {items.length}
          </span>
        )}
      </a>
      <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
        <div className="dropdown-header mb-4">
          <div className="float-left">
            <span>Notifications</span>
          </div>
          {items.length > 0 && (
            <div className="float-right">
              <a href="#" onClick={markAllasRead}>Mark All As Read</a>
            </div>
          )}
        </div>
        <div className="dropdown-list-content dropdown-list-icons px-3 py-2">
          {items.map((data, index) => (
            <a href="#" 
            className={`dropdown-item dropdown-item-unread ${data.read_status == 1 ? ' text-muted' : 'bg-white text-white'}`} 
            key={index}
            style={{
              backgroundColor: data.read_status === 1 ? 'rgba(233, 236, 239, 0.95)' : '', // Slightly darker than bg-light
              borderRadius: '5px', 
              padding: '10px', 
              marginBottom: '5px'
            }}
            >
              <div className="dropdown-item-content" onClick={() => markAsRead(data,1)}>
                <Tip title={data.title}>
                  <span className="dropdown-item-title preserve-space text-ellipsis" onClick={() => markAsRead(data,1)}>
                    {data.title}
                  </span>
                  <span className="dropdown-item-body preserve-space text-ellipsis" onClick={() => markAsRead(data,1)}>
                    {data.body && data.body.replace(/<[^>]+>/g, '')}
                  </span>
                  <span className="dropdown-item-time preserve-space text-ellipsis" onClick={() => markAsRead(data,1)}>
                    {moment(data.created_at).format('DD-MM-YYYY hh:mm a')}
                  </span>
                  <span className="dropdown-item-mark preserve-space text-ellipsis" onClick={() => markAsRead(data,2)}>
                    <FontAwesomeIcon icon={faCircleCheck}  />
                  </span>
                </Tip>
              </div>
            </a>
          ))}
        </div>
        <div className="dropdown-footer text-center">
          {items.length > 0 && (
            <a href="#" onClick={viewAllNotifications}>View All <i className="fas fa-chevron-right"></i></a>
          )}
        </div>
      </div>
    </>
  );
}
