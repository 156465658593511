import React from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

export default function PaymentTermsSales({ paymentRecords, addPayment, formdisabled, handlePaymentChange, deletePayment }) {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered table-hover table-responsive-sm">
              <thead>
                <tr>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>S.No.</th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Description
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Stage
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Percent
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Amount</th>

                  {
                    !formdisabled ? (

                      <th style={{background:'#ABEBC6'}}>
                        <a className="">
                          <PlusOutlined
                            onClick={addPayment}
                            style={{color:'black'}}
                          />
                        </a>
                      </th>
                    ) : ""

                  }

                </tr>
              </thead>
              <tbody>
                {paymentRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    <td>
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        value={record.description}
                        disabled={formdisabled}
                        onChange={(e) => handlePaymentChange(index, e)}
                        placeholder=""
                        required
                      />
                    </td>

                    <td>

                      <select name="stage" id=""
                        value={record.stage}
                        disabled={formdisabled}
                        className="form-control"
                        onChange={(e) => handlePaymentChange(index, e)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1">Contract Signed</option>
                        <option value="2">Site Visit</option>
                        <option value="3">CC Review</option>
                        <option value="4">RFI</option>
                      </select>

                    </td>

                    <td>
                      <input
                        type="number"
                        name="percent"
                        disabled={formdisabled}
                        className="form-control"
                        value={typeof record.percent == 'number' ? record.percent.toFixed(2) : record.percent}
                        onChange={(e) => handlePaymentChange(index, e)}
                        placeholder="0"
                        required
                        step="0.01"
                        pattern="^\d{0,2}(\.\d{1,2})?$"
                        oninput="validity.valid||(value='');"
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                      />


                    </td>

                    <td>
                      <input
                        type="number"
                        disabled={formdisabled}
                        name="amount"
                        value={record.amount}
                        className="form-control"
                        onChange={(e) => handlePaymentChange(index, e)}
                        placeholder="0"
                        step="0.01"
                        pattern="\d+(\.\d{1,2})?"
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </td>



                    {
                      !formdisabled ? (

                        <td>
                          <CloseOutlined onClick={() => deletePayment(index)} />

                        </td>
                      ) : ""

                    }

                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  );
}
