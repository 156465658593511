import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { contractsigned_monthly_graphs_url, API_HEADER } from "../../config";
import axios from "axios";

function Linechart() {

  const [contractMonthly, setContractMonthly] = useState([]);
  const [createdDate, setCreatedDate] = useState([]);
  const [createdYear, setCreatedYear] = useState([]);
  const [myMedian, setMyMedian] = useState([]);

  useEffect(() => {

    const MonthlyContract=[];
    const DateCreated = [];
    const YearCreated = [];

    function calculateMedian(arr) {
      arr.sort((a, b) => a - b); 
      var n = arr.length;
      if (n % 2 === 0) { 
          var middle1 = arr[n / 2];
          var middle2 = arr[n / 2 - 1];
          var median = (middle1 + middle2) / 2;
      } else { 
          var median = arr[Math.floor(n / 2)];
      }
      return median;
  }

    const fetchData = async () => {
      try {
        const reqData= await axios.get(`${contractsigned_monthly_graphs_url}`, API_HEADER);
        const mysignedcontract = reqData.data.contract_monthly;

        for(let i=0; i< mysignedcontract.length; i++)
        {
         MonthlyContract.push((mysignedcontract[i].proposal_signed)); 
         const dateString = mysignedcontract[i].month_year_created;
         const [month, year] = dateString.split('-');
         const date = new Date(year, parseInt(month) - 1);
         const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
         const formattedYear = date.getFullYear();


         DateCreated.push(formattedMonth);
         YearCreated.push(formattedYear);
        }

        setContractMonthly(MonthlyContract)
        setCreatedDate(DateCreated)
        setCreatedYear(YearCreated)
        setMyMedian(calculateMedian(contractMonthly))        

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container-fluid ">
      <Chart
        type="line"
        width={"100%"}
        height={300}
        series={[
          {name : 'SignedContract', data : contractMonthly,color:'#ab2626'},
          {name : 'Median', data : myMedian,color:'#c28b1d'}
        ]}
        options={{
          chart:{
            toolbar: {
              show: false,  // This will hide the icons on the top right corner
            },
          },
          
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },

          stroke: {
            curve: 'smooth'
          },

          markers: {
            size: 1
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['transparent'], 
              opacity: 0.5
            },
          },
          xaxis: {
            title: { text: `Month` , style:{fontSize:'16px',fontWeight:'bold'} },
            categories: createdDate,   
          },
          yaxis: {
            title: { text: "Number of Deals", style:{fontSize:'16px',fontWeight:'bold'} },
          },
        }}
      />
    </div>
  );
}

export default Linechart;
