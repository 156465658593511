import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API_HEADER, project_wise_project_url, client_project_wise_graph_url } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ClientProgramWiseProjects({search,startDate,endDate}) {
  const [issuedRegisteredCount, setIssuedRegisteredCount] = useState([]);
  const [kickoutCount, setKickoutCount] = useState([]);
  const [ongoingProjectsCount, setOngoingProjectsCount] = useState([]);
  const [contractSignedCount, setContractSignedCount] = useState([]);
  const [programWiseName, setProgramWiseName] = useState([]);
  const [programWiseData, setProgramWiseData] = useState([]); // State to store MyprogramwiseData
  const [rfiProjectCount, setrfiProjectCount] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const issuedregistered = [];
    const kickoutcount = [];
    const ongoingprojects = [];
    const contractsigned = [];
    const programwisename = [];
    const rficompleted = [];

    const getProposalScopeWise = async () => {
            
      const payload = { 
        "fy": "",
        'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
        'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
      };

      const reqData = await axios.post(`${client_project_wise_graph_url}`, payload, API_HEADER);

      const MyprogramwiseData = reqData.data.data;

      for (let i = 0; i < MyprogramwiseData.length; i++) {
        issuedregistered.push(MyprogramwiseData[i].project_completed);
        kickoutcount.push(MyprogramwiseData[i].project_kickout);
        ongoingprojects.push(MyprogramwiseData[i].project_ongoing);
        contractsigned.push(MyprogramwiseData[i].project_notstarted);
        programwisename.push(MyprogramwiseData[i].description);
        rficompleted.push(MyprogramwiseData[i].project_rfi);

      }
      setIssuedRegisteredCount(issuedregistered);
      setKickoutCount(kickoutcount);
      setOngoingProjectsCount(ongoingprojects);
      setContractSignedCount(contractsigned);
      setProgramWiseName(programwisename);
      setrfiProjectCount(rficompleted);

      setProgramWiseData(MyprogramwiseData); // Save the data
    };

    getProposalScopeWise();
  }, [search]);

  const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
    const seriesName = w.globals.seriesNames[seriesIndex];
    const programName = w.globals.labels[dataPointIndex];

    const matchedProgram = programWiseData.find((program) => program.description === programName);
    const program_id = matchedProgram ? matchedProgram.program_id : null;


    if (program_id) {
      navigate('/programwiseprojecttable', { state: { title:seriesName, seriesName, program_id, programName } });
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Chart
          type="bar"
          width={"100%"}
          height={400}
          series={[
            {
              name: "Contract Signed",
              data: contractSignedCount,
              color: '#27AE60',
            },
            {
              name: "Ongoing Projects",
              data: ongoingProjectsCount,
              color: '#2776ae',
            },

            {
              name: "RFI Completed",
              data: rfiProjectCount,
              color: '#65ddf7',
            },

            {
              name: "Kickout",
              data: kickoutCount,
              color: '#f37324',
            },
            {
              name: "Issued/Registered",
              data: issuedRegisteredCount,
              color: '#08415C',
            },

            
          ]}
          options={{
            chart: {
              toolbar: {
                show: false,  // This will hide the icons on the top right corner
              },
              stacked: true,
              events: {
                dataPointSelection: handleChartClick,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '40%',
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: '11px',
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            stroke: {
              width: 1,
            },
            xaxis: {
              title: {
                text: "Programs",
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                },
              },
              categories: programWiseName,
            },
            yaxis: {
              title: {
                text: "Number of Projects",
                style: { fontSize: '16px', fontWeight: 'bold' },
              },
              labels: {
                style: { fontSize: "15" },
              },
            },
            legend: {
              position: 'bottom',
            },
            dataLabels: {
              enabled: false,
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            grid: {
              borderColor: '#f1f1f1',
              row: {
                colors: ['transparent'],
                opacity: 1,
              },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default ClientProgramWiseProjects;
