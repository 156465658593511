import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Table, Tag, Select, Input } from "antd";
import { Tooltip as Tip } from "antd";

import axios from "axios";
import React from "react";
import ProjectStatusSchema from "../Schema/ProjectStatusSchema";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_HEADER, at_allprojects_url } from "../config";
import { get_scope_url,getCountryList, get_client_name_url, get_program_url, get_trsbu_url, get_assesment_url, get_project_stages_url } from "../config";
import Header from "./Header";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import { useDebounce } from "../utlis/Debounce";
const { Option } = Select;

const TotalProjectsAT = () => {
	const { id } = useParams()
	const [dataSource, setDataSource] = useState([]);
	const [loadData, setloadData] = useState(false);
	const [loader, setLoader] = useState(false);
	const location = useLocation();

	const ids = location.state

	const [statusList, setStatusList] = useState([]);
	const [countryList, setCountryList] = useState([]);
	const [traineeList, setTraineeList] = useState([]);
	const [teamLeaderList, setTeamLeaderList] = useState([]);
	const [sbuHeadList, setSbuHeadList] = useState([]);
	const [programList, setProgramList] = useState([]);
	const [validatorVerifierList, setValidatorVerifierList] = useState([]);
	const [clientNameList, setClientNameList] = useState([]);
	const [scopeList, setScopeList] = useState([]);

	const [status, setStatus] = useState('');
	const [client_id, setClientId] = useState('');
	const [country, setCountry] = useState('');
	const [trainee, setTrainee] = useState('');
	const [scope, setScope] = useState('');
	const [program, setProgram] = useState('');
	const [teamLeader, setTeamLeader] = useState('');
	const [sbu_head_name, setSbuHead] = useState('');
	const [validator_verifier, setValidatorVerifier] = useState('');
	const [search, setSearch] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	const pending_days = JSON.parse(localStorage.getItem("mail_reminder")).pending_since;

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());
	

	const getCountry = async () => {
		try {
			const result = await axios.get(`${getCountryList}`);
			setCountryList(result.data.data);
		} catch (error) {
		}
	};

	const getScope = async () => {
        try {
            const result = await axios.get(`${get_scope_url}`);
            setScopeList(result.data.data);
        } catch (error) {
        }
    };

	const getSbuHead = async () => {
		try {
			const result = await axios.get(`${get_trsbu_url}`);
			setSbuHeadList(result.data.data);
		} catch (error) {
		}
	};

	const getProgram = async () => {
		try {
			const result = await axios.get(`${get_program_url}`);
			setProgramList(result.data.data);
		} catch (error) {
		}
	};


	const getClientname = async () => {
		try {
			const result = await axios.get(`${get_client_name_url}`);
			setClientNameList(result.data.data);
		} catch (error) {
			toast.error("Error fetching Clientname list");
		}
	};

	const getValidator = async () => {
		try {
			const result = await axios.get(`${get_assesment_url}`);
			setValidatorVerifierList(result.data.data);
			setTeamLeaderList(result.data.data);
			setTraineeList(result.data.data);
		} catch (error) {
			toast.error("Error fetching validator list");
		}
	};

	const getStatus = async () => {
		try {
			const result = await axios.get(`${get_project_stages_url}`);
			setStatusList(result.data);
		} catch (error) {
			toast.error("Error fetching status list");
		}
	};



	const handleClientNameSearch = (value) => {
		setClientId(value);
		setloadData(true);
	};

	const handleCountrySearch = (value) => {
		setCountry(value);
		setloadData(true);
	};


	const handleProgramSearch = (value) => {
		setProgram(value);
		setloadData(true);
	};

	const handleSBUSearch = (value) => {
		setSbuHead(value);
		setloadData(true);
	};

	const handleTeamLeaderSearch = (value) => {
		setTeamLeader(value);
		setloadData(true);
	};

	const handleValidatorSearch = (value) => {
		setValidatorVerifier(value);
		setloadData(true);
	};

	const handleTraineeSearch = (value) => {
		setTrainee(value);
		setloadData(true);
	};

	const handleScopeSearch = (value) => {
		setScope(value);
		setloadData(true);
	};

	const handleStatusSearch = (value) => {
		setStatus(value);
		setloadData(true);
	};

	useEffect(() => {
		getCountry();
		getProgram();
		getClientname();
		getSbuHead();
		getValidator();
		getStatus();
		getScope();
	}, []);

	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		setPagination(pagination);
		setloadData(true);
		setSearch(null);
	};

	const editForm = async (record) => {
		navigate(`/atdeskreview/${record.id}`)
	};


	const allData = async () => {
		try {
			setLoader(true)
			let payload = {
				page: pagination.current,
				limit: pagination.pageSize,
				country: country ? country : null,
				client_id: client_id ? client_id : null,
				// search: debouncedSearch || '',
				program: program ? program : null,
				search:search,
				sbu_head: sbu_head_name ? sbu_head_name : null,
				validator_verifier: validator_verifier ? validator_verifier : null,
				team_leader: teamLeader ? teamLeader : null,
				trainee_validator: trainee ? trainee : null,
				scope: scope ? scope : null,
				project_status: status ? status : null,
				pending_at: ids?.id ?? null
			};

			const response = await axios.post(
				`${at_allprojects_url}`,
				payload,
				API_HEADER,
			);

			setDataSource(response.data.records.data);
			setloadData(false);

			setPagination((prevPagination) => ({
				...prevPagination,
				total: response.data.records.total,
			}));
			setLoader(false)

		} catch (error) {
		}
	};

	useEffect(() => {
		allData();
	}, [loadData]);

	const handleSearch = (value) => {
		setSearchValue(value)
		setSearch(value);
		setloadData(true);
	};

	const columns = [
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					S.No
				</span>
			),
			dataIndex: "id",
			width: 80,
			fixed:'left',
			render: (text, record, index) => {
				const pageIndex = (pagination.current - 1) * pagination.pageSize;
				return pageIndex + index + 1;
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					EID
				</span>
			),
			width: 150,

			// fixed: "left",
			// dataIndex: "earthood_id",
			render: (text, record) => {
				return (
					<Tip title={record.earthood_id}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.earthood_id}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Project Name
				</span>
			),
			width: 160,
			render: (text, record) => {
				return (
					// <span className="text-capitalize textcolor font14px">
					// 	{record.project_name}
					// </span>

					<Tip title={record.project_name}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.project_name}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Client Name
				</span>
			),
			width: 120,
			fixed:'left',
			render: (text, record) => (
				// <span className="text-capitalize font14px">{record.client_name.name}</span>
				<Tip title={record.client_name?.name}>
					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
						{record.client_name?.name}
					</span>
				</Tip>
			),

		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Program
				</span>
			),
			width: 100,

			render: (text, record) => {
				return (
					// <span className="text-capitalize font14px">
					// 	{record.program_name.description}
					// </span>
					<Tip title={record.program_name?.description}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.program_name?.description}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
			}
		},

		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Scope
				</span>
			),

			width: 150,

			render: (text, record) => {

				if (record.sbu_head_name != null) {
					return (
						
						<Tip title={record.scope_name?.scope}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.scope_name?.scope}
							</span>
						</Tip>
					);
				}



			},
			sorter: (record1, record2) => {
				return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Program ID
				</span>
			),
			width: 150,

			render: (text, record) => {
				return (
					// <span className="text-capitalize font14px">
					// 	{record.program_name.description}
					// </span>
					<Tip title={record.program_id}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.program_id}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Country
				</span>
			),
			width: 100,

			render: (text, record) => (
				// <span className="text-capitalize font14px">{record.country_name?.description}</span>
				<Tip title={record.country_name?.description}>
					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
						{record.country_name?.description}
					</span>
				</Tip>
			),
			sorter: (record1, record2) => {
				return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
			}
		},

		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					SBU Head
				</span>
			),

			width: 150,

			render: (text, record) => {

				if (record.sbu_head_name != null) {
					return (
						// <p className="px-lg-4 py-lg-2 rounded-5 text-sm" >
						//     {record.sbu_head_name?.name}
						// </p>
						<Tip title={record.sbu_head_name?.name}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.sbu_head_name?.name}
							</span>
						</Tip>
					);
				}



			},
			sorter: (record1, record2) => {
				return (record1.sbu_head_name?.name > record2.sbu_head_name?.name) ? 1 : (record1.sbu_head_name?.name === record2.sbu_head_name?.name) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Team Leader
				</span>
			),
			width: 150,
			render: (text, record) => {

				if (record.team_leader_name != null) {
					return (
						// <p className="px-lg-4 py-lg-2 rounded-5 text-sm" >
						//     {record.team_leader_name?.name}
						// </p>
						<Tip title={record.team_leader_name?.name}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.team_leader_name?.name}
							</span>
						</Tip>
					);
				}



			},
			sorter: (record1, record2) => {
				return (record1.team_leader_name?.name > record2.team_leader_name?.name) ? 1 : (record1.team_leader_name?.name === record2.team_leader_name?.name) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Validator/ Verifier
				</span>
			),
			width: 150,
			render: (text, record) => {

				if (record.validator_verifier_name != null) {
					return (
						// <p className="px-lg-4 py-lg-2 rounded-5 text-sm" >
						//     {record.validator_verifier_name?.name}
						// </p>
						<Tip title={record.validator_verifier_name?.name}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.validator_verifier_name?.name}
							</span>
						</Tip>
					);
				}

			},
			sorter: (record1, record2) => {
				return (record1.validator_verifier_name?.name > record2.validator_verifier_name?.name) ? 1 : (record1.validator_verifier_name?.name === record2.validator_verifier_name?.name) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Trainee Validator
				</span>
			),
			width: 150,
			render: (text, record) => {

				if (record.trainee_name != null) {
					return (
						// <p className="px-lg-4 py-lg-2 rounded-5 text-sm" >
						//     {record.trainee_name?.name}
						// </p>
						<Tip title={record.trainee_name?.name}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.trainee_name?.name}
							</span>
						</Tip>
					);
				}



			},
			sorter: (record1, record2) => {
				return (record1.trainee_name?.name > record2.trainee_name?.name) ? 1 : (record1.trainee_name?.name === record2.trainee_name?.name) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Pending Days
				</span>
			),
			width: 100,
			render: (text, record) => {

				if (record.pending_days != null) {
					return (
						// <p className="px-lg-4 py-lg-2 rounded-5 text-sm" >
						//     {record.pending_days.name}
						// </p>
						<Tip title={record.pending_days}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.pending_days}
							</span>
						</Tip>
					);
				}



			},
			sorter: (record1, record2) => {
				return (record1.pending_days > record2.pending_days) ? 1 : (record1.pending_days === record2.pending_days) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Project Status
				</span>
			),
			width: 150,
			render: (text, record) => (

				<ProjectStatusSchema status={record.project_status} />

			),
			sorter: (record1, record2) => {
				return (record1.project_status > record2.project_status) ? 1 : (record1.project_statuse === record2.project_status) ? 0 : -1
			}
		},

		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Action
				</span>
			),
			dataIndex: "",
			key: "x",
			width: 90,
			render: (record) => (
				<a className="">

					<EyeOutlined
						onClick={() => editForm(record)}
						style={{ color: "blue", fontSize: '20px' }}
					/>
				</a>
			),
		},
	];

	return (
		<>
			<Header />
			<SideNavbar />
			<div className="content-wrapper bg-white">
				<div className="content">
					<div className="container-fluid">
						<div className="row"
							style={{
								justifyContent: "center",

							}}
						>

							<div className="">

								<div className="mx-3 my-3 border-1 border border-light-subtle p-0 rounded-3 mt-5">

									<div className="row border-0 ">
										<div className="col-12 ">
											<p className="text-black text-capitalize  font20px fw-bold p-3 rounded-top-3">
												All Projects
											</p>
										</div>
									</div>
									<div className='row d-flex'>


										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Country </label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Country"
													onChange={handleCountrySearch}
												>
													<Option value="">Select</Option>
													{countryList.map((country, index) => (
														<Option key={index} value={country.id} label={country.name}>
															{country.name}
														</Option>
													))}
												</Select>
											</div>

										</div>

										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Program </label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Program"
													onChange={handleProgramSearch}
												>
													<Option value="">Select</Option>

													{programList.map((program, index) => (
														<Option key={index} value={program.id} label={program.program_name}>
															{program.program_name}
														</Option>
													))}
												</Select>
											</div>

										</div>

										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Validator/Verifier</label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Validator/Verifier"
													onChange={handleValidatorSearch}
												>
													<Option value="">Select</Option>

													{validatorVerifierList.map((option, index) => (
														<Option key={index} value={option.id} label={option.name}>
															{option.name}
														</Option>
													))}
												</Select>
											</div>

										</div>


										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>SBU Head </label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select SBU Head"
													onChange={handleSBUSearch}
												>
													<Option value="">Select</Option>

													{sbuHeadList.filter(
														(option) => option.designation_id == 9
													).map((option, index) => (
														<Option key={index} value={option.id} label={option.name}>
															{option.name}
														</Option>
													))}
												</Select>
											</div>

										</div>

									</div>


									<div className='row d-flex'>



										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Team Leader</label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Team Leader"
													onChange={handleTeamLeaderSearch}
												>
													<Option value="">Select</Option>
													{teamLeaderList.map((option, index) => (
														<Option key={index} value={option.id} label={option.name}>
															{option.name}
														</Option>
													))}
												</Select>
											</div>

										</div>

										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Trainee </label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Trainee"
													onChange={handleTraineeSearch}
												>
													<Option value="">Select</Option>

													{traineeList.map((option, index) => (
														<Option key={index} value={option.id} label={option.name}>
															{option.name}
														</Option>
													))}
												</Select>
											</div>

										</div>

										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Client Name</label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Client"
													onChange={handleClientNameSearch}
												>
													<Option value="">Select</Option>

													{clientNameList.map((option, index) => (
														<Option key={index} value={option.id} label={option.name}>
															{option.name}
														</Option>
													))}
												</Select>
											</div>

										</div>

										<div className="col-sm-3 col-md-3 col-lg-3">

											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Project Status</label>
												<Select
													allowClear
													showSearch
													filterOption={filterOption}
													placeholder="Select Status"
													onChange={handleStatusSearch}
												>
													<Option value="">Select</Option>
													<Option value="0">Inactive</Option>
													{statusList.map((option, index) => (
														<Option key={index} value={option.key} label={option.value}>
															{option.value}
														</Option>
													))}
												</Select>
											</div>



										</div>

									</div>

									<div className='row d-flex'>
										<div className="col-sm-3 col-md-3 col-lg-3">
											<div className='d-grid mb-3 mx-3'>
												<label className='text-capitalize textcolumntitle font12px fw-bold'>Project Delay Days</label>
												<Select
													allowClear
													placeholder="Select Pending Days"
												>
													<Option value="">Select</Option>
													<Option value="10 days">10 Days</Option>
													<Option value="20 days">20 Days</Option>
													<Option value="30 days">30 Days</Option>
												</Select>
											</div>
										</div>

                                    <div className="col-sm-3 col-md-3 col-lg-3">
                                    
                                    <div className='d-grid mb-3 mx-3'>
                                    	<label className='text-capitalize textcolumntitle font12px fw-bold'>Scope </label>
                                    	<Select
                                    		allowClear
                                    		showSearch
                                    		filterOption={filterOption}
                                    		placeholder="Select Scope"
                                    		onChange={handleScopeSearch}
                                    	>
                                    		<Option value="">Select</Option>
                                    
                                    		{scopeList.map((option, index) => (
                                    			<Option key={index} value={option.id} label={option.sector_name}>
                                    				{option.sector_name}
                                    			</Option>
                                    		))}
                                    	</Select>
                                    </div>
                                    
                                    </div>

										<div className="col-sm-5 col-md-5 mt-4" >
											<Input.Search
												allowClear
												placeholder="Search By  Project Name/EID/Program ID..."
												onChange={(e) => setSearchValue(e.target.value)}
												value={searchValue}
												enterButton
												onSearch={handleSearch}
												style={{ width: "100%" }}
												className="rounded-2 custom-search"
											// buttonStyle={{ backgroundColor: '#fff' }} // Change color here
											/>
										</div>
									</div>



									<div className="row my-3 mx-3">
										<Table
											className="border-1 border ronded-0 border-subtle-light px-0"
											scroll={{
												x: 1500,
											}}
											columns={columns}
											dataSource={dataSource}
											rowKey="proposal_id"
											pagination={pagination}
											onChange={handleTableChange}
											loading={loader}
										/>
				  
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default TotalProjectsAT;
