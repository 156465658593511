import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const PDFFile = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
  
  }, []);
  const exportPdf = async () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.setFontSize(16);
    doc.text("My PDF Title", 120, 10);
    doc.autoTable({
      html: "#my-table",
      startY: 20, 
    });
    doc.save("mypdf.pdf");
  };
  return (
    <div style={{ padding: "30px" }}>
      <button
        className="btn btn-primary float-end mt-2 mb-2"
        onClick={exportPdf}
      >
        Export
      </button>
      <h3>Table Data:</h3>
      <table className="table table-bordered" id="my-table">
        <thead style={{ background: "yellow" }}>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Title</th>
            <th scope="col">Brand</th>
            <th scope="col">Category</th>
            <th scope="col">Price</th>
            <th scope="col">Rating</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data?.products) &&
            data?.products?.map((row) => (
              <tr>
                <td>{row?.id}</td>
                <td>{row?.title}</td>
                <td>{row?.brand}</td>
                <td>{row?.category}</td>
                <td>${row?.price}</td>
                <td>{row?.rating}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default PDFFile;