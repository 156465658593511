import { FileUploader } from "react-drag-drop-files";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { get_project_details_url, API_HEADER, BASE_DOCUMENT, client_doc_url } from "../config";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Progress } from "antd";
import FileUploaderComponent from "../utlis/FileUploaderComponent";


const fileTypes = ["JPG", "JPEG", "PDF", "RAR", "XLS", "XLSX", "DOC", "DOCX", "ZIP", "XLSM"];

export default function ClientLODReview() {

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size

    const { id } = useParams();
    const navigate = useNavigate()

    const [rowCount, setRowCount] = useState(1);
    const [filePDDSizeError, setFilePDDSizeError] = useState(false);
    const [fileERSizeError, setFileERSizeError] = useState(false);
    const [fileMRSizeError, setFileMRSizeError] = useState(false);
    const [fileIRRSizeError, setFileIRRSizeError] = useState(false);
    const [fileOtherSizeError, setFileOtherSizeError] = useState(false);
    const [fileCPASizeError, setFileCPASizeError] = useState(false);

    const [file3, setFile3] = useState('');
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file4, setFile4] = useState('');
    const [file5, setFile5] = useState('');
    const [file6, setFile6] = useState('');

    const [f23, setF23] = useState("");
    const [f23name, setF23Name] = useState(null);
    const [pddName, setPddName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setErName] = useState(null);
    const [er, setEr] = useState("");
    const [mrName, setMrName] = useState(null);
    const [mr, setMr] = useState("");
    const [cpaName, setcpaName] = useState(null);
    const [cpa, setcpa] = useState("");
    const [lod, setlod] = useState("");
    const [lodName, setlodName] = useState(null);
    const [irrName, setirrName] = useState(null);
    const [irr, setIrr] = useState("");
    const [otherName, setOtherName] = useState(null);
    const [other, setOther] = useState("");
    const [dynamicname, setDynamicName] = useState([]);
    const [dynamic, setDynamic] = useState([]);
    const [files, setFiles] = useState([]);
    const [lodStatus, setLodStatus] = useState(null)
    const [clientRemark, setClientRemarks] = useState("");
    const [tlRemarks, setTLRemarks] = useState("");

    const [scope, setScope] = useState('')
    const [weblink, setWeblink] = useState("");
    const [fileErrors, setFileErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);
    const [formData, setFormData] = useState({
        evidence_name: [],
        project_status: "",
    })


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${get_project_details_url}/${id}`,
                    API_HEADER
                );
                const { record } = response.data;

                record.evidences.map((item, i) => {
                    setFiles(item.name)
                })
                setFiles(record.evidences)

                setTLRemarks(record.lod_tl_remarks)
                setClientRemarks(record.status == 4 || record.status == 1 ? record.client_remarks = "" : record.client_remarks)
                setScope(record.scope_name.sector_name)
                setWeblink(record.weblink)
                setFormData({
                    evidence_name: record.evidences || [],
                    files: record.files || [],
                    tl_remarks: record.tl_remarks,
                    code: record.status,

                    project_status: record.project_status,
                });

                setLodStatus(record.status)
                setRowCount(record.evidences ? (record.evidences.length == 0 ? 1 : record.evidences.length) : 1);


                let url0 = `${BASE_DOCUMENT}/projects/${record.id}/${record.lod_documents}`;
                setlodName(url0);
                setlod(record.lod_documents);

                let url3 = `${BASE_DOCUMENT}/documents/${record.earthood_id.split(' ')[0]}/${record.f23_doc}`;
                setF23Name(url3);
                setF23(record.f23_doc);

                let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;
                setPddName(url4);
                setPdd(record.pdd_documents);

                let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;
                setErName(url5);
                setEr(record.er_documents);

                let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;
                setMrName(url6);
                setMr(record.mr_documents);

                let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
                setirrName(url7);
                setIrr(record.irr_documents);

                let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
                setcpaName(url8);
                setcpa(record.cpa_documents);

                let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.other_documents}`;
                setOtherName(url9);
                setOther(record.other_documents);


                const dynamicUrl = record.evidences.map((evidence) => `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`)
                setDynamicName(dynamicUrl)
                const dName = record.evidences.map((evidence) => evidence.name);
                setDynamic(dName)

            } catch (error) { }
        };
        fetchData(id);
    }, [id]);

    const CONFIG_Token2 = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token")
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };


    const handleWeblinkChange = (event) => {
        const value = event.target.value;
        setWeblink(value);
    };

    const handleMultiSizeError = (index) => {
        setFileErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = true;
            return newErrors;
        });
    };

    const handleFile = (file, index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = { ...file, index };
            return newFiles;
        });

        // Clear error if file is within size limit
        if (file.size <= dynamicMaxSize) {
            setFileErrors((prevErrors) => {
                const newErrors = [...prevErrors];
                newErrors[index] = false;
                return newErrors;
            });
        }
        else {
            setFileErrors([])
        }
    };


    const deleteRow = (index) => {
        setFormData((prevFormData) => {
            const newEvidenceName = [...prevFormData.evidence_name];
            newEvidenceName.splice(index, 1);
            return { ...prevFormData, evidence_name: newEvidenceName };
        });

        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });

        setFileErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors.splice(index, 1);
            return newErrors;
        });

        setRowCount((prevCount) => prevCount - 1);
    };

    const addRow = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            evidence_name: [...prevFormData.evidence_name, { remarks: "" }],
        }));

        setFiles((prevFiles) => [...prevFiles, null]);
        setFileErrors((prevErrors) => [...prevErrors, false]);
        setRowCount((prevCount) => prevCount + 1);
    };

    const handleEvidenceChange = (index, value) => {
        setFormData((prevFormData) => {
            const newEvidenceName = [...prevFormData.evidence_name];
            newEvidenceName[index] = { remarks: value };
            return { ...prevFormData, evidence_name: newEvidenceName };
        });
    };

    const handleRemarksChange = (content) => {
        setClientRemarks(content)
    }

    const handleSubmitDoc = async (event) => {
        event.preventDefault();
        setIsSubmitting(false);


        if (clientRemark == null || clientRemark == '<p><br></p>' || clientRemark == '') {
            toast.error('Please Add Some Remarks')
        }
        else {

            try {
                setIsSubmitting(true);

                const formDataToSend = new FormData();

                formDataToSend.append("project_id", id);
                formDataToSend.append("pdd_doc", file3);
                formDataToSend.append("er_doc", file1);
                formDataToSend.append("mr_doc", file2);
                formDataToSend.append("cpa_doc", file4);
                formDataToSend.append("irr_doc", file5);
                formDataToSend.append("other_documents", file6);
                formDataToSend.append('client_remarks', clientRemark)
                formDataToSend.append('weblink', weblink)

                formData.evidence_name.forEach((evidence, index) => {
                    formDataToSend.append(
                        `evidences[${index}][evidence_name]`,
                        evidence.remarks
                    );
                });

                files.forEach((file, index) => {
                    formDataToSend.append(`evidences[${index}][evidence_file]`, file[0]);
                });

                const response = await axios.post(
                    `${client_doc_url}`,
                    formDataToSend,
                    CONFIG_Token2
                );

                if (!response.data.status) {
                    toast.error(response.data.message);
                } else if (
                    filePDDSizeError ||
                    fileERSizeError ||
                    fileMRSizeError ||
                    fileCPASizeError ||
                    fileIRRSizeError ||
                    fileOtherSizeError
                ) {
                    toast.error(`File size must below ${dynamicMaxSize} mb`);
                } else {
                    toast.success("Document Submitted Successfully");
                    setFile3(null);
                    setFile1(null);
                    setFile2(null);
                    setFile4(null);
                    setFile5(null);
                    setFile6(null);
                    // setFiles([]);
                    // setEvidenceList([]);
                    navigate("/dashboard");
                }
            }
            catch (error) {
                toast.error("Documents and Remarks are mandatory");
            } finally {
                setIsSubmitting(false);
            }
        }

    };

    return (
        <>
            {(lodStatus == 4 || formData.project_status == 1) ?
                <>
                    {isSubmitting && (
                        <div className="overlay">
                            <Progress type="circle" percent={percent} />
                        </div>
                    )}

                    <form onSubmit={handleSubmitDoc} method="post">
                        <table className="table table-bordered table-hover table-responsive-sm">
                            <thead>
                                <tr>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                        Document Name
                                    </th>

                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color" >
                                        Upload Document
                                    </th>

                                    {formData.project_status > 1 ? (
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            Uploaded Document
                                        </th>
                                    ) : (
                                        ""
                                    )}

                                </tr>
                            </thead>
                            <tbody>

                                <tr>

                                    <td className="text-center lightgreen p-3">
                                        PDD Document
                                        {scope == "VAL" ?
                                            <span style={{ color: "red" }}>*</span>
                                            : ''
                                        }
                                    </td>



                                    <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file3} setFile={setFile3} />


                                    {formData.project_status > 1 ?
                                        <td>
                                            <a target="_blank" href={pddName}>
                                                {pdd}
                                            </a>
                                        </td>
                                        : ''}
                                </tr>



                                <tr>
                                    <td className="text-center lightgreen p-3">
                                        ER Document
                                        {scope == "VER" || scope == "VAL" ?
                                            <span style={{ color: "red" }}>*</span>
                                            : ''
                                        }
                                    </td>




                                    <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file1} setFile={setFile1} />

                                    {formData.project_status > 1 ?
                                        <td>
                                            <a target="_blank" href={erName}>
                                                {er}
                                            </a>
                                        </td>
                                        : ''}
                                </tr>

                                <tr>


                                    <td className="text-center lightgreen p-3">
                                        MR Document
                                        {scope == "VER" ?
                                            <span style={{ color: "red" }}>*</span>
                                            : ''
                                        }

                                    </td>


                                    <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file2} setFile={setFile2} />


                                    {formData.project_status > 1 ?
                                        <td>
                                            <a target="_blank" href={mrName}>
                                                {mr}
                                            </a>
                                        </td>
                                        : ''}
                                </tr>

                                <tr>
                                    <td className="text-center lightgreen p-3">
                                        CPA Document
                                        <span style={{ color: "red" }}></span>
                                    </td>



                                    <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file4} setFile={setFile4} />


                                    {formData.project_status > 1 ?
                                        <td>
                                            <a target="_blank" href={cpaName}>
                                                {cpa}
                                            </a>
                                        </td>
                                        : ''}
                                </tr>

                                <tr>
                                    <td className="text-center lightgreen p-3">
                                        IRR Document
                                        <span style={{ color: "red" }}></span>
                                    </td>



                                    <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file5} setFile={setFile5} />


                                    {formData.project_status > 1 ?
                                        <td>
                                            <a target="_blank" href={irrName}>
                                                {irr}
                                            </a>
                                        </td>
                                        : ''}
                                </tr>

                                <tr>
                                    <td className="text-center lightgreen p-3">
                                        Other Document

                                    </td>




                                    <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file6} setFile={setFile6} />


                                    {formData.project_status > 1 ?
                                        <td>
                                            <a target="_blank" href={otherName}>
                                                {other}
                                            </a>
                                        </td>
                                        : ''}
                                </tr>
                            </tbody>
                        </table>

                        <table className="table table-bordered table-hover table-responsive-sm">
                            <thead>
                                <tr>
                                    <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                                        S.No.
                                    </th>
                                    <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                                        Evidence Name                               
                                    </th>
                                    <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                                        Upload File
                                    </th>
                                    {formData.project_status > 1 ? (
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                            Uploaded Document
                                        </th>
                                    ) : (
                                        ''
                                    )}
                                    <th className="table-heading-color">
                                        <PlusOutlined onClick={addRow} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {[...Array(rowCount)].map((_, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="evidence_name"
                                                className="form-control"
                                                placeholder="evidence name"                                               
                                                value={
                                                    formData.evidence_name[index]
                                                        ? formData.evidence_name[index].remarks
                                                        : ""
                                                }
                                                onChange={(event) =>
                                                    handleEvidenceChange(index, event.target.value)
                                                }
                                            />
                                        </td>
                                        <td>
                                            <FileUploader
                                                handleChange={(file) => handleFile(file, index)}
                                                name="f23_doc"
                                                multiple={true}
                                                maxSize={dynamicMaxSize}
                                                types={fileTypes}
                                                onSizeError={() => handleMultiSizeError(index)}
                                            />
                                            {fileErrors[index] ? (
                                                <span className="text-danger">
                                                    File size greater than {dynamicMaxSize} mb is not
                                                    allowed
                                                </span>
                                            ) : (
                                                <span>
                                                    {files[index] && files[index][0]?.name && (
                                                        <span>{`File name: ${files[index][0]?.name}`}</span>
                                                    )}
                                                </span>
                                            )}
                                        </td>
                                        {formData.project_status > 1 ? (
                                            <td>
                                                <a href={dynamicname[index]} target="_blank">
                                                    {dynamic[index]}
                                                </a>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                        <td>
                                            <CloseOutlined onClick={() => deleteRow(index)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>



                        <div className=" mb-3">
                            <label
                                htmlFor="weblink"
                                className="form-label lightgreen fs-6"
                            >
                                Weblink
                            </label>

                            <textarea
                                placeholder="Add Weblink"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="weblink"
                                rows={3}
                                value={weblink}
                                onChange={handleWeblinkChange}
                            />

                        </div>

                        {formData.project_status > 1 ?
                            <div className="row">
                                <div className="my-4 col-sm-3">
                                    <label className="mb-3 fs-6 lightgreen">LOD Document</label>
                                </div>
                                <div className="my-4 col-sm-6">
                                    <a
                                        target="_blank"
                                        className="mt-2 d-block"
                                        href={lodName}
                                    >
                                        {lod}
                                    </a>
                                </div>
                            </div>

                            : ''}


                        {formData.project_status > 1 ?
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label className="form-label lightgreen fs-6 mb-2">
                                        Team Leader Remarks
                                    </label>

                                    <ReactQuill
                                        theme="snow"
                                        value={tlRemarks}
                                        name="client_remarks"
                                        readOnly={true}
                                        style={{
                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>
                            : ''}

                        <div className="row">
                            <div className="col-12">
                                <label className="form-label lightgreen fs-6 mb-2">
                                    Client Remarks
                                    <span style={{ color: "red" }}>*</span>
                                </label>

                                <ReactQuill
                                    theme="snow"
                                    value={clientRemark}
                                    name="client_remarks"
                                    onChange={handleRemarksChange}
                                    style={{
                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>


                        <div className="container">
                            <div className="row">
                                <div className="d-flex justify-content-end my-3 col-12 pr-0">

                                    <button
                                        className="btn px-3 fs-5"
                                        style={{
                                            backgroundColor: "#07b6af",
                                            color: "white",
                                        }}
                                        type="submit"
                                    >
                                        Submit
                                    </button>

                                </div>
                            </div>
                        </div>
                        {loading && <div className="loader"></div>}
                    </form>
                </>
                :
                <>
                    <table className="table table-bordered table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th className="text-center lightgreen p-3 fw-bolder fs-6" style={{ background: '#ABEBC6' }}>
                                    Document Name
                                </th>



                                <th className="text-center lightgreen p-3 fw-bolder fs-6" style={{ background: '#ABEBC6' }}>

                                    Uploaded Document
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td className="text-center lightgreen p-3">
                                    PDD Document
                                    {scope == "VAL" ?
                                        <span style={{ color: "red" }}>*</span>
                                        : ''
                                    }
                                </td>



                                <td>
                                    <a target="_blank" href={pddName}>
                                        {pdd}
                                    </a>
                                </td>
                            </tr>



                            <tr>
                                <td className="text-center lightgreen p-3">
                                    ER Document
                                    {scope == "VER" || scope == "VAL" ?
                                        <span style={{ color: "red" }}>*</span>
                                        : ''
                                    }
                                </td>



                                <td>
                                    <a target="_blank" href={erName}>
                                        {er}
                                    </a>
                                </td>

                            </tr>
                            <tr>

                                <td className="text-center lightgreen p-3">
                                    MR Document
                                    {scope == "VER" ?
                                        <span style={{ color: "red" }}>*</span>
                                        : ''
                                    }

                                </td>


                                <td>
                                    <a target="_blank" href={mrName}>
                                        {mr}
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-center lightgreen p-3">
                                    CPA Document
                                    <span style={{ color: "red" }}></span>
                                </td>


                                <td>
                                    <a target="_blank" href={cpaName}>
                                        {cpa}
                                    </a>
                                </td>

                            </tr>

                            <tr>
                                <td className="text-center lightgreen p-3">
                                    IRR Document
                                    <span style={{ color: "red" }}></span>
                                </td>


                                <td>
                                    <a target="_blank" href={irrName}>
                                        {irr}
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-center lightgreen p-3">
                                    Other Document
                                    <span style={{ color: "red" }}></span>
                                </td>

                                <td>
                                    <a target="_blank" href={otherName}>
                                        {other}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>




                    <table className="table table-bordered table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th className="form-label lightgreen fs-6 text-center" style={{ background: '#ABEBC6' }}>
                                    S.No.
                                </th>
                                <th className="form-label lightgreen fs-6 text-center" style={{ background: '#ABEBC6' }}>
                                    Evidence Name                                   
                                </th>





                                <th className="text-center lightgreen p-3 fw-bolder fs-6" style={{ background: '#ABEBC6' }}>

                                    Uploaded Document
                                </th>



                            </tr>
                        </thead>


                        <tbody>
                            {[...Array(rowCount)].map((_, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <input
                                            type="text"
                                            name="evidence_name"
                                            className="form-control"
                                            disabled
                                            placeholder="evidence name"
                                            
                                            value={
                                                formData.evidence_name[index]
                                                    ? formData.evidence_name[index].remarks
                                                    : ""
                                            }
                                            onChange={(event) =>
                                                handleEvidenceChange(
                                                    index,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </td>


                                    <td>
                                        <a target="_blank" href={dynamicname[index]}>

                                            {dynamic[index]}
                                        </a>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>



                    <div className=" mb-3">
                        <label
                            htmlFor="weblink"
                            className="form-label lightgreen fs-6"
                        >
                            Weblink
                        </label>

                        <textarea
                            placeholder="Add Weblink"
                            className="form-control"
                            style={{ width: "100%" }}
                            id="weblink"
                            rows={3}
                            disabled
                            value={weblink}

                        />

                    </div>


                    {lodStatus == 2 || (lodStatus == 5) || (lodStatus == 3) ? '' :
                        <div className="row">
                            <div className="my-4 col-sm-4">
                                <label className="mb-3 fs-6 lightgreen">LOD Document</label>
                            </div>
                            <div className="my-4 col-sm-6">
                                <a
                                    target="_blank"
                                    className="mt-2 d-block"
                                    href={lodName}
                                >
                                    {lod}
                                </a>
                            </div>
                        </div>
                    }


                    {lodStatus == 2 || lodStatus == 5 || lodStatus == 3 ? '' :
                        <div className="row">
                            <div className="col-12">
                                <label className="form-label lightgreen fs-6 mb-3">
                                    Team Leader Remarks
                                </label>

                                <ReactQuill
                                    theme="snow"
                                    value={tlRemarks}
                                    name="client_remarks"
                                    readOnly={true}
                                    style={{
                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-12">
                            <label className="form-label lightgreen fs-6 mb-2">
                                Client Remarks<span style={{ color: "red" }}>*</span>
                            </label>

                            <ReactQuill
                                theme="snow"
                                value={clientRemark}
                                name="client_remarks"
                                readOnly={true}
                                style={{
                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>



                </>


            }
        </>)
}
