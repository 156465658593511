import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  BASE_DOCUMENT,
  get_KickoutInfo_url,
  tldocs_Kickout_url,
  getKickoutDetails,
} from "../../config";
import { useParams } from "react-router-dom";
import { API_HEADER } from "../../config";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";

const TrackKickoutTab = ({project_id,kickout_id}) => {

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkAT, setRemarkAT] = useState("");
  const [remarkClient, setRemarkClient] = useState('');
  const [remarkRegistrar, setRemarkRegistrar] = useState("");

  const [member, setMember] = useState();

  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [file7, setFile7] = useState('');

  const [fvrName, setFVRName] = useState(null)
  const [fvr, setFvr] = useState("")
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null)
  const [auditFinding, setAuditFinding] = useState("")
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");

  const [kickOutDocName, setKickOutDocName] = useState(null)
  const [kickOutDoc, setKickOutDoc] = useState("")
  const [kickoutId, setKickoutId] = useState('')

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${getKickoutDetails}/${project_id}/${kickout_id}`,
        API_HEADER
      );
      const { kickout } = response.data;

      setFile1(kickout.fvr_report)
      setFile2(kickout.err_document)
      setFile3(kickout.pdd_document)
      setFile6(kickout.audit_finding)

      const { record } = response.data;

      setkickoutStatus(record.kickout_status);
      setRemarkTL((record.kickout_status == 1 || record.kickout_status == 2 || record.kickout_status == 5) ? kickout.tl_remarks == "" : kickout.tl_remarks);
      setRemarkAT(kickout.at_remarks);
      setRemarkRegistrar(kickout.kick_registrar_remarks);
      setMember(kickout.kickout_status)
      setRemarkClient(kickout.client_remarks)
      setKickoutId(record.kickout_id)

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.err_document}`;
      setERName(url2);
      setER(kickout.err_document);

      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.irr_document}`;
      setRRName(url5);
      setRR(kickout.irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.other_docs}`;
      setOtherName(url7);
      setOther(kickout.other_docs);

      let url8 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.kickout_document}`;
      setKickOutDocName(url8);
      setKickOutDoc(kickout.kickout_document);


    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  const handleRemarksChange = (content) => {
    setRemarkTL(content);
  };

  return (
    <>
      

          <form
            method="post"
            encType="multipart/form-data"
          >

              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                      Uploaded Document
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      FVR Report
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={fvrName} target="_blank">{fvr}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      ER
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={erName} target="_blank">{er}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      PDD
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={pddName} target="_blank">{pdd}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">4</td>

                    <td className="text-center lightgreen p-3">
                      IRR
                    </td>

                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>



                  </tr>

                  <tr>
                    <td class="text-center">5</td>

                    <td className="text-center lightgreen p-3">
                      CPA
                    </td>

                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">6</td>

                    <td className="text-center lightgreen p-3">
                      Audit Finding
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={auditFindingName} target="_blank">{auditFinding}</a>
                    </td>
                  </tr>


                  <tr>
                    <td class="text-center">7</td>


                    <td className="text-center lightgreen p-3">
                      Other Document
                    </td>






                    <td>
                      <a target="_blank" href={otherName}>
                        {other}
                      </a>
                    </td>


                  </tr>

                </tbody>
              </table>              

              <div className="col-5 my-4 ">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  disabled

                >
                  <option value=''>Send to TR</option>
                </select>
              </div>

              <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkTL}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTL }}

                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {remarkAT && 
          <div>
                <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div className="row mt-4">

                  <ReactQuill
                    theme="snow"
                    value={remarkAT}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkAT }}
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />

                </div>
              </div>}

              {remarkClient && 
          <div>
                <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div className="row mt-4">

                  <ReactQuill
                    theme="snow"
                    value={remarkClient}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkClient }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />

                </div>
              </div>}

          </form>
    </>

  )
}

export default TrackKickoutTab;





