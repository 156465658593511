
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { get_project_details_url, getKickoutDetails, API_HEADER, get_assesment_url, BASE_DOCUMENT, at_desk_review_url } from "../../config";

const fileTypes = ["JPG", "JPEG", "PDF", "RAR", "XLS", "XLSX", "DOC", "DOCX", "ZIP", "XLSM"];

export default function TrackDocument({project_id,kickout_id}) {
    const navigate = useNavigate()

    const [rowCount, setRowCount] = useState(1);
    const [isTL, setIsTL] = useState(false);
    const [projectid, setProjectId] = useState(null);
    const [teamLeader, setTeamLeader] = useState("");
    const [isAT, setIsAT] = useState(false);
    const [formdisable, setFormDisable] = useState(false);
    const [fileTLSizeError, setFileTLSizeError] = useState(false);
    const [file, setFile] = useState(null);
    const [status, SetStatus] = useState(null);
    const [remark, setRemark] = useState("");
    const [lodStatus, setLodStatus] = useState(null)
    const [clientRemarks, setClientRemarks] = useState("");
    const [atsRemarks, setAtsRemarks] = useState("");

    const [f23, setF23] = useState("");
    const [pddName, setPddName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setErName] = useState(null);
    const [lod, setlod] = useState("");
    const [lodName, setlodName] = useState(null);
    const [er, setEr] = useState("");
    const [mrName, setMrName] = useState(null);
    const [mr, setMr] = useState("");
    const [dynamicname, setDynamicName] = useState([]);
    const [dynamic, setDynamic] = useState([]);
    const [irrName, setirrName] = useState(null);
    const [irr, setIrr] = useState("");
    const [cpaName, setcpaName] = useState(null);
    const [cpa, setCpa] = useState("");
    const [f23name, setF23Name] = useState(null);
    const [member, setMember] = useState();

    const [kickOutDocName, setKickOutDocName] = useState(null)
    const [kickOutDoc, setKickOutDoc] = useState("")

    const [formData, setFormData] = useState({ evidence_name: [], })


    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${getKickoutDetails}/${project_id}/${kickout_id}`,
                API_HEADER
            );


            const kickout = response.data.kickout

            if (response && response.data && response.data.record) {
                const record = response.data.record;
                setProjectId(record.id);
                
                if (record.lod_documents) {
                    let url0 = `${BASE_DOCUMENT}/projects/${record.id}/${record.lod_documents}`;
                    setlodName(url0);
                    setlod(record.lod_documents);
                }

                if (record.f23_doc) {
                    let url3 = `${BASE_DOCUMENT}/documents/${record.earthood_id.split(" ")[0]}/${record.f23_doc}`;
                    setF23Name(url3);
                    setF23(record.f23_doc);
                }

                if (record.pdd_documents) {
                    let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;
                    setPddName(url4);
                    setPdd(record.pdd_documents);
                }

                if (record.er_documents) {
                    let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;
                    setErName(url5);
                    setEr(record.er_documents);
                }

                if (record.mr_documents) {
                    let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;
                    setMrName(url6);
                    setMr(record.mr_documents);
                }

                if (record.irr_documents) {
                    let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
                    setirrName(url7);
                    setIrr(record.irr_documents);
                }

                if (record.cpa_documents) {
                    let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
                    setcpaName(url8);
                    setCpa(record.cpa_documents);
                }

                if (record.evidences) {
                    const dynamicUrl = record.evidences.map((evidence) => `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`);
                    setDynamicName(dynamicUrl);

                    const dName = record.evidences.map((evidence) => evidence.name);
                    setDynamic(dName);
                }

                let url9 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.kickout_document}`;
                setKickOutDocName(url9);
                setKickOutDoc(kickout.kickout_document);
            }

        } catch (error) { }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>

            <table className="table table-bordered table-hover table-responsive-sm">
                <thead>
                    <tr>
                        <th className="text-center lightgreen p-3 fw-bolder fs-6 ">
                            S.No
                        </th>
                        <th className="text-center lightgreen p-3 fw-bolder fs-6">
                            Document Name
                        </th>
                        <th className="text-center lightgreen p-3 fw-bolder fs-6">
                            Uploaded Document
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">1</td>
                        <td className="text-center lightgreen p-3">
                            PDD Document
                        </td>
                        <td>
                            <a target="_blank" href={pddName}>
                                {pdd}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center">2</td>

                        <td className="text-center lightgreen p-3">
                            ER Document
                        </td>

                        <td>
                            <a target="_blank" href={erName}>
                                {er}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center">3</td>

                        <td className="text-center lightgreen p-3">
                            MR Document
                        </td>

                        <td>
                            <a target="_blank" href={mrName}>
                                {mr}
                            </a>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-center">4</td>

                        <td className="text-center lightgreen p-3">
                            CPA Document
                        </td>

                        <td>
                            <a target="_blank" href={cpaName}>
                                {cpa}
                            </a>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-center">5</td>

                        <td className="text-center lightgreen p-3">
                            IRR Document
                        </td>

                        <td>
                            <a target="_blank" href={irrName}>
                                {irr}
                            </a>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-center">6</td>

                        <td className="text-center lightgreen p-3">
                            KickOut Document
                        </td>

                        <td>
                            <a href={kickOutDocName} target="_blank">{kickOutDoc}</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </>
    )

}