import React from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { get_proposal_entity } from "../config";
import axios from "axios";

export default function ProposalEntity({formdisabled, proposalEntityRecords, addProposalEntity, deleteProposalEntity, handleProposalEntityChange }) {
    
      const [proposalEntity, setProposalEntity] = useState([]);

      useEffect(() => {
        const fetchDataProposalEntity = async () => {
          try {
            const responseproposalEntity = await axios.get(`${get_proposal_entity}`);
            setProposalEntity(responseproposalEntity.data.data);
          } catch (error) {}
        };
    
      fetchDataProposalEntity();
      }, []);


    return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered table-hover table-responsive-sm">
              <thead>
                <tr>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>S.No.</th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Entity
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Name
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th className="form-label lightgreen font-weight-600 fs-6" style={{background:'#ABEBC6'}}>Relationship With Project
                    <span style={{ color: "red" }}>*</span>
                  </th>
                 
                  {
                    !formdisabled ? (

                      <th style={{background:'#ABEBC6'}}>
                        <a className="">
                          <PlusOutlined
                            onClick={addProposalEntity}
                            style={{color:'black'}}
                          />
                        </a>
                      </th>
                    ) : ""

                  }

                </tr>
              </thead>
              <tbody>
                {proposalEntityRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    <td>

                      <select name="entity" id=""
                        value={record.entity}
                        disabled={formdisabled}
                        className="form-control"
                        onChange={(e) => handleProposalEntityChange(index, e)}
                        required
                      >
                        <option value="">Select</option>
                        {proposalEntity.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.description}
                                    </option>
                                  ))}
                      </select>

                    </td>


                    <td>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={record.name}
                        disabled={formdisabled}
                        onChange={(e) => handleProposalEntityChange(index, e)}
                        placeholder="Name"
                        required
                      />
                    </td>

                    
                    <td>
                      <input
                        type="text"
                        name="relationship"
                        disabled={formdisabled}
                        className="form-control"
                        value={record.relationship}
                        onChange={(e) => handleProposalEntityChange(index, e)}
                        placeholder="Relationship with project"
                        required
                       
                      />


                    </td>


                    {
                      !formdisabled ? (

                        <td>
                          <CloseOutlined onClick={() => deleteProposalEntity(index)} />

                        </td>
                      ) : ""

                    }

                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  );
}
