// import { FileUploader } from "react-drag-drop-files";
// import { DeleteTwoTone } from '@ant-design/icons';
// import React, { useState } from "react";

// const fileTypes = [
//     "JPG",
//     "JPEG",
//     "PDF",
//     "RAR",
//     "XLS",
//     "XLSX",
//     "DOC",
//     "DOCX",
//     "ZIP",
//     "XLSM"
// ];

// const FileUploaderComponent = ({setFile,file, className="p-3" }) => {

//     const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
//     const dynamicMaxSize = my_file_maxSize.max_document_size

//     const [fileSizeError, setFileSizeError] = useState(false);
//     const [key, setKey] = useState(0);

//     const handleFileChange = (file) => {
//         if (file.size <= dynamicMaxSize * 1024 * 1024) {
//             setFileSizeError(false);
//             setFile(file);
//         } else {
//             setFileSizeError(true);
//         }
//     };

//     const handleFileDelete = () => {
//         setFile(null);
//         setKey(prevKey => prevKey + 1); // Reset uploader by changing key
//     };

//     const handleSizeError = () => {
//         setFileSizeError(true);
//     };

//     console.log("filllee:",file)

//     return (
//         <div className>
//             <FileUploader
//               key={key} // Add key to force re-render
//                 handleChange={handleFileChange}
//                 name="doc"
//                 types={fileTypes}
//                 multiple={false}
//                 maxSize={dynamicMaxSize}
//                 required={false}
//                 onSizeError={handleSizeError}
//             />

//             {fileSizeError ? (
//                 <span className="text-danger">
//                     File size greater than {dynamicMaxSize} mb is not allowed
//                 </span>
//             ) : (
//                 file && (
//                     <div>
//                         <span style={{ marginRight: '20px' }}>
//                             <DeleteTwoTone twoToneColor="#eb2f96" onClick={handleFileDelete} />
//                         </span>
//                         <span>{file.name}</span>
//                     </div>
//                 )
//             )}
//         </div>
//     );
// };

// export default FileUploaderComponent;




import { FileUploader } from "react-drag-drop-files";
import { DeleteTwoTone } from '@ant-design/icons';
import React, { useState, useEffect } from "react";

const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];

const FileUploaderComponent = ({ setFile, file}) => {
    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    // const dynamicMaxSize = my_file_maxSize.max_document_size;
    const dynamicMaxSize = my_file_maxSize && my_file_maxSize.max_document_size ? my_file_maxSize.max_document_size : 40 * 1024 * 1024; // 40MB in bytes

    const [fileSizeError, setFileSizeError] = useState(false);
    const [key, setKey] = useState(0);
    const [isFileUploaded, setIsFileUploaded] = useState(false);

    useEffect(() => {
        // Reset isFileUploaded when the file is initially provided (in edit mode)
        if (file) {
            setIsFileUploaded(false);
        }
    }, []);

    const handleFileChange = (file) => {
        if (file.size <= dynamicMaxSize * 1024 * 1024) {
            setFileSizeError(false);
            setFile(file);
            setIsFileUploaded(true); // Mark that the file was uploaded through the uploader
        } else {
            setFileSizeError(true);
        }
    };

    const handleFileDelete = () => {
        setFile(null);
        setKey(prevKey => prevKey + 1); // Reset uploader by changing key
        setIsFileUploaded(false);
    };

    const handleSizeError = () => {
        setFileSizeError(true);
    };

    return (
        <div >
            <FileUploader
                key={key} // Add key to force re-render
                handleChange={handleFileChange}
                name="doc"
                types={fileTypes}
                multiple={false}
                maxSize={dynamicMaxSize}
                required={false}
                onSizeError={handleSizeError}
            />

            {fileSizeError ? (
                <span className="text-danger">
                    File size greater than {dynamicMaxSize} MB is not allowed
                </span>
            ) : (
                file && isFileUploaded && (
                    <div>
                        <span style={{ marginRight: '20px' }}>
                            <DeleteTwoTone twoToneColor="#eb2f96" onClick={handleFileDelete} />
                        </span>
                        <span>{file.name}</span>
                    </div>
                )
            )}
        </div>
    );
};

export default FileUploaderComponent;

