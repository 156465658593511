import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, dvr_report_url, tl_rfi_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import LoaderComponent from "../utlis/LoderComponent";

import {
    API_HEADER,

} from "../config";



const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];

const ClientRfi = (props) => {
    const { id } = useParams();
    const { isTL } = props;

    
   
    
  
    const [rfiStatus, setRfiStatus] = useState("")
    const [tmRfiStatus, setTmRfiStatus] = useState("")


    
    const [fvrName, setFVRName] = useState(null)
    const [fvr, setFvr] = useState("")
    const [pddName, setPDDName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setERName] = useState(null);
    const [er, setER] = useState("");
    const [verificationDeedName, setVerificationDeedName] = useState(null);
    const [verificationDeed, setVerificationDeed] = useState("");
    const [validationDeedName, setValidationDeedName] = useState(null);
    const [validationDeed, setValidationDeed] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);

   

   



    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${ids}`,
                API_HEADER
            );
            const { record } = response.data;



            setRfiStatus(record.status)
           
            setTmRfiStatus(record.rfi_tmaction)



            // setAction(record.status)


            let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.fvr_report}`;
            setFVRName(url1);
            setFvr(record.fvr_report);

            let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
             setERName(url2);
             setER(record.tr_er_doc);

            
             let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
              setPDDName(url3);
              setPdd(record.tr_pdd_doc);

              let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
              setVerificationDeedName(url4);
              setVerificationDeed(record.verification_deed);

              
              let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
              setValidationDeedName(url5);
              setValidationDeed(record.validation_deed);

        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };

    return (
       

            <>
                {
           
                        <form
                           
                        >
                            <table className="table table-bordered  table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            S.No
                                        </th>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                            Document Name
                                        </th>

                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                            Uploaded Document
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">1</td>
                                        <td className="text-center lightgreen p-3">
                                            FVR Report
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <td>
                                            <a href={fvrName} target="_blank">{fvr}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">2</td>
                                        <td className="text-center lightgreen p-3">
                                            ER
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <td>
                                            <a href={erName} target="_blank">{er}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">3</td>
                                        <td className="text-center lightgreen p-3">
                                            PDD/MR
                                            <span style={{ color: "red" }}>*</span>
                                        </td>

                                        <td>
                                            <a href={pddName} target="_blank">{pdd}</a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">4</td>
                                        <td className="text-center lightgreen p-3">
                                            Verification Deed
                                        </td>

                                        <td>
                                            <a href={verificationDeedName} target="_blank">{verificationDeed}</a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">5</td>
                                        <td className="text-center lightgreen p-3">
                                            Validation Deed
                                        </td>

                                        <td>
                                            <a href={validationDeedName} target="_blank">{validationDeed}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </form>
                }
            </>


        
    )
}

export default ClientRfi