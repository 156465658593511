
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT } from "../../../config";
import { get_list_of_users_sbu_cc_url, get_KickoutInfo_url, tr_cc_action_kickout_url } from "../../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER, } from "../../../config";
import FileUploaderComponent from "../../../utlis/FileUploaderComponent";
import LoaderComponent from "../../../utlis/LoderComponent";




const TRKickoutCCRound = (props) => {

  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size



  const [ccTLReviewRemark, setCCTLReviewRemark] = useState('');
  const [ccATReviewRemark, setCCATReviewRemark] = useState('');
  const [ccTRReviewRemark, setCCTRReviewRemark] = useState("");
  const [action, setAction] = useState("");
  const [CCReviewStatus, setCCReviewStatus] = useState('');
  const [changeType, setChangeType] = useState('');


  const [listOfUsers, setListOfUsers] = useState([])
  const [listItem, setListItem] = useState('')

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [kickoutId, setKickoutId] = useState('');

  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [file7, setFile7] = useState('');



  const [fvrName, setFVRName] = useState(null)
  const [fvr, setFvr] = useState("")
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null)
  const [auditFinding, setAuditFinding] = useState("")
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");
  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { kickout } = response.data;

      setKickoutId(record.kickout_id)
      setkickoutStatus(record.kickout_status);

      setCCATReviewRemark(kickout.cc_at_remarks)
      setCCTLReviewRemark(kickout.cc_tl_remarks)
      setCCTRReviewRemark((kickout.kickout_status == 20) ? kickout.cc_tr_remarks = "" : kickout.cc_tr_remarks)
      setCCReviewStatus(kickout.cc_status);
      setListItem(kickout.cc_id)



      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.cc_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_err_document}`;
      setERName(url2);
      setER(kickout.cc_err_document);


      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.cc_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.cc_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_irr_document}`;
      setRRName(url5);
      setRR(kickout.cc_irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.cc_audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_other_docs}`;
      setOtherName(url7);
      setOther(kickout.cc_other_docs);

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${get_list_of_users_sbu_cc_url}`)
      const { data } = response.data
      setListOfUsers(data)
    }
    fetchData()
  }, [])

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const handleRemarksChange = (content) => {
    setCCTRReviewRemark(content);
  };


  const handleActionChange = (e) => {
    setAction(e.target.value);
  };


  const navigate = useNavigate();

  const handleSubmitAction = async (e) => {
    e.preventDefault()
    setIsSubmitting(false);


    if ((ccTRReviewRemark == null || ccTRReviewRemark == '<p><br></p>' || ccTRReviewRemark == '') && action) {
      toast.error('Please Add Some Remarks')
    }
    else {

      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("cc_fvr_report", file1);
        formDataToSend.append("cc_cpa_document", file5);
        formDataToSend.append("cc_err_document", file2);
        formDataToSend.append("cc_pdd_document", file3);
        formDataToSend.append("cc_audit_finding", file6);
        formDataToSend.append("cc_irr_document", file4);
        formDataToSend.append("cc_other_docs[]", file7);
        formDataToSend.append("kickout_status", action);
        formDataToSend.append("cc_tr_remarks", ccTRReviewRemark);
        formDataToSend.append("cc_changes", changeType); // Add change type
        formDataToSend.append("kickout_id", kickoutId);


        const response = await axios.post(
          `${tr_cc_action_kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success(" Submitted Successfully");
          setCCTLReviewRemark("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error(error);
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }
    }
  };


  return (

    <>

      {
        kickoutStatus == 20 ? (
          <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

            <form
              method="post"
              encType="json/form-data"
              onSubmit={handleSubmitAction}
            >
              <div className="cc-review1">
                <div className="cc-review2">
                  <span className="cc-review3">CC Reviewed By </span>
                  <select
                    id="list_of_users"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="list_of_users"
                    value={listItem}
                    readOnly={true}
                    disabled
                    style={{ flex: "1" }}
                  >
                    <option value={""}>Select</option>
                    {listOfUsers.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>


              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Upload Document
                    </th>


                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Uploaded Document
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      FVR Report
                      <span style={{ color: "red" }}>*</span>

                    </td>


                    <FileUploaderComponent file={file1} setFile={setFile1} />


                    <td>
                      <a href={fvrName} target="_blank">{fvr}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      ER
                      <span style={{ color: "red" }}>*</span>

                    </td>


                    <FileUploaderComponent file={file2} setFile={setFile2} />


                    <td>
                      <a href={erName} target="_blank">{er}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      PDD/MR
                      <span style={{ color: "red" }}>*</span>

                    </td>

                    <FileUploaderComponent file={file3} setFile={setFile3} />


                    <td>
                      <a href={pddName} target="_blank">{pdd}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">4</td>


                    <td className="text-center lightgreen p-3">
                      IRR

                    </td>

                    <FileUploaderComponent file={file4} setFile={setFile4} />



                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>



                  </tr>

                  <tr>
                    <td class="text-center">5</td>

                    <td className="text-center lightgreen p-3">
                      CPA

                    </td>

                    <FileUploaderComponent file={file5} setFile={setFile5} />

                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">6</td>



                    <td className="text-center lightgreen p-3">
                      Audit Finding
                      <span style={{ color: "red" }}>*</span>

                    </td>

                    <FileUploaderComponent file={file6} setFile={setFile6} />

                    <td>
                      <a href={auditFindingName} target="_blank">{auditFinding}</a>
                    </td>

                  </tr>


                  <tr>
                    <td class="text-center">7</td>


                    <td className="text-center lightgreen p-3">
                      Other Document

                    </td>

                    <FileUploaderComponent file={file7} setFile={setFile7} />

                    <td>
                      <a target="_blank" href={otherName}>
                        {other}
                      </a>
                    </td>

                  </tr>

                </tbody>
              </table>

              <>

                <p className="lightgreen fw-bold my-3 fs-6">
                  Team Leader Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTLReviewRemark}
                    name="ccremarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: ccTLReviewRemark }}

                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>


                <p className="lightgreen fw-bold my-3 fs-6">
                  Technical Reviewer Remarks<span style={{ color: "red" }}>*</span>

                </p>
                <hr className="mb-3 lightgreen" />
                <div className="row mt-4">
                  <span className="col-10 col-lg-4 col-md-4">
                    <input
                      type="radio"
                      id="approve"
                      name="sbu_head"
                      value={22}
                      onChange={handleActionChange}
                      className="text-success"
                    />
                    <label className="text-success mx-1" htmlFor="approve">Approved</label>
                  </span>
                  <span className="col-10 col-lg-4 col-md-4">
                    <input
                      type="radio"
                      id="clarification_required"
                      name="sbu_head"
                      value={21}
                      onChange={handleActionChange}
                      className="text-danger"
                    />
                    <label className="text-danger mx-1" htmlFor="clarification_required">
                      Clarification Required
                    </label>
                  </span>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <ReactQuill
                      theme="snow"
                      value={ccTRReviewRemark}
                      name="ccremarks"

                      onChange={handleRemarksChange}
                      style={{

                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>

              </>

              <div className="container  d-flex justify-content-end pr-0">
                <button
                  className="btn my-4 px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
              {loading && <div className="loader"></div>}
            </form>
          </>
        ) : (
          <form
            method="post"
            encType="json/form-data"
            onSubmit={handleSubmitAction}
          >
            <div className="cc-review1">
              <div className="cc-review2">
                <span className="cc-review3">CC Reviewed By </span>
                <select
                  id="list_of_users"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="list_of_users"
                  value={listItem}
                  readOnly={true}
                  disabled
                  style={{ flex: "1" }}
                >
                  <option value={""}>Select</option>
                  {listOfUsers.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    FVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={fvrName} target="_blank">{fvr}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={erName} target="_blank">{er}</a>
                  </td>


                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    PDD
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={pddName} target="_blank">{pdd}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">
                    IRR
                  </td>

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">
                    CPA
                  </td>

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>


                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={auditFindingName} target="_blank">{auditFinding}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">7</td>


                  <td className="text-center lightgreen p-3">
                    Other Document
                  </td>


                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>


                </tr>

              </tbody>

            </table>
            <div >
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Leader Remarks
              </p>
              <hr className="mb-3 lightgreen" />

              <ReactQuill
                theme="snow"
                value={ccTLReviewRemark}
                name="cctlremarks"
                readOnly={true}
                dangerouslySetInnerHTML={{ __html: ccTLReviewRemark }}

                style={{

                  height: "20vh",
                  marginBottom: "2rem",
                }}
                className="mb-5"
              />

            </div>
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">
                Technical Reviewer Remarks<span style={{ color: "red" }}>*</span>

              </p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span>
                  {kickoutStatus === 22 ? (
                    <Alert className="col-12" message="Approved" type="success" showIcon />
                  ) : kickoutStatus === 21 ? (
                    <Alert className="col-12" message="Clarification Required" banner />
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={ccTRReviewRemark}
                  name="ccremarks"
                  readOnly={true}


                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>

            </div>
            {loading && <div className="loader"></div>}
          </form>
        )
      }

    </>

  )
}

export default TRKickoutCCRound;