import { Input, Table, Tabs, DatePicker, Button, Select, Tooltip as Tip, Spin } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  faFileSignature,
  faFileArrowDown,
  faFileCircleCheck,
  faFileCircleQuestion,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  get_proposal_detail_url,
  getCountryList,
  get_client_name_url,
  at_tl_data_url,
  get_scope_url,
  get_program_url,
  getAllProjects,
  API_HEADER,
  getDashboardData,
  getAllProposals,
  team_change_listing_url
} from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LegendTable from "../LegendTable";
import ProjectNameWithDelay from "../ProjectNameWithDelay";
import ProposalNameWithDelay from "../Misc/ProposalNameWithDelay";

const { Option } = Select;

export default function TDash() {
  const navigate = useNavigate();

  const mail_data = JSON.parse(localStorage.getItem("mail_reminder"));
  const [searchValue, setSearchValue] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [clientname, setClientname] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [client_id, setClient_id] = useState(null);
  const [proposal_received_tms, setProposal_received_tms] = useState(0);
  const [proposal_sent_clarify, setProposal_sent_clarify] = useState(0);
  const [approved_proposal, setApproved_proposal] = useState(0);
  const [rejected_proposal, setRejected_proposal] = useState(0);
  const [signed_contract, setSigned_contract] = useState(0);
  const [search, setSearch] = useState(null);
  const [siteVisitStatus, setSiteVisitStatus] = useState('')
  const [activeKey, setActiveKey] = useState("1");
  const [statuskey, setStatus] = useState(5);
  const [projectStatuskey, setProjectStatuskey] = useState(9);
  const [kickoutRfiStatus, setKickoutRfiStatus] = useState(23);


  const [alldata, setAlldata] = useState([]);
  const [allRFIdata, setAllRFIdata] = useState(0);
  const [teamChangedata, setTeamChangedata] = useState([]);

  const [rfi, setRFI] = useState(0);
  const [kickoutRfi, setKickoutRfi] = useState(0);
  const [remoteSiteVisit, setRemoteSiteVisit] = useState(0);
  const [kickoutRFI, setKickoutRFI] = useState(0);

  const [teamChangePermission, setTeamChangePermission] = useState(0);


  let [loader, Setloader] = useState(false);
  let [rfiLoad, SetRFILoader] = useState(false);
  let [teamChangeLoader, SetTeamChangeLoader] = useState(false);
  const [scope, setScope] = useState(null);
  const [scopeList, setScopeList] = useState([]);
  const [programname, setProgramname] = useState([]);
  const [program, setProgram] = useState(null);

  let [spinloader, setspinloader] = useState(true);


  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleTeamChange = async (id, teamId) => {
    navigate(`/teamchangemodule/${id}`, { state: { teamId } })
  }


  const dateFormat = "DD/MM/YYYY";
  const getCountry = async () => {
    try {
      const result = await axios.get(`${getCountryList}`);
      setCountryList(result.data.data);
    } catch (error) {
      toast.error("Error fetching country list");
    }
  };
  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {
      toast.error("Error fetching Clientname list");
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getCountry();
    getClientname();
  }, []);



  const handleFromDateChange = (date) => {
    setFromDate(date);
    Setloader(true);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    Setloader(true);
  };
  const disabledFutureDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const handleSearchByDateRange = (value) => {
    const currentDate = moment();
    if (fromDate && fromDate.isAfter(currentDate)) {
      toast.error("From date cannot be a future date");
    } else if (toDate && toDate.isAfter(currentDate)) {
      toast.error("To date cannot be a future date");
    } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
      toast.error("From date cannot be greater than to date");
    }
    else if (!fromDate && !toDate && !country && !client_id) {
      toast.error("Invalid Search")
    }

    else {
      Setloader(true);
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const handleTeamSearch = (value) => {
    setSearch(value);
    SetTeamChangeLoader(true);
  };
  

  const getProgramname = async () => {
    try {
      const result = await axios.get(`${get_program_url}`);
      setProgramname(result.data.data);
    } catch (error) {
      toast.error("Error fetching Clientname list");
    }
  };
  const getScope = async () => {
    try {
      const result = await axios.get(`${get_scope_url}`);
      setScopeList(result.data.data);
    } catch (error) {
      toast.error("Error fetching Scope list");
    }
  };


  const getDashData = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.dashboard;

      setProposal_received_tms(dashboard.status5);
      setProposal_sent_clarify(dashboard.status6);
      setApproved_proposal(dashboard.status9);
      setRejected_proposal(dashboard.status7);
      setSigned_contract(dashboard.status12);
      setRFI(dashboard.under_rfi);
      setRemoteSiteVisit(dashboard.remote_site_visit);
      setKickoutRFI(dashboard.kickout_rfi);

      setTeamChangePermission(dashboard.team_change);

      setspinloader(false);

    } catch (error) {
    }
  };
  const handlePtActions = async (record) => {
    const payload = {
      proposal_id: record.proposal_id,
    };
    const response = await axios.post(
      `${get_proposal_detail_url}`,
      payload,
      API_HEADER
    );
    const data = response.data.record;
    navigate("/ptactions", { state: { data } });

  };

  const editFormForDeskReview = async (id) => {
    navigate(`/atdeskreview/${id}`)
  }

  const editFormForTeamChange = async (id) => {
    navigate(`/teamchangemodule/${id}`)
  }

  const editFormForKickOut = async (id) => {
    navigate(`/kickoutmodule/${id}`)
  }

  const allData = async () => {
    try {
      let payload = {
        status: statuskey,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        search: search ? search : null,
      };
      const response = await axios.post(
        `${getAllProposals}`,
        payload,
        API_HEADER
      );

      setAlldata(response.data.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));

      Setloader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };


  const allRFIData = async () => {
    try {
      let payload = {
        project_status: projectStatuskey,
        status: statuskey,
        kickout_status: kickoutRfiStatus,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        search: search ? search : null,
        site_visit_status: siteVisitStatus,
      };
      const response = await axios.post(
        `${at_tl_data_url}`,
        payload,
        API_HEADER
      );

      setAllRFIdata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      SetRFILoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const allTeamChangeData = async () => {
    try {
      let payload = {

        page: pagination.current,
        limit: pagination.pageSize,
        search: search ? search : null,

      };
      const response = await axios.post(
        `${team_change_listing_url}`,
        payload,
        API_HEADER
      );

      setTeamChangedata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      SetTeamChangeLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };


  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    Setloader(true);
  };

  const handleTeamTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    SetTeamChangeLoader(true);
  };

  const handleTabChange = (key) => {
    setFromDate("");
    setToDate("");
    setCountry("");
    setClient_id("");

    setActiveKey(key);

    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));


    if (key == 1) {
      Setloader(true);
      setStatus(5);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
    } else if (key == 2) {
      Setloader(true);
      setStatus(6);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
    } else if (key == 3) {
      Setloader(true);
      setStatus(9);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
    } else if (key == 4) {
      Setloader(true);
      setStatus(12);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
    } else if (key == 5) {
      Setloader(true);
      setStatus(7);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
    }
    else if (key == 6) {
      setStatus(16);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
      SetRFILoader(true);
    }

    else if (key == 7) {
      setStatus(8);
      setProjectStatuskey('')
      setKickoutRfiStatus('')
      SetTeamChangeLoader(true)
    }
    else if (key == 8) {
      setStatus('')
      setProjectStatuskey('');
      setSiteVisitStatus(5)
      setKickoutRfiStatus('');
      SetRFILoader(true);
    }
    else if (key == 9) {
      SetTeamChangeLoader(true)
    }
    else if (key == 10) {
      setStatus('')
      setProjectStatuskey(11);
      setSiteVisitStatus('')
      setKickoutRfiStatus(25);
      SetRFILoader(true);
    }

  };

  useEffect(() => {
    if(activeKey==1 || activeKey==2 || activeKey==3 || activeKey==4 || activeKey==5){
    allData();
    }
  }, [loader]);

  useEffect(() => {
    if(activeKey==6 || activeKey==8 || activeKey==10){
    allRFIData()
    }
  }, [rfiLoad]);

  useEffect(() => {
    if(activeKey==7 || activeKey==9){
    allTeamChangeData()
    }
  }, [teamChangeLoader]);

  useEffect(() => {
    getDashData();
  }, []);

  const handleClientNameSearch = (value) => {
    setClient_id(value);
    Setloader(true);
  };


  const handleCountrySearch = (value) => {
    setCountry(value);
    Setloader(true);
  };

  const handleProgramNameSearch = (value) => {
    setProgram(value);
    Setloader(true);
  };

  const handleScopeSearch = (value) => {
    setScope(value);
    Setloader(true);
  };

  const columnProposalReceivedPT = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Proposal Date
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.created_at.slice(0, 10)}
            </span>
          </Tip>

        );
      },
      sorter: (record1, record2) => {
        return (record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)) ? 1 : (record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      fixed: "left",
      render: (text, record) => {
        return (
          // <span className="font14px ">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => (

        <ProposalNameWithDelay record={record} />

      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Sector
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      width: 80,
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 100,
      render: (record) => (
        <a className="">
          <EditOutlined
            onClick={() => handlePtActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          />
        </a>
      ),
    },
  ];

  const columnApprovedProposal = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      width: 60,
      dataIndex: "id",
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Proposal Date
        </span>
      ),
      width: 80,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.pt_submit_date.slice(0, 10)}
          // </span>
          <Tip title={record.pt_submit_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.pt_submit_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.pt_submit_date.slice(0, 10) > record2.pt_submit_date.slice(0, 10)) ? 1 : (record1.pt_submit_date.slice(0, 10) === record2.pt_submit_date.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action taken Date
        </span>
      ),
      width: 80,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.tm_action_date.slice(0, 10)}
          // </span>
          <Tip title={record.tm_action_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.tm_action_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.tm_action_date.slice(0, 10) > record2.tm_action_date.slice(0, 10)) ? 1 : (record1.tm_action_date.slice(0, 10) === record2.tm_action_date.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold text-truncate">
          Project Name
        </span>
      ),
      width: "20%",
      fixed: 'left',
      render: (text, record) => (
        <ProposalNameWithDelay record={record} />
      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => {
        return (

          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      width: 80,
      dataIndex: "",


      render: (record) => (
        <a className="">
          <EyeOutlined
            onClick={() => handlePtActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          />
        </a>
      ),
    },
  ];

  const columnSignedContract = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Recd. Date
        </span>
      ),
      width: 100,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.created_at.slice(0, 10)}
            </span>
          </Tip>

        );
      },
      sorter: (record1, record2) => {
        return (record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)) ? 1 : (record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (record) => {
        return (
          // <span className="font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        )
      },

      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold text-truncate">
          Project Name
        </span>
      ),
      width: 140,
      render: (text, record) => {

        return (
          <>
            <span className="text-capitalize font14px">

              <Tip title={record.project_name}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.project_name}
                </span>
              </Tip>

            </span>
          </>
        );
      },
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 140,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.scope > record2.scope) ? 1 : (record1.scope === record2.scope) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px ">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      width: 80,
      dataIndex: "",
      key: "x",
      fixed: "right",
      render: (record) => (
        <a className="">
          <EyeOutlined
            onClick={() => handlePtActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          />
        </a>
      ),
    },
  ];

  const columnRejectedProposal = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Recd. Date
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.created_at.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)) ? 1 : (record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (record) => {
        return (
          // <span className="font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        )
      },

      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          <Tip title={record.project_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.project_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (

          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 130,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.scope > record2.scope) ? 1 : (record1.scope === record2.scope) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px ">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      width: 80,
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 100,
      render: (record) => (
        <a className="">
          <EyeOutlined
            onClick={() => handlePtActions(record)}
            style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          />
        </a>
      ),
    },
  ];

  const RFIColumn = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 130,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 140,
      render: (text, record) => (

        <ProjectNameWithDelay record={record} />

      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 80,
      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 80,
      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen">{record.program_name?.description}</span>
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>

      ),
      sorter: (record1, record2) => {
        return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize font14px">{record.country_name?.description}</span>
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>

      ),
      sorter: (record1, record2) => {
        return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 140,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.status == 15) {
          msg = "Not Started";
          color = "red";
        }
        else if (record.status == 16) {
          msg = "TL submitted FVR to TM";
          color = "orange";
        } else if (record.status == 17) {
          msg = "Tech Mgr raised RFI Clarification";
          color = "blue";
        } else if (record.status == 18) {
          msg = "TM approved RFI";
          color = 'green'
        } else if (record.status == 19) {
          msg = "MD raised clarification";
          color = 'purple'
        }
        else if (record.status == 20) {
          msg = "MD approved";
          color = 'green'
        }
        else {
          msg = record.status;
        }

        return (
          // <p className="px-lg-4 py-lg-2 rounded-5 text-sm" style={{ color }}>
          //     {msg}
          // </p>
          <Tip title={msg}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis" style={{ color }}>
              {msg}
            </span>
          </Tip>
        );
      },

    },



    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>

          {
            record.status == 16 ?
              <EditOutlined
                style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                onClick={() => editFormForDeskReview(record.id)}
              />
              :
              <EyeOutlined
                style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                onClick={() => editFormForDeskReview(record.id)} />
          }





        </a>
      ),
    },
  ];

  const KickoutRFIColumn = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 90,
      render: (text, record) => {
        return (
          <span className="font14px">
            {record.earthood_id}
          </span>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      },
      // defaultSortOrder: 'ascend',
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 140,
      render: (text, record) => (
        // <span className="text-capitalize font14px">{record.client_name?.name}</span>
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 140,
      render: (text, record) => (
        <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 80,
      render: (text, record) => (
        <span className="text-capitalize font14px textcolorgreen">{record.scope_name?.scope}</span>
      ),
      sorter: (record1, record2) => {
        return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <span className="text-capitalize font14px textcolorgreen">{record.program_name?.description}</span>
      ),
      sorter: (record1, record2) => {
        return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <span className="text-capitalize font14px">{record.country_name?.description}</span>
      ),
      sorter: (record1, record2) => {
        return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Status
        </span>
      ),
      width: 140,
      render: (text, record) => {
        let color = "red";
        let msg = "";
        if (record.kickout_status == 23) {
          // msg = "CC approved";
          msg = "TL to CC Reviewer";


        }
        else if (record.kickout_status == 24) {
          // msg = "TL submitted to TM for RFI";
          msg = "CC Approved";

        }
        else if (record.kickout_status == 25) {
          // msg = "TM raised clarification in RFI round";
          msg = "AT Sent to TL";

        }
        else if (record.kickout_status == 26) {
          // msg = "TM approved RFI";TL Sent to AT
          msg = "TL Sent to AT";
        }

        else if (record.kickout_status == 27) {
          // msg = "MD raised clarification in RFI round";
          msg = "TL Sent to TM";
        }

        else if (record.kickout_status == 28) {
          // msg = "MD approved RFI";
          msg = "TM Raised Clarification";
        }
        else if (record.kickout_status == 29) {
          msg = "TM Approved";
        }
        else if (record.kickout_status == 30) {
          msg = "MD Raised Clarification";
        }
        else if (record.kickout_status == 31) {
          msg = "MD Approved";
        }
        else {
          msg = record.status;
        }

        return (
          <p className="px-lg-4 py-lg-2 rounded-5 text-sm" style={{ color }}>
            {msg}
          </p>
        );
      },

    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <a>

          {
            record.kickout_status == 27 ?
              <EditOutlined
                style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                onClick={() => editFormForKickOut(record.id)}
              />
              :
              <EyeOutlined
                style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                onClick={() => editFormForKickOut(record.id)}
              />
          }





        </a>
      ),
    },
  ];


  const columnRemoteSiteVisit = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },


    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>

        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,

      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,

      render: (text, record) => (
        <ProjectNameWithDelay record={record} />
      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen font14px">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolorgreen font14px ">{record.program_name?.description}</span>
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 80,

      render: (text, record) => (
        // <span className="text-capitalize font14px">{record.country_name?.description}</span>
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>

      ),
      sorter: (record1, record2) => {
        return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
      }
    },


    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Site Visit Status
        </span>
      ),
      width: 130,
      render: (text, record) => {
        let color = "black";
        let msg = "";
        if (record.site_visit_status == 0 || record.site_visit_status == null) {
          msg = "Not Scheduled";
          color = "blue";
        }
        else if (record.site_visit_status == 1) {
          msg = "Team Member Sent to TL";
        } else if (record.site_visit_status == 2) {
          msg = "TL Sent to Team Member";
        } else if (record.site_visit_status == 3) {
          msg = "TL Sent to TM Support";
        } else if (record.site_visit_status == 4) {
          msg = "TM Support Raised Clarification";
        } else if (record.site_visit_status == 5) {
          msg = "TM Support Approved";
        } else if (record.site_visit_status == 6) {
          msg = "TM Raised Clarification";
        } else if (record.site_visit_status == 7) {
          msg = "TM Approved";
        } else if (record.site_visit_status == 8) {
          msg = "Team Member Sent to TL";
        } else if (record.site_visit_status == 9) {
          msg = "TL Sent to Team Member";
        } else if (record.site_visit_status == 10) {
          msg = "Team Leader Sent to SBU Head";
        } else if (record.site_visit_status == 11) {
          msg = "SBU Head Raised Clarification";
        } else if (record.site_visit_status == 12) {
          msg = "Site Visit Approved";
        }
        else {
          msg = record.site_visit_status;
        }

        return (
          // <p className="px-lg-4 py-lg-2 rounded-5 text-sm" style={{ color }}>
          //     {msg}
          // </p>
          <Tip title={msg}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis" style={{ color }}>
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.site_visit_status > record2.site_visit_status) ? 1 : (record1.site_visit_status === record2.site_visit_status) ? 0 : -1
      }

    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.site_visit_status == 5 ? (
            <EditOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForDeskReview(record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          )}


        </a>
      ),
    },
  ];

  const ColumnTeamChange = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },


    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 70,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 150,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolor">{record.project_name}</span>
        <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>


      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Team Change Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "black";
        let msg = "";

        if (record.team_change_status == 0) {
          msg = "Not Started";
        }
        else if (record.team_change_status == 1) {
          msg = "SBU Initiate the team Change";
        }
        else if (record.team_change_status == 2) {
          msg = "TL sent to team members";
        }
        else if (record.team_change_status == 3) {
          msg = "Team members sent to TL";
        }
        else if (record.team_change_status == 4) {
          msg = "TL sent to TM support";
        }
        else if (record.team_change_status == 5) {
          msg = "TM support raised the clarification";
        }
        else if (record.team_change_status == 6) {
          msg = "Action Pending";
        }
        else if (record.team_change_status == 7) {
          msg = "TM raised the clarification";
        }
        else if (record.team_change_status == 8) {
          msg = "TM approved";
        }



        return (
          <Tip title={msg}>
            <span className="text-capitalize font14px preserve-space text-ellipsis" style={{ color }}>
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.status > record2.status) ? 1 : (record1.status === record2.status) ? 0 : -1
      }

    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          {record.team_change_status === 6 ? (
            <EditOutlined
              onClick={() => handleTeamChange(record.project_id, record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          ) : (
            <EyeOutlined
              onClick={() => handleTeamChange(record.project_id, record.id)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          )}

        </a>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={spinloader}>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="1"
              centered
              activeKey={activeKey}
              onChange={handleTabChange}
            >

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 1 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Pending for review
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {proposal_received_tms}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="1"
              >

                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className=" bg-white border-0 rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium  p-2 rounded-top-3">
                              Proposal Pending for review
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center  p-2">

                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                              enterButton
                              onSearch={handleSearch}
                            />
                          </div>

                        </div>


                      </div>
                      <Table
                        className="col-12 border-1 border ronded-0 border-subtle-light px-0"
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnProposalReceivedPT}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>


              </Tabs.TabPane>


              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 2 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Sent for clarification
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {proposal_sent_clarify}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleQuestion}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="2"
              >
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">

                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium  p-2 rounded-top-3">
                              Proposal Sent For Clarification
                            </p>
                          </div>
                        </div>
                        <div className="row  p-2 gx-3 align-items-center">

                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value=""><Select></Select></Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>


                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                            <Input.Search

                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="custom-search rounded-2"
                            />                          </div>

                        </div>


                      </div>
                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        className="col-12 border-1 border ronded-0 border-subtle-light px-0"
                        columns={columnApprovedProposal}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                        
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>


              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 3 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Approved Proposal
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {approved_proposal}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="3"
              >
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">

                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium  p-2 rounded-top-3">
                              Approved Proposal
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center  p-2">

                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold ">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>


                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>


                          <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              style={{ width: "100%", }}
                              onSearch={handleSearch}
                              className="custom-search rounded-2"
                            />
                          </div>

                        </div>


                      </div>
                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        columns={columnApprovedProposal}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>


              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 4 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Signed Contract
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {signed_contract}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="4"
              >
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">

                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium  p-2 rounded-top-3">
                              Signed Contract
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 d-flex align-items-center  p-2">

                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle      fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>


                          <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="custom-search rounded-2"
                            />

                          </div>

                        </div>


                      </div>

                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        columns={columnSignedContract}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }

                    </div>
                  </div>
                </div>
              </Tabs.TabPane>


              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 5 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Rejected Proposals
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {rejected_proposal}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="5"
              >

                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className=" bg-white border-0 rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium  p-2 rounded-top-3">
                              Rejected Proposals
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center  p-2">

                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                              enterButton
                              onSearch={handleSearch}
                            />
                          </div>

                        </div>


                      </div>
                      <Table
                        className="col-12 border-1 border ronded-0 border-subtle-light px-0"
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnRejectedProposal}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />

                    </div>
                  </div>
                </div>


              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 6 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      RFI
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {rfi}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileSignature}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="6"
              >
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">

                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium  p-2 rounded-top-3">
                              RFI
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 d-flex align-items-center  p-2">

                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle      fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                max={new Date().toISOString().split('T')[0]}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>


                          <div className="col-sm-4 col-md-4 col-lg-2 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="custom-search rounded-2"
                            />

                          </div>

                        </div>


                      </div>

                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        columns={RFIColumn}
                        loading={loader}
                        dataSource={allRFIdata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }

                    </div>
                  </div>
                </div>
              </Tabs.TabPane>


              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 8 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap">
                      Remote Site Visit
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {remoteSiteVisit}
                      </p>
                      <FontAwesomeIcon
                        icon={faFileCircleCheck}
                        size="xl"
                        className="iconcolor"
                      />
                    </div>
                  </div>
                }
                key="8"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Remote Site Visit
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="d-grid mb-3">
                              <label className="text-capitalize textcolumntitle fw-bold font12px">
                                Scope{" "}
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select scope"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleScopeSearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {scopeList.map((scope, index) => (
                                  <Option
                                    key={index}
                                    value={scope.id}
                                    label={scope.sector_name}
                                  >
                                    {scope.sector_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                program Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramNameSearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {programname.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%" }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%" }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-3 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                            />
                          </div>

                        </div>
                      </div>

                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnRemoteSiteVisit}
                        loading={loader}
                        dataSource={allRFIdata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 10 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap">
                      Kickout RFI
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {kickoutRFI}
                      </p>
                      <FontAwesomeIcon
                        icon={faFileCircleCheck}
                        size="xl"
                        className="iconcolor"
                      />
                    </div>
                  </div>
                }
                key="10"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Kickout RFI
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="d-grid mb-3">
                              <label className="text-capitalize textcolumntitle fw-bold font12px">
                                Scope{" "}
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select scope"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleScopeSearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {scopeList.map((scope, index) => (
                                  <Option
                                    key={index}
                                    value={scope.id}
                                    label={scope.sector_name}
                                  >
                                    {scope.sector_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                program Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramNameSearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {programname.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%" }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%" }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%" }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-3 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                            />
                          </div>

                        </div>
                      </div>

                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        columns={KickoutRFIColumn}
                        loading={loader}
                        dataSource={allRFIdata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 9 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap">
                      Team Change
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {teamChangePermission}
                      </p>
                      <FontAwesomeIcon
                        icon={faFileCircleCheck}
                        size="xl"
                        className="iconcolor"
                      />
                    </div>
                  </div>
                }
                key="9"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Team Change
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center justify-content-end p-2">


                        <div className="col-sm-4 col-md-4 col-lg-3 mb-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                              enterButton
                              onSearch={handleTeamSearch}
                            />
                          </div>

                        </div>
                      </div>

                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        columns={ColumnTeamChange}
                        loading={teamChangeLoader}
                        dataSource={teamChangedata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTeamTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

            </Tabs>
          </div>
        </div>
      </div>
      </Spin>
    </>
  );
}
