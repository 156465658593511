import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const LegendTable = () => {
    const mail_data = JSON.parse(localStorage.getItem("mail_reminder"));
    return (
        <>
       <div className="mb-3 mt-2" style={{lineHeight:"0.3rem"}}>
            <p className="text-secondary px-3 ">
                <FontAwesomeIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ color: "red" }}
                />
                &nbsp;Pending For {mail_data.mail_days_warning} Days
            </p>

           
            <p className=" px-3" style={{color:"red"}}>
                &nbsp;(Pending for more than {mail_data.mail_days_danger} Days)
            </p>

        </div>         
        </>
    )
}

export default LegendTable
