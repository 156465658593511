import { Pagination, Tooltip as Tip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HEADER, get_project_team_info } from "../../config";

const TeamChangeSummary = ({ id, teamId }) => {
    console.log("teamsuma", teamId);

    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${get_project_team_info}/${id}?team_id=${teamId}`, API_HEADER);
                const data = response.data.loghistory || [];
                setActivityData(data);
                setTotalRecords(data.length);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchActivity();
    }, [id, teamId]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12;
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const paginatedData = activityData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <>
            <table className="table table-bordered table-hover team-change-table">
                <thead>
                    <tr>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">S.No.</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Action Taken Date/Time</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Team / Designation</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Remarks</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.length > 0 ? (
                        paginatedData.map((data, index) => (
                            <tr key={index}>
                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                <td>{formatDate(data.created_at)}</td>
                                <td>{data.user_id}</td>
                                <Tip title={data.remarks && data.remarks.replace(/<[^>]+>/g, '')}>
                                    <td className="text-ellipsiss" style={{ maxWidth: '150px' }}>
                                        {data.remarks && data.remarks.replace(/<[^>]+>/g, '')}
                                    </td>
                                </Tip>
                                <Tip title={data.status && data.status.replace(/<[^>]+>/g, '')}>
                                    <td className="text-ellipsiss" style={{ maxWidth: '150px' }}>
                                        {data.status && data.status.replace(/<[^>]+>/g, '')}
                                    </td>
                                </Tip>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center"><h6>No Data</h6></td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
                showSizeChanger
                showQuickJumper
                pageSizeOptions={['5', '10', '20', '50']}
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'end' }}
            />
        </>
    );
};

export default TeamChangeSummary;
