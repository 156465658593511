import React, { useState, useEffect } from "react";
import { Input, Table, Tooltip as Tip, Button } from "antd";
import { MultiSelect } from "react-multi-select-component";
import "../../App.css";
import Header from "../../Pages/Header";
import SideNavbar from "../SideNavbar";
import Footer from "../Footer";
import { API_HEADER, getAllProjectReports } from "../../config";
import axios from "axios";
import exportToExcel from "../../utlis/exportToExcel";

const DynamicTable = () => {
  const defaultColumns = [
    "id",
    "earthood_id",
    "project_name",
    "client_name",
    "project_status",
  ];

  const initialColumns = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      width: 60,
      fixed: "left",
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
      key: "id",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 80,
      dataIndex: "earthood_id",
      render: (text, record) => (
        <Tip title={record.earthood_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.earthood_id}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1,
      key: "earthood_id",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 180,
      dataIndex: "project_name",
      render: (text, record) => (
        <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1,
      key: "project_name",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 180,
      dataIndex: "client_name",
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1,
      key: "client_name",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Status
        </span>
      ),
      width: 150,
      dataIndex: "project_status",
      render: (text, record) => {
        let status = record.project_status;

        let msg = "Inactive";

        if (status == 1) {
          msg = "Pending Docs Submission";
        } else if (status == 2) {
          msg = "Under LOD Review";
        } else if (status == 3) {
          msg = "Under Desk Review";
        } else if (status == 4) {
          msg = "Site Visit Completed";
        } else if (status == 5) {
          msg = "Site Visit Finding";
        } else if (status == 6) {
          msg = "DVR Round";
        } else if (status == 7) {
          msg = "Technical Review";
        } else if (status == 8) {
          msg = "CC Review";
        } else if (status == 9) {
          msg = "Under RFI";
        } else if (status == 10) {
          msg = "RFI Completed";
        } else if (status == 11) {
          msg = "Under Kickout";
        } else if (status == 12) {
          msg = "Issued/Registered";
        }

        return (
          <Tip title={msg}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.project_status > record2.project_status
          ? 1
          : record1.project_statuse === record2.project_status
          ? 0
          : -1;
      },
      key: "project_status",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Scope
        </span>
      ),
      width: 120,
      dataIndex: "scope_name",
      render: (text, record) => (
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1,
      key: "scope_name",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 100,
      dataIndex: "program_name",
      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.program_name?.description > record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1,
      key: "program_name",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 120,
      dataIndex: "country_name",
      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.country_name?.description > record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1,
      key: "country_name",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 120,
      dataIndex: "sectoral_scope",
      render: (text, record) => (
        <Tip title={record.sectoral_scope_names}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.sectoral_scope_names}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.sectoral_scope_names > record2.sectoral_scope_names
          ? 1
          : record1.sectoral_scope_names ===
            record2.sectoral_scope_names
          ? 0
          : -1,
      key: "sectoral_scope",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Team Leader
        </span>
      ),
      width: 150,
      dataIndex: "team_leader",
      render: (text, record) => (
        <Tip title={record.team_leader_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.team_leader_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.team_leader > record2.team_leader
          ? 1
          : record1.team_leader ===
            record2.team_leader
          ? 0
          : -1,
      key: "team_leader",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Validator/Verifier
        </span>
      ),
      width: 150,
      dataIndex: "validator_verifier",
      render: (text, record) => (
        <Tip title={record.validator_verifier_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.validator_verifier_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.validator_verifier > record2.validator_verifier
          ? 1
          : record1.validator_verifier ===
            record2.validator_verifier
          ? 0
          : -1,
      key: "validator_verifier",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Trainee
        </span>
      ),
      width: 150,
      dataIndex: "trainee",
      render: (text, record) => (
        <Tip title={record.trainee_validator_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.trainee_validator_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.trainee > record2.trainee_validator
          ? 1
          : record1.trainee_validator ===
            record2.trainee_validator
          ? 0
          : -1,
      key: "trainee",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          SBU Head
        </span>
      ),
      width: 150,
      dataIndex: "sbu_head",
      render: (text, record) => (
        <Tip title={record.sbu_head_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.sbu_head_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.sbu_head > record2.sbu_head
          ? 1
          : record1.sbu_head ===
            record2.sbu_head
          ? 0
          : -1,
      key: "sbu_head",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Technical Reviewer
        </span>
      ),
      width: 150,
      dataIndex: "technical_reviewer",
      render: (text, record) => (
        <Tip title={record.technical_reviewer_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.technical_reviewer_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.technical_reviewer > record2.technical_reviewer
          ? 1
          : record1.technical_reviewer ===
            record2.technical_reviewer
          ? 0
          : -1,
      key: "technical_reviewer",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          RFI Completion
        </span>
      ),
      width: 120,
      dataIndex: "rfi_completion",
      render: (text, record) => (
        <Tip title={record.rfi_registrar_uploading_date}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.rfi_registrar_uploading_date}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.rfi_completion > record2.rfi_completion
          ? 1
          : record1.rfi_completion ===
            record2.rfi_completion
          ? 0
          : -1,
      key: "rfi_completion",
    },


    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Signed Contract Date
        </span>
      ),
      width: 120,
      dataIndex: "contract_signed_date",
      render: (text, record) => (
        <Tip title={record.contract_signed_date}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.contract_signed_date}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) =>
        record1.contract_signed_date > record2.contract_signed_date
          ? 1
          : record1.contract_signed_date ===
            record2.contract_signed_date
          ? 0
          : -1,
      key: "contract_signed_date",
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    initialColumns
      .filter((col) => defaultColumns.includes(col.key))
      .map((col) => ({ label: col.title.props.children, value: col.key }))
  );

  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState({});
  const [filters, setFilters] = useState({});

  const handleFilterChange = (selectedOptions) => {
    const newFilters = {};
    selectedOptions.forEach((option) => {
      newFilters[option.value] = ""; 
    });
    setFilters(newFilters);
  };

  const [availableFilters, setAvailableFilters] = useState(
    initialColumns.map((col) => ({
      label: col.title.props.children,
      value: col.dataIndex,
    }))
  );

  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [exploader, setexploader] = useState(false);

  const [spin, setSpin] = useState(true);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleSearchByColumn = async () => {
    setLoader(true);
  };

  const resetFilters = async () => {
    setFilters({});
    setLoader(true);
  };

  const handleExport = () => {
    setexploader(true);
    exportToExcel(exportData, filteredColumns);
  };

  const fetchData = async () => {
    try {

      let payload = {
        page: pagination.current,
        limit: pagination.pageSize,
        ...filters,
      };

      const response = await axios.post(
        `${getAllProjectReports}`,
        payload,
        API_HEADER
      );

      setData(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      setLoader(false);
      setSpin(false);

    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const fetchDataforExport = async () => {
    try {
      let payload = {
        page: pagination.current,
        limit: 1000,
        ...filters,
      };

      const response = await axios.post(
        `${getAllProjectReports}`,
        payload,
        API_HEADER
      );

      setExportData(response.data.records.data);

      setexploader(false);
    } catch (error) {
      console.log(error);
      setexploader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loader]);

  useEffect(() => {
    fetchDataforExport();
  }, [exploader]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    setLoader(true);
  };

  const handleChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions);
  };

  const handleFilterValueChange = (column, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  const filteredColumns = initialColumns
    .filter((col) =>
      selectedColumns.map((sel) => sel.value).includes(col.dataIndex)
    )
    .map((col) => ({
      title: col.title,
      dataIndex: col.dataIndex,
      key: col.key,
      render: col.render,
      sorter: col.sorter,
    }));


  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row">
              <h4 className="textcolorblue fw-bolder text-capitalize my-4">
                Project Report
              </h4>

              <div className="col-sm-4 mb-3">
                <Button type="primary" onClick={handleExport} className="mb-4">
                  Export to Excel
                </Button>
              </div>

              <div className="col-sm-4">
                <MultiSelect
                  options={initialColumns.filter((col) => !defaultColumns.includes(col.key)).
                    map((col) => ({
                    label: col.title.props.children,
                    value: col.dataIndex,
                  }))}
                  value={selectedColumns}
                  onChange={handleChange}
                  labelledBy="Select Columns"
                  hasSelectAll={false}    // Disable "Select All"
                  ClearSelectedIcon	={null}  
                />
              </div>

              <div className="col-sm-4">
                <MultiSelect
                  options={availableFilters}
                  value={Object.keys(filters).map((key) => ({
                    label: availableFilters.find((f) => f.value === key).label,
                    value: key,
                  }))}
                  onChange={handleFilterChange}
                  labelledBy="Select Filters"
                  hasSelectAll={false}    // Disable "Select All"
                  overrideStrings={{
                    selectSomeItems: "Select Filters",
                    allItemsAreSelected: "All Filters Are Selected",
                    selectAll: "Select All",
                    clearSearch: "Clear Search",
                    search: "Search",
                  }}
                />
              </div>

              <div className="col-sm-12">
                {Object.keys(filters).length > 0 && (
                  <>
                    <h6>Filters:</h6>

                    <div className="d-flex flex-wrap align-items-center">
                      {Object.keys(filters).map((column) => {
                        const filter = availableFilters.find(
                          (filter) => filter.value === column
                        );
                        return (
                          <div key={column} className="me-2">
                            <span className="text-capitalize">
                              {filter ? filter.label : column}:
                            </span>
                            <Input
                              placeholder={`Search By ${
                                filter ? filter.label : column
                              }`}
                              value={filters[column]}
                              onChange={(e) =>
                                handleFilterValueChange(column, e.target.value)
                              }
                              className="my-2"
                            />
                          </div>
                        );
                      })}

                      <div className="mt-3">
                        <button
                          className="btn btn-success btn-md rounded-2 me-2"
                          onClick={handleSearchByColumn}
                        >
                          Search
                        </button>

                        <button
                          className="btn btn-danger btn-md rounded-2"
                          onClick={resetFilters}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>


              <div className="col-12 mt-4">
                <Table
                 scroll={{
                  x: "100%",
                 }}
                 tableLayout="fixed"
                  loading={spin}
                  columns={filteredColumns}
                  dataSource={data}
                  rowKey="id"
                  pagination={pagination}
                  style={{ position: 'relative', zIndex: 0 }} 
                  onChange={handleTableChange}
                  className="border-1 border ronded-0 border-subtle-light px-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DynamicTable;
