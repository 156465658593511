import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import SiteVisit from "../SiteVisit";
import RemoteSiteVisitTL from "./RemoteSiteVisitTL";
import { API_HEADER, get_project_details_url } from "../../config";
import { useParams } from "react-router-dom";
import ATSiteVisit from "../ATSiteVisit";
import RemoteSiteVisitAT from "./RemoteSiteVisitAT";

const SiteVisitType = ({ isTL }) => {
  const { id } = useParams();
  console.log(id)
  const [visitType, setVisitType] = useState('1');
  const [siteVisitType, setSiteVisitType] = useState("");
  const [siteVisitStatus, setSiteVisitStatus] = useState("")

  const handleSelectionChange = (event) => {
    setVisitType(event.target.value);
  };

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );

      const { record } = response.data;
      setVisitType(record.site_visit_type);
      setSiteVisitType(record.site_visit_type);
      setSiteVisitStatus(record.site_visit_status)

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);


  return (
    <div className="container">
      <div className="row">

        {
          siteVisitStatus == 0 || siteVisitStatus == 1 || siteVisitStatus == 2 ?

            <div className=" col-12 mb-3">

              <div className="col-6">

                <label
                  htmlFor="sitevisitchoices"
                  className="form-label lightgreen fs-6 mr-4"
                >
                  Site Visit Type<span style={{ color: "red" }}>*</span>
                </label>

                <select onChange={handleSelectionChange} value={visitType} className="form-select borderlightgreen form-select-sm">
                  <option value="1">Physical Site Visit</option>
                  <option value="2">Remote Site Visit</option>
                </select>

              </div>


              {isTL && (visitType == "1" && <SiteVisit visitType={visitType} /> ||
                visitType == "2" && <RemoteSiteVisitTL visitType={visitType} />)}

              {!isTL && (visitType == "1" && <ATSiteVisit visitType={visitType} /> ||
                visitType == "2" && <RemoteSiteVisitAT visitType={visitType} />)}
            </div>
            :
            <div className=" col-12 mb-3">

              <div className="col-6">

                <label
                  htmlFor="sitevisitchoices"
                  className="form-label lightgreen fs-6 mr-4"
                >
                  Site Visit Type<span style={{ color: "red" }}>*</span>
                </label>


                <select value={visitType} className="form-select borderlightgreen form-select-sm" disabled>
                  <option value="1">Physical Site Visit</option>
                  <option value="2">Remote Site Visit</option>
                </select>

              </div>


              {isTL && (visitType == "1" && <SiteVisit visitType={visitType} /> ||
                visitType == "2" && <RemoteSiteVisitTL visitType={visitType} />)}

              {!isTL && (visitType == "1" && <ATSiteVisit visitType={visitType} /> ||
                visitType == "2" && <RemoteSiteVisitAT visitType={visitType} />)}


            </div>
        }

      </div>
    </div>
  );
};

export default SiteVisitType;
