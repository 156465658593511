import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { target_analysis_url, API_HEADER } from "../../config";
import { Tag } from "antd";

function TargetAnalysis() {
  const [target, setTarget] = useState(0);  
  const [realValue, setRealValue] = useState([]);
  const [dateCreated, setDateCreated] = useState([]);
  const [createdYear, setCreatedYear] = useState([]);

  useEffect(() => {
    const RealTargetAchieved = [];
    const CreationDate = [];
    const YearCreated = [];

    const gettargetanalysisrecord = async () => {
      const dataReq = await axios.get(`${target_analysis_url}`, API_HEADER);
      const MyTargetAnalysis = dataReq.data.contract_monthly;
      const MyTargetAnalysistarget = dataReq.data;

      for (let i = 0; i < MyTargetAnalysis.length; i++) {
        RealTargetAchieved.push(MyTargetAnalysis[i].target_achived);
        const dateString = MyTargetAnalysis[i].month_year_created;
        const [month, year] = dateString.split('-');
        const date = new Date(year, parseInt(month) - 1);
        const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
        const formattedYear = date.getFullYear();
        CreationDate.push(formattedMonth);
        YearCreated.push(formattedYear);
      }

      setTarget(MyTargetAnalysistarget.monthly_target);
      setRealValue(RealTargetAchieved);
      setDateCreated(CreationDate);
      setCreatedYear(YearCreated);
    };

    gettargetanalysisrecord();
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Chart
          type="bar"
          width={"100%"}
          height={500}
          series={[
            {
              name: 'Actual',
              data: realValue.map((value, index) => ({
                x: dateCreated[index],
                y: value,
                goals: [
                  {
                    name: 'Expected',
                    value: target,
                    strokeHeight: 5,
                    strokeColor: '#e5c185' 
                  }
                ]
              }))
            }
          ]}
          options={{
            chart: {
              toolbar: {
                show: false,  
              },
              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350
                }
              }
            },
            colors: ['#1e3263'],
            theme: { mode: "light" },
            xaxis: {
              tickPlacement: "on",
              title: {
                text: `Month`,
                style: { fontSize: 15,fontWeight:'bold'  },
              },
            },
            yaxis: {
              labels: {
                formatter: (val) => `${val}`,
                style: { fontSize: "15"},
              },
              title: {
                text: "Value in Millions",
                style: { fontSize: 15 ,fontWeight:'bold'},
              },
            },
            grid: {
              borderColor: '#f1f1f1',
              row: {
                colors: ['transparent'], 
                opacity: 1,

              },
            },
            tooltip: {
              enabled: true,
              shared: true,
              intersect: false,
              y: {
                formatter: (val) => `${val}M`,
                title: {
                  formatter: (seriesName) => seriesName
                }
              }
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              customLegendItems: ['Actual', 'Expected'],
              markers: {
                fillColors: ['#1e3263', '#e5c185']
              },
              // position: 'top', // Position the legend at the top
              horizontalAlign: 'center', 
            },
            dataLabels: {
              enabled: true,
              formatter: (val) => `${val}`,
              style: {
                colors: ["#f4f4f4"],
                fontSize: 15,
              },
            },
          }}
        ></Chart>
      </div>
    </React.Fragment>
  );
}

export default TargetAnalysis;
