
import { Table, Pagination } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HEADER, get_project_activity_url } from "../config";

const ActivityClientTL = ({ id }) => {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${get_project_activity_url}/${id}`, API_HEADER);
                setActivityData(response.data.records);
                setLoading(false);
            } catch (error) {
            }
        };
        fetchActivity();
    }, [id]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12; 
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const paginatedData = activityData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <>
            <table className="table table-bordered my-4 table-hover">
                <thead>
                    <tr>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">S.No.</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Action Taken Date/Time</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Team / Designation</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Remarks</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((data, index) => (
                        <tr key={index}>
                            <td>{(currentPage - 1) * pageSize + index + 1}</td>
                            <td>{formatDate(data.created_at)}</td>
                            <td>{data.user_designation}</td>
                            <td>{data.remarks && data.remarks.replace(/<[^>]+>/g, '')}</td>
                            <td>{data.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={activityData.length}
                onChange={handlePageChange}
                showSizeChanger
                showQuickJumper
                pageSizeOptions={['5', '10', '20', '50']}
                className="mt-4"
                style={{display:'flex',justifyContent:'end'}}

               
            />
        </>
    );
};

export default ActivityClientTL;
