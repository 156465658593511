import React from 'react'
import { Table } from "antd";
import { Tooltip as Tip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faBell
} from "@fortawesome/free-solid-svg-icons";
import { API_HEADER, get_proposal_detail_url, getNotificationList, markNotificationAsRead } from '../config';
import axios from "axios";
import { toast } from "react-toastify";
import Header from './Header';
import SideNavbar from '../Components/SideNavbar';
import Footer from '../Components/Footer';

const AllNotifications = () => {

    const navigate = useNavigate();

    let [loader, Setloader] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const markAsRead = async (item) => {
        try {
    
          let payload={
            id:item.id,
            type:2
          }
          const result = await axios.post(`${markNotificationAsRead}`,payload, API_HEADER);
    
          if(result.data.status){
            toast.success("Notification has been marked as read successfully!");
          }
          Setloader(true);

        } catch (error) {
          console.log(error);
        }
      };

    const [alldata, setAlldata] = useState([]);

    const allData = async () => {
        try {
            
            const result = await axios.get(`${getNotificationList}?page=${pagination.current}&limit=${pagination.pageSize}`, API_HEADER);
            setAlldata(result.data.records.data);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: result.data.records.total,
            }));

            Setloader(false);
        } catch (error) {
            toast.error(error.result.data.message);
        }
    };

    useEffect(() => {
        allData();
    }, [loader]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        Setloader(true);
    };
  
    const columnRFIApproved = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
            sorter: (record1, record2) => {
                return (record1.id > record2.id) ? 1 : (record1.id === record2.id) ? 0 : -1
            },
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Title
                </span>
            ),
            width : 90,

            render: (text, record) => {
                return (
                    <Tip title={record.title}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                      {record.title}
                    </span>
                  </Tip>
                );
            },
            sorter: (record1, record2) => {
                return (record1.title > record2.title) ? 1 : (record1.title === record2.title) ? 0 : -1
            },
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                   Message
                </span>
            ),
            width : 140,
            render: (text, record) => (
            <Tip title={record.body}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.body && record.body.replace(/<[^>]+>/g, '')}
                </span>
            </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.body > record2.body) ? 1 : (record1.body === record2.body) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Received Date
                </span>
            ),
            width: 140,
            render: (text, record) => {
                return (
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis" >
                {moment(record.created_at).format('DD-MM-YYYY hh:mm a')}
                </span>
                );
            },
            sorter: (record1, record2) => {
                return (record1.created_at > record2.created_at) ? 1 : (record1.created_at === record2.created_at) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Action
                </span>
            ),
            key: "x",
            fixed: "right",
            width: 90,
            render: (text, record) => (
                <a className="" onClick={() => markAsRead(record)}>
                  Mark as Read
                </a>
            ),

        },
    ];

    return (
        <>

        <Header/>
        <SideNavbar/>
        <div className="content-wrapper bg-white ">
          <div className="content">
            <div className="container-fluid bg-white ">
              <div className="row">
                <div className="col-12">
                <h4 className="textcolorblue fw-bolder text-capitalize my-4">
                  All Notifications
                </h4>

                <Table
                    columns={columnRFIApproved}
                    loading={loader}
                    dataSource={alldata}
                    rowKey="proposal_id"
                    pagination={pagination}
                    onChange={handleTableChange}
                    bordered
                />
      
                </div>
                </div>
            </div>
          </div>
        </div>
        <Footer/>

        </>
    )
}

export default AllNotifications

