import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Tooltip as Tip } from "antd";
import ProjectStatusSchema from "../Schema/ProjectStatusSchema";
import { Table, Tag, Select } from "antd";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_HEADER, clients_allprojects_data_url, get_sectoralscope_url } from "../config";
import { get_proposal_detail_url, getCountryList, get_scope_url, get_client_name_url, get_program_url } from "../config";
import Header from "./Header";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
const { Option } = Select;

const CompletedProjectClient = () => {
	const [dataSource, setDataSource] = useState([]);
	const [loadData, setloadData] = useState(false);
	const [loader, setLoader] = useState(false);

	const [countryList, setCountryList] = useState([]);
	const [programList, setProgramList] = useState([]);
	const [scopeList, setScopeList] = useState([]);
	const [clientname, setClientname] = useState([]);
	const [client_id, setClientId] = useState([]);
	const [country, setCountry] = useState([]);
	const [scope, setScope] = useState([]);
	const [program, setProgram] = useState([]);

	const getCountry = async () => {
		try {
			const result = await axios.get(`${getCountryList}`);
			setCountryList(result.data.data);
		} catch (error) {
		}
	};

	const getProgram = async () => {
		try {
			const result = await axios.get(`${get_program_url}`);
			setProgramList(result.data.data);
		} catch (error) {
		}
	};

	const getScope = async () => {
		try {
			const result = await axios.get(`${get_sectoralscope_url}`);
			setScopeList(result.data.data);
		} catch (error) {
		}
	};



	const handleClientNameSearch = (value) => {
		setClientId(value);
		setloadData(true);
	};

	const handleCountrySearch = (value) => {
		setCountry(value);
		setloadData(true);
	};

	const handleScopeSearch = (value) => {
		setScope(value);
		setloadData(true);
	};


	const handleProgramSearch = (value) => {
		setProgram(value);
		setloadData(true);
	};

	useEffect(() => {
		getCountry();
		getScope();
		getProgram()
	}, []);

	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		setPagination(pagination);
		setloadData(true);
	};

	
	const editForm = async (record) => {
		navigate(`/clientprojectdetails/${record.id}`)
	}

	const columns = [
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					S.No
				</span>
			),
			dataIndex: "proposal_id",
			fixed: "left",
			width: 70,
			render: (text, record, index) => {
				const pageIndex = (pagination.current - 1) * pagination.pageSize;
				return pageIndex + index + 1;
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					EID
				</span>
			),
			fixed: "left",
			width: 90,
			render: (text, record) => {
				return (
					<Tip title={record.earthood_id}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.earthood_id}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Project Name
				</span>
			),
			width: 180,
			render: (text, record) => {
				return (
					<Tip title={record.project_name}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.project_name}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
			}
		},
		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Program
				</span>
			),
			width: 100,

			render: (text, record) => {
				return (
					<Tip title={record.program_name.description}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.program_name.description}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.program_name.description > record2.program_name.description) ? 1 : (record1.program_name.description === record2.program_name.description) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Scope
				</span>
			),
			width: 90,

			render: (text, record) => {
				if (record.scope_name.scope) {
					return (
						<Tip title={record.scope_name.scope}>
							<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
								{record.scope_name.scope}
							</span>
						</Tip>
					);
				} else {
					return null
				}
			},
			sorter: (record1, record2) => {
				return (record1.scope_name.scope > record2.scope_name.scope) ? 1 : (record1.scope_name.scope === record2.scope_name.scope) ? 0 : -1
			}
		},
	
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Action
				</span>
			),
			dataIndex: "",
			key: "x",
			fixed: "right",
			width: 90,
			render: (record) => (
				<a className="">

					
							<EyeOutlined
								onClick={() => editForm(record)}
								style={{ color: "blue", fontSize: '20px' }}
							/>
						
				</a>
			),
		},
	];

	const allData = async () => {
		try {
			setLoader(true)
			let payload = {
				status: "",
				page: pagination.current,
				limit: pagination.pageSize,
				country: country ? country : null,
				client_id: client_id ? client_id : null,
				scope: scope ? scope : null,
				program: program ? program : null,
				filter_status :2
			};

			const response = await axios.post(
				`${clients_allprojects_data_url}`,
				payload,
				API_HEADER,
			);
			setDataSource(response.data.records.data);
			setloadData(false);

			setPagination((prevPagination) => ({
				...prevPagination,
				total: response.data.records.total,
			}));
			setLoader(false)

		} catch (error) {
		}
	};

	useEffect(() => {
		allData();
	}, [loadData]);

	return (
		<>
			<Header />
			<SideNavbar />
			<div className="content-wrapper bg-white">
				<div className="content">
					<div className="container-fluid">
						<div className="row"
							style={{
								justifyContent: "center",
							}}
						>
							
							<div className="">

								<div className="mx-3 my-3 border-1 border border-light-subtle p-0 rounded-3 mt-5">

								<div className="row border-0 ">
										<div className="col-12 ">
											<p className="text-black text-capitalize  font20px fw-bold p-3 rounded-top-3">
											Completed Projects
											</p>
										</div>
									</div>

									<div className="row my-3 mx-3">
										<Table
										className="border-1 border ronded-0 border-subtle-light px-0"
										
											columns={columns}
											dataSource={dataSource}
											rowKey="proposal_id"
											pagination={pagination}
											onChange={handleTableChange}
											loading={loader}
										/>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</>
	);
};

export default CompletedProjectClient;
