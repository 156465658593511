import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Tabs,
  Select,
  Upload,
  message,
  Input,
  Alert,
  Spin,
  Modal,
  Form as NewForm
} from "antd";
import axios from "axios";
import { Tooltip } from 'antd';
import { API_HEADER, BASE_DOCUMENT, get_client_name_url, get_ta_tr_experts, get_contact_person_url, } from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_assesment_url } from "../config";
import {get_validator_verifier} from "../config";
import { toast } from "react-toastify";
import Header from "./Header";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import {
  get_project_details_url,
  edit_assessment_team_url,
  get_scope_pa_poa_url,
  get_meths_url
} from "../config";
import { get_trsbu_url } from "../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { at_desk_review_url } from "../config";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import ActivityChain from "../Components/ActivityChain";
import SiteVisit from "../Components/SiteVisit";
import SiteVisitReview from "../Components/SiteVisitReview";
import DraftFinding from "../Components/DraftFinding";
import ClientDraftFinding from "../Components/ClientDraftFinding";
import SiteFinding from "../Components/SiteFinding";
import { MultiSelect } from "react-multi-select-component";
import TrRound from "../Components/TrRound";
import TrRoundReview from "../Components/TrRoundReview";
import CCReview from "../Components/CCReview";
import CCReviewAction from "../Components/CCReviewAction";
import SBUCCReview from "../Components/SBUCCReview";
import TLRfi from "../Components/TLRfi";
import TMRfiReview from "../Components/TMRfiReview";
import MdRFIRound from "../Components/MdRFIRound";
import LODReview from "../Components/LODReview";
import SiteVisitCompleted from "../Components/SiteVisitCompleted";
import TLDvr from "../Components/TLDvr";
import ATDvr from "../Components/ATDvr";
import ATTrRound from "../Components/ATTrRound";
import RegistrarRFI from "../Components/RegistrarRFI";
import TrCCReview from "../Components/TrCCReview";
import ATSiteFinding from "../Components/ATSiteFinding";
import ATDraftFinding from "../Components/ATDraftFinding";
import ATLod from "../Components/ATLod";
import ATSiteVisit from "../Components/ATSiteVisit";
import ATSiteVisitCompleted from "../Components/ATSiteVisitCompleted";
import ATCCReview from "../Components/ATCCReview";
import ATRFI from "../Components/ATRFI";
import SiteVisitType from "../Components/RemoteSiteVisit/SiteVisitType";
import RemoteSiteVisitTL from "../Components/RemoteSiteVisit/RemoteSiteVisitTL";
import RemoteSiteVisitAT from "../Components/RemoteSiteVisit/RemoteSiteVisitAT";
import RemoteSiteVisitTM from "../Components/RemoteSiteVisit/RemoteSiteVisitTM";
import RemoteSiteVisitTMS from "../Components/RemoteSiteVisit/RemoteSiteVisitTMS";
import TeamChangeSummary from "../Components/TeamChange/TeamChangeSummary";
import ProjectTeamChangeSummary from "../Components/TeamChange/ProjectTeamChangeSummary";
import KickoutSummary from "../Components/kickout/KickoutSummary";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const ATDeskReview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Option } = Select;
  const { id } = useParams();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const cc_id = userdata.id;

  const [projectid, setProjectId] = useState(null);

  const [clientName, setClientName] = useState([]);
  const [sectoralScope, setSectoralScope] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setformSubmitting] = useState(false);

  const [myscope, setMyScope] = useState([]);
  const [program, setProgram] = useState([]);

  const [country, setCountry] = useState([]);
  const [atlist, setAtList] = useState([]);
  const [f23name, setF23Name] = useState(null);
  const [rowCount, setRowCount] = useState(1);
  const [formEdit, setformEdit] = useState(true);
  const [trlist, setTRList] = useState([]);
  const [remark, setRemark] = useState("");
  const [fileTLSizeError, setFileTLSizeError] = useState(false);
  const [file, setFile] = useState(null);
  const [formdisable, setFormDisable] = useState(false);

  const [activeTab, setActiveTab] = useState("1");
  const [isTL, setIsTL] = useState(false);
  const [isAT, setIsAT] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [member, setMember] = useState();
  const [projectstatus, setProjectStatus] = useState(null);
  const [status, SetStatus] = useState(null);
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [siteVisitType, setSiteVisitType] = useState("");
  const [role, setRole] = useState("");

  const [mysectorscope, setMySectorScope] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selected, setSelected] = useState([]);
  const [meth, setMeth] = useState([])
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);

  const [teamLeader, setTeamLeader] = useState("");
  const [taExpert, setTAExpert] = useState("");
  // const [validator, setValidator] = useState("");
  const [financeExpert, setFinanceExpert] = useState("");
  const [localExpert, setLocalExpert] = useState("");
  // const [methExpert, setMethExpert] = useState("");
  // const [traineeExpert, setTraineeExpert] = useState("");
  const [technicalReviewer, setTechnicalReviewer] = useState("");
  const [teamChangeDoc, setTeamChangeDoc] = useState([]);
  const [taTrExpert, setTATRExpert] = useState([]);
  const [mytaexpert, setMyTaexpert] = useState([]);
  const [scope_PA_POA, setScope_PA_POA] = useState([]);

  const [lodStatus, setLodStatus] = useState("");
  const [siteFindingStatus, setSiteFindingStatus] = useState("");
  const [draftFindingStatus, setDraftFindingStatus] = useState("");
  const [TrStatus, setTrStatus] = useState("");
  const [navEdit, setNavEdit] = useState(false);
  const [ccStatus, setCCStatus] = useState("");

  const [clientContactPerson, setClientContactPerson] = useState([]);
  const [selectedclientContactPerson, setselectedclientContactPerson] = useState([]);


  const [selectedClient, setSelectedClient] = useState(null);
  const [monitoringPeriod, setMonitoringPeriod] = useState([]);
  const [weblink, setWeblink] = useState("");
  const [apiData, setAPIData] = useState('')

  const [teamChangeStatus, setTeamChangeStatus] = useState(0)

  const [rfiReport, setRfiReport] = useState("");
  const [rfiReportDoc, setRfiReportDoc] = useState(null);

  const [validatorVerifier, setvalidatorVerifier] = useState([]);
  const [myvalidatorverifier, setMyvalidatorVerifier] = useState([]);

  const [methExpert, setmethExpert] = useState([]);
  const [mymethexpert, setMymethExpert] = useState([]);

  const [traineeValidator, settraineeValidator] = useState([]);
  const [mytraineevalidator, setMytraineevalidator] = useState([]);

  const [gisExpert, setgisExpert] = useState([]);
  const [mygisexpert, setMygisExpert] = useState([]);

  useEffect(() => {
    setMember(isTL ? 4 : 3);
  }, [isTL]);

  const [fileteamChangeDocSizeError, SetFileteamChangeDocSizeError] = useState(false)
  const [teamDocName, setTeamDocName] = useState(null);
  const [teamDoc, setTeamDoc] = useState("");
  const [f23, setF23] = useState("");
  const [pddName, setPddName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setErName] = useState(null);

  const [lod, setlod] = useState("");
  const [lodName, setlodName] = useState(null);

  const [er, setEr] = useState("");
  const [mrName, setMrName] = useState(null);
  const [mr, setMr] = useState("");
  const [dynamicname, setDynamicName] = useState([]);
  const [dynamic, setDynamic] = useState([]);

  const [irrName, setirrName] = useState(null);
  const [irr, setIrr] = useState("");
  const [cpaName, setcpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rfiStatus, setRfiStatus] = useState('');


  const [newRegistrarDocName, setNewRegistrarDocName] = useState(null)
  const [newRegistrarDoc, setNewRegistrarDoc] = useState('')

  const [newValidationDeedName, setNewValidationDeedName] = useState(null);
  const [newValidationDeed, setNewValidationDeed] = useState('');

  const [newVarificationDeedName, setNewVarificationDeedName] = useState(null);
  const [newVarificationDeed, setNewVarificationDeed] = useState('');

  const [newOtherDocName, setNewOtherDocName] = useState(null);
  const [newOtherDoc, setNewOtherDoc] = useState([]);
  const [clientRemark, setClientRemark] = useState("");



  const [formData, setFormData] = useState({
    project_name: "",
    project_status: "",
    earthood_id: "",
    client_id: "",
    country: "",
    program: "",
    program_id: "",
    implemented_fees: "",
    created_at: "",
    meth: '',
    scope: [],
    scope_pa: "",
    sectoral_scope: [],
    team_leader: "",
    ta_expert: [],
    validator_verifier: [],
    finance_expert: "",
    local_expert: "",
    meth_expert: [],
    trainee_validator: [],
    technical_reviewer: "",
    expert_tr: [],
    sbu_head: "",
    pdd_documents: "",
    er_documents: "",
    mr_documents: "",
    evidence_name: [],
    contact_person_id: '',
    deadline_date: '',
    gis_expert: [],


  });

  const [rangeForm] = NewForm.useForm();
  const { RangePicker } = DatePicker;



  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );

      if (response && response.data && response.data.record) {
        const { record } = response.data;

        setProjectId(record.id);
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record.program}`
        );
        setAPIData(record)

        setAtList(responseteamleader.data.data);
        setSelectedClient(record.client_id);
        setTeamLeader(record.team_leader);
        // setTAExpert(record.ta_expert);
        // setValidator(record.validator_verifier);
        setFinanceExpert(record.finance_expert);
        setLocalExpert(record.local_expert);
        //setmethExpert(record.meth_expert);
        // setTraineeExpert(record.trainee_validator);
        setTechnicalReviewer(record.technical_reviewer)
        setWeblink(record.weblink)
        setClientRemark(record.client_finding_remarks);
        setTeamChangeStatus(record.team_change_status);
 
        setFormData({
          project_name: record.project_name,
          project_status: record.project_status,
          earthood_id: record.earthood_id,
          client_id: record.client_id,
          country: record.country,
          program: record.program,
          program_id: record.program_id,
          implemented_fees: record.implemented_fees,
          scope: record.scope,
          scope_pa: record.scope_pa,
          sectoral_scope: selected,
          evidence_name: record.evidences || [],
          team_leader: record.team_leader,
          ta_expert: record.ta_expert,
          validator_verifier: record.validator_verifier,
          finance_expert: record.finance_expert,
          local_expert: record.local_expert,
          meth_expert: record.meth_expert,
          trainee_validator: record.trainee_validator,
          technical_reviewer: record.technical_reviewer,
          expert_tr: record.expert_tr,
          sbu_head: record.sbu_head,
          status: record.status,
          meth: record.meth,
          contact_person_id: record.contact_person_id,
          deadline_date: record.deadline_date,
          gis_expert: record.gis_expert    


        });

        if (record?.monitoring_period) {
          setMonitoringPeriod(JSON.parse(record.monitoring_period))
        }

        const isTeamLeader = record.team.some((item) => item.role_id == 1);
        setIsTL(isTeamLeader);

        const isTeamMember = record.team.some((item) => item.role_id > 1);
        setIsAT(isTeamMember);

        if (record.cc_id == cc_id) {
          setIsCC(true);
        } else {
          setIsCC(false);
        }

        setRowCount(record.evidences ? record.evidences.length : 1);

        setProjectStatus(record.project_status);
        SetStatus(record.status);
        setFormDisable(true);
        setSiteVisitStatus(record.site_visit_status);
        setSiteVisitType(record.site_visit_type);
        setSiteFindingStatus(record.site_finding_status);
        setDraftFindingStatus(record.finding_status);
        setTrStatus(record.tr_status);
        setCCStatus(record.cc_status);
        setLodStatus(record.status);
        setRfiStatus(record.status)
        setformEdit(true)

        if (designation_id == 8) {
          if (isTeamLeader) {
            setRole("Team Leader");
          } else {
            setRole("Team Member");
          }
        }

        let urlteamchange = `${BASE_DOCUMENT}/projects/${record.id}/${record.team_change_document}`;

        setTeamDocName(urlteamchange);
        setTeamDoc(record.team_change_document);


        let url0 = `${BASE_DOCUMENT}/projects/${record.id}/${record.lod_documents}`;

        setlodName(url0);
        setlod(record.lod_documents);

        let url3 = `${BASE_DOCUMENT}/documents/${record.earthood_id.split(" ")[0]
          }/${record.signed_contract}`;

        setF23Name(url3);
        setF23(record.signed_contract);

        let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;

        setPddName(url4);
        setPdd(record.pdd_documents);

        let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;

        setErName(url5);
        setEr(record.er_documents);

        let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;

        setMrName(url6);
        setMr(record.mr_documents);

        let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
        setirrName(url7);
        setIrr(record.irr_documents);

        let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
        setcpaName(url8);
        setCpa(record.cpa_documents);

        let url10 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
        setRfiReportDoc(url10);
        setRfiReport(record.rfi_report);


        let url17 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
        setNewVarificationDeedName(url17);
        setNewVarificationDeed(record.verification_deed);

        let url18 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
        setNewValidationDeedName(url18);
        setNewValidationDeed(record.validation_deed);

        let url19 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
        setNewRegistrarDocName(url19);
        setNewRegistrarDoc(record.rfi_report);

        let otherDocsUrls = record.rfi_registrar_docs.split(',').map(doc => `${BASE_DOCUMENT}/projects/${record.id}/${doc.trim()}`);

        let otherDocsNames = record.rfi_registrar_docs.split(',');

        setNewOtherDocName(otherDocsUrls);
        setNewOtherDoc(otherDocsNames);

        record.evidences.forEach((evidence, index) => {
          let dynamicurl = `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`;
          setDynamicName(dynamicurl);
          setDynamic(evidence.name);
        });

        if (record.project_status == 2) {
          if (record.status == 2 || record.status == 6) {
            setFormDisable(false);
          } else if (record.status == 3 && isTeamLeader) {
            setFormDisable(false);
            setRemark(record.ats_remarks);
          } else if (record.status == 3 && !isTeamLeader) {
            setRemark(record.ats_remarks);
          } else if (record.status == 5 && !isTeamLeader) {
            setFormDisable(false);
            setRemark(record.tl_remarks);
          } else if (record.status == 5 && isTeamLeader) {
            setRemark(record.tl_remarks);
          } else if (record.status == 4) {
            setRemark(record.tl_remarks);
          }
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    if (apiData) {

      const sectorscopes = apiData.sectoral_scope.split(",").map(Number);

      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);

    }
  }, [apiData, mysectorscope])

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    if (monitoringPeriod.length > 0) {
      rangeForm.setFieldValue("startEndDate", [dayjs(monitoringPeriod[0]), dayjs(monitoringPeriod[1])])
    }
    else {
      rangeForm.setFieldValue("startEndDate", [null, null])
    }
  })

  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=7`
        );
        setTATRExpert(responsetatrexpert.data.data.map((taexpert) => ({
          value: taexpert.id,
          label: taexpert.name,
        }))
        )

      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert.includes(taexpert.value)
      );
      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);

  useEffect(() => {
    const fetchDatavalidatorVerifier = async () => {
      try {
        const responsevalidatorverifier = await axios.get(
          `${get_validator_verifier}?skill_id=2 &new_team=1`
        );
        setvalidatorVerifier(responsevalidatorverifier.data.data.map((validator_verifier) => ({
          value: validator_verifier.id,
          label: validator_verifier.name,
        }))
        )

      } catch (error) { }
    };

    
    fetchDatavalidatorVerifier();
    
  }, []);

  console.log("validatorVerifier",validatorVerifier)


  useEffect(() => {
    const fetchDatamethExpert = async () => {
      try {
        const responsemethexpert = await axios.get(
          `${get_validator_verifier}?skill_id=5`
        );
        setmethExpert(responsemethexpert.data.data.map((methexpert) => ({
          value: methexpert.id,
          label: methexpert.name,
        }))
        )

      } catch (error) { }
    };

    
    fetchDatamethExpert();
    
  }, []);

  useEffect(() => {
    const fetchDatatraineeValidator = async () => {
      try {
        const responsetraineevalidator = await axios.get(
          `${get_validator_verifier}?skill_id=3`
        );
        settraineeValidator(responsetraineevalidator.data.data.map((traineevalidator) => ({
          value: traineevalidator.id,
          label: traineevalidator.name,
        }))
        )

      } catch (error) { }
    };

    
    fetchDatatraineeValidator();
    
  }, []);
  

  useEffect(() => {
  
    if (apiData && gisExpert.length > 0) {
      
      const gisExperts = apiData?.gis_expert?.split(",").map(Number);
     
      const filteredGISExpert = gisExpert.filter((expert) =>
        gisExperts.includes(expert.value)
      );

setMygisExpert(filteredGISExpert);
    }
  }, [apiData, gisExpert]);

  useEffect(() => {
  
    if (apiData && methExpert.length > 0) {
      
      const meth = apiData?.meth_expert?.split(",").map(Number);
     
      const filteredMethExpert = methExpert.filter((expert) =>
        meth.includes(expert.value)
      );

      setMymethExpert(filteredMethExpert);
    }
  }, [apiData, methExpert]);

  useEffect(() => {
  
    if (apiData && validatorVerifier.length > 0) {
      
      const validator = apiData?.validator_verifier?.split(",").map(Number);
     
      const filteredValidatorExpert = validatorVerifier.filter((expert) =>
        validator.includes(expert.value)
      );

      setMyvalidatorVerifier(filteredValidatorExpert);
    }
  }, [apiData, validatorVerifier]);

  console.log("rrrr:",myvalidatorverifier)

  useEffect(() => {
  
    if (apiData && traineeValidator.length > 0) {
      
      const traineevalidator = apiData?.trainee_validator?.split(",").map(Number);
     
      const filteredTraineeValidator = traineeValidator.filter((expert) =>
        traineevalidator.includes(expert.value)
      );

      setMytraineevalidator(filteredTraineeValidator);
    }
  }, [apiData, traineeValidator]);


  useEffect(() => {
    const fetchDatagisExpert = async () => {
      try {
        const responsegisexpert = await axios.get(
          `${get_validator_verifier}?skill_id=9`
        );
        setgisExpert(responsegisexpert.data.data.map((gisexpert) => ({
          value: gisexpert.id,
          label: gisexpert.name,
        }))
        )

      } catch (error) { }
    };

    
    fetchDatagisExpert();
    
  }, [apiData]);


  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=8`
        );
        setTRExpert(responsetatrexperts.data.data.map((trexpert) => ({
          value: trexpert.id,
          label: trexpert.name,
        }))
        )
      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {

      const trExperts = apiData.expert_tr?.split(",").map(Number);
      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts.includes(taexpert.value)
      );

      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);



  const handleRemarksChange = (content) => {
    setRemark(content);
  };

  const handleFileChange = (file) => {
    if (file.size <= dynamicMaxSize * 1024 * 1024) {
      setFileTLSizeError(false);
    }

    setFileTLSizeError(false);

    setFile(file);
  };

  const handleTLFileError = (file) => {
    setFileTLSizeError(true);
  };

  useEffect(() => {
    const fetchDataClientName = async () => {
      try {
        const responseclientname = await axios.get(`${get_client_name_url}`);
        setClientName(responseclientname.data.data);
      } catch (error) { }
    };

    fetchDataClientName();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataSope_PA_POA();
  }, []);


  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) { }
    };

    fetchSectoralScope();
  }, []);

  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);
        setMyScope(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchDataClientContactPerson = async () => {
      try {
        let payload = {
          id: selectedClient
        }
       
        const responseclientcontactperson = await axios.post(`${get_contact_person_url}`, payload);
        const formattedClientContactPerson = JSON.parse(responseclientcontactperson.data.data.contact_person).map((person) => ({
          value: person.id,
          label: person.name,
        }));
        
        setClientContactPerson(formattedClientContactPerson);
        
        const contactPersonId = apiData.contact_person_id ? String(apiData.contact_person_id) : '';
        const sectorscopes = contactPersonId.split(",").map(Number);
        const filteredSectorScopes = formattedClientContactPerson.filter((sectorscope) =>
          sectorscopes.includes(sectorscope.value)
        );
        
        setselectedclientContactPerson(filteredSectorScopes);
        
      } catch (error) { 
        console.log(error)
      }
    };
    if (selectedClient) {
      fetchDataClientContactPerson();
    }
  }, [selectedClient]);

  const handleSubmitTL = async (e) => {
    e.preventDefault();
    setformSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("project_id", projectid);
      formData.append("project_status", 2);
      formData.append("status", member);
      formData.append("lod_documents", file);
      formData.append("remarks", remark);

      const response = await axios.post(
        `${at_desk_review_url}`,
        formData,
        CONFIG_Token2
      );
      if (!response.data.status) {
        toast.error(response.data.message);
      } else if (fileTLSizeError) {
        toast.error(`File size must below ${dynamicMaxSize}`);
      } else {
        setformSubmitting(false);
        toast.success("Remarks Submitted Successfully");
        setRemark("");
        setFile(null);
        setMember("");
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error("Documents and Remarks are mandatory");
    }
  };

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) { }
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataCountry = async () => {
      try {
        const responsecountry = await axios.get(`${get_country_url}`);
        setCountry(responsecountry.data.data);
      } catch (error) { }
    };

    fetchDataCountry();
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) { }
    };

    fetchDataTrData();
  }, []);

  useEffect(() => {
    const fetchDataMeth = async () => {
      try {
        const responsemeths = await axios.get(`${get_meths_url}`);
        setMeth(responsemeths.data.data);
      } catch (error) { }
    };

    fetchDataMeth();
  }, []);

  const handleEditAssesmentTeamSubmit = async (e) => {
    e.preventDefault();

    setIsModalOpen(false);
    try {
      const formData = new FormData();
      formData.append("project_id", projectid);
      formData.append("team_leader", teamLeader);
      // formData.append("validator_verifier", validator);
      formData.append("ta_expert", taExpert);
      formData.append("meth_expert", methExpert);
      formData.append("local_expert", localExpert);
      formData.append("finance_expert", financeExpert);
      // formData.append("trainee_validator", traineeExpert);
      formData.append("technical_reviewer", technicalReviewer);
      formData.append("team_change_document", teamChangeDoc);

      Swal.fire({
        title: "Confirmation?",
        text: "Are you sure, you want to proceed with the selected Assessment Team?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.post(
            `${edit_assessment_team_url}`,
            formData,
            CONFIG_Token2
          );

          if (response.status === 200 && response.data.status == true) {
            toast.success("Team Changes Successfully");
            window.location.reload();
            navigate(`/atdeskreview/${id}`);
          } else {
            fetchData();
          }
        } else {
        }
      });
    } catch (error) {
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleTeamChange = () => {
    navigate(`/teamchangemodule/${id}`)
  }


  const handleProposalEdit = () => {
    navigate(`/proposalchangemodule/${id}`)
  }

  const handleTeamChangeDocChange = (teamChangeDoc) => {
    if (teamChangeDoc.size <= dynamicMaxSize * 1024 * 1024) {
      SetFileteamChangeDocSizeError(false);
    }

    SetFileteamChangeDocSizeError(false);
    setTeamChangeDoc(teamChangeDoc);
  };
  const handleTeamChangeDocSizeError = (teamChangeDoc) => {
    SetFileteamChangeDocSizeError(true);
  };

  const combined = newOtherDoc?.map((name, index) => ({
    name: name,
    url: newOtherDocName[index]
  }));


  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row pb-5 ">
              <div className="col-12 ">
                <div className="d-flex mx-3 justify-content-between">
                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    Project Details
                  </h4>
                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    {role}
                  </h4>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  className="mx-5 "
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                  size="large"
                >
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center  ${activeTab == 1 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Project Details</p>
                      </div>
                    }
                    key="1"
                  >
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {isTL ? (
                        <button type="button" class="btn btn-outline-success" onClick={handleProposalEdit}
                          style={{ width: '100px' }}
                        >
                          Edit
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <form>
                      <div className="col-12 border-0 py-3 mx-auto">
                        <fieldset disabled={formEdit}>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="projectname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Tooltip title={formData.project_name} placement="top">
                                <input
                                  type="text"
                                  className="form-control borderlightgreen"
                                  id="project_name"
                                  placeholder="Project Name"
                                  required
                                  name="project_name"
                                  readOnly={true}
                                  value={formData.project_name}
                                />
                              </Tooltip>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="eid"
                                className="form-label lightgreen fs-6"
                              >
                                Earthood Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control borderlightgreen"
                                id="earthood_id"
                                placeholder="Earthood Id"
                                name="earthood_id"
                                required
                                readOnly={true}
                                value={formData.earthood_id}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Client Name
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="client_id"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="client_id"
                                value={formData.client_id}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {clientName.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="contact person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Client Contact Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>
{/* 
                                <select
                                  id="contact_person"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="contact_person"
                                  required
                                  value={formData.contact_person_id}
                                >
                                  <option value={""}>Select</option>
                                  {clientContactPerson && clientContactPerson
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                                 */}

                                 
                              <MultiSelect
                                options={clientContactPerson}
                                value={selectedclientContactPerson}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />

                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program"
                                className="form-label lightgreen fs-6"
                              >
                                Program<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="program"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="program"
                                value={formData.program}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {program.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.program_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="country"
                                className="form-label lightgreen fs-6"
                                required
                              >
                                Project Country<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="country"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="country"
                                value={formData.country}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {country.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope(PO/POA)"
                                className="form-label lightgreen fs-6"
                              >
                                Scope(PA/POA)
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="scope_pa"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope_pa"
                                value={formData.scope_pa}
                                disabled={true}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    scope_pa: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {scope_PA_POA.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.scope}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program Id"
                                className="form-label lightgreen fs-6"
                              >
                                Program Id
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                id="program_id"
                                placeholder="Program Id"
                                // required
                                name="program_id"
                                className="form-control borderlightgreen"
                                value={formData.program_id}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Implementation Fees"
                                className="form-label lightgreen fs-6"
                              >
                                Implementation Fees
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control borderlightgreen"
                                id="implemented_fees"
                                placeholder="Implementation Fees"
                                required
                                name="implemented_fees"
                                value={formData.implemented_fees}
                                readOnly={true}
                              />
                            </div>




                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="SBU Head"
                                  className="form-label lightgreen fs-6"
                                >
                                  SBU Head
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sbu_head"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sbu_head"
                                  required
                                  value={formData.sbu_head}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      sbu_head: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {trlist
                                    .filter(
                                      (option) => option.designation_id == 9
                                    )
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Sectoral Scope
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={mysectorscope}
                                value={selectedSector}
                                onChange={setSelectedSector}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Scope<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="scope"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope"
                                value={formData.scope}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {myscope.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.sector_name}
                                  </option>
                                ))}
                              </select>
                            </div>




                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth

                              </label>
                              <select
                                id="meth"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth"
                                value={formData.meth}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    meth: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {meth.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="deadline Date"
                                className="form-label lightgreen fs-6"
                              >
                                Deadline Date

                              </label>

                              <DatePicker
                                id="deadlinedate"
                                className={`form-control borderlightgreen custom-disabled-date-picker`}
                                style={{ color: 'black' }}
                                disabled={formEdit}
                                format="DD/MM/YYYY"
                                value={formData.deadline_date ? dayjs(formData.deadline_date) : null}
                                // onChange={handleDeadlineDateChange}
                                disabledDate={(current) => current && current < moment().startOf('day')}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Monitoring Period"
                                className="form-label lightgreen fs-6"
                              >
                                Monitoring Period
                              </label>

                              <NewForm form={rangeForm} >
                                <NewForm.Item
                                  name="startEndDate"
                                >
                                  <RangePicker
                                    style={{ width: "100%" }}
                                    format={'DD/MM/YYYY'}
                                    disabled={formEdit}
                                  // onChange={handleMonitoringPeriodChange}
                                  />
                                </NewForm.Item>
                              </NewForm>

                              {/* {proposalDateError && (
                                <div className="invalid-feedback">
                                  {proposalDateError}
                                </div>
                              )} */}
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="weblink"
                                className="form-label lightgreen fs-6"
                              >
                                Weblink
                              </label>

                              <textarea
                                placeholder="Add Weblink"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="weblink"
                                rows={3}
                                // disabled={tm_form_input}

                                value={weblink}
                              // onChange={handleWeblinkChange}
                              />

                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Service Agreement (F23)
                              </label>
                              <div>
                                <a href={f23name}>{f23}</a>
                              </div>
                            </div>

                            {formData.project_status >= 10 ?
                              <>
                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                  <label
                                    htmlFor="taexpert"
                                    className="form-label lightgreen fs-6"
                                  >
                                    RFI Report
                                  </label>
                                  <div>
                                    <a target="_blank" href={newRegistrarDocName}>
                                      {newRegistrarDoc}
                                    </a>
                                  </div>
                                </div>


                                <div className="row">
                                  {newValidationDeed &&
                                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                                      <label
                                        htmlFor="taexpert"
                                        className="form-label lightgreen fs-6"
                                      >
                                        Validation Deed
                                      </label>
                                      <div>
                                        <a target="_blank" href={newValidationDeedName}>
                                          {newValidationDeed}
                                        </a>
                                      </div>
                                    </div>
                                  }

                                  {newVarificationDeed &&
                                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                                      <label
                                        htmlFor="taexpert"
                                        className="form-label lightgreen fs-6"
                                      >
                                        Varification Deed
                                      </label>
                                      <div>
                                        <a target="_blank" href={newVarificationDeedName}>
                                          {newVarificationDeed}
                                        </a>
                                      </div>
                                    </div>
                                  }

                                  {(newOtherDoc.length > 0) &&
                                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                                      <label
                                        htmlFor="taexpert"
                                        className="form-label lightgreen fs-6"
                                      >
                                        Other Docs
                                      </label>
                                      <div>
                                        {/* <a target="_blank" href={newOtherDocName}>
                                        {newOtherDoc}
                                      </a> */}

                                        {combined.map((item) => (
                                          <div>
                                            <a target="_blank" href={item.url}>
                                              {item.name}

                                            </a>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  }

                                </div>
                              </>

                              : ''}

                          </div>

                          {/* </div> */}
                        </fieldset>
                        <div className="d-flex justify-content-between  mt-5 mb-3">
                          <p className="textlightgreen fw-bold fs-6">
                            Assessment Team
                          </p>



                          {(isTL || designation_id == 9 ) && (teamChangeStatus==0)? (
                            <button type="button" class="btn btn-outline-success"
                              onClick={handleTeamChange}
                            >
                              Change Team
                            </button>
                          ) : (
                            ""
                          )}
                        </div>



                        <hr className="mb-5 lightgreen" />
                        <fieldset disabled={true}>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Team Leader
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="team_leader"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="team_leader"
                                required
                                value={formData.team_leader}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 1)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                TA Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>



                              <MultiSelect
                                options={taTrExpert}
                                value={mytaexpert}
                                onChange={setMyTaexpert}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />


                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Local Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="local_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="local_expert"
                                value={formData.local_expert}
                                readOnly={true}
                                required
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 6)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="financeexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Finance Expert
                              </label>

                              <select
                                id="finance_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="finance_expert"
                                value={formData.finance_expert}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 4)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="validator"
                                className="form-label lightgreen fs-6"
                              >
                                Validator/verifier
                              </label>

                              <MultiSelect
                                options={validatorVerifier}
                                value={myvalidatorverifier}
                                onChange={setMyvalidatorVerifier}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />

                              {/* <select
                                id="validator_verifier"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="validator_verifier"
                                value={formData.validator_verifier}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 2)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select> */}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="methexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Meth Expert
                              </label>

                              <MultiSelect
                                options={methExpert}
                                value={mymethexpert}
                                onChange={setMymethExpert}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />

                              {/* <select
                                id="meth_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth_expert"
                                value={formData.meth_expert}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 5)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select> */}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Trainee Validator/verifier
                              </label>

                              <MultiSelect
                                options={traineeValidator}
                                value={mytraineevalidator}
                                onChange={setMytraineevalidator}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />

                              {/* <select
                                id="trainee_validator"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="trainee_validator"
                                value={formData.trainee_validator}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 3)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select> */}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="technical_reviewer"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="technical_reviewer"
                                required
                                value={formData.technical_reviewer}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {trlist
                                  .filter(
                                    (option) => option.designation_id == 7
                                  )
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Expert to Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>



                              <MultiSelect
                                options={trExpert}
                                value={myexperttr}
                                onChange={setMyExpertTr}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />
                             
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="gis_expert"
                                className="form-label lightgreen fs-6"
                              >
                                GIS Expert
                                
                              </label>



                              <MultiSelect
                                options={gisExpert}
                                value={mygisexpert}
                                onChange={setMygisExpert}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />


                            </div>

                            <div></div>
                          </div>
                        </fieldset>
                      </div>
                    </form>
                  </Tabs.TabPane>

                  {projectstatus > 1 && designation_id == 8 && isTL ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 text-center ${activeTab == 2 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">LOD Review</p>
                        </div>
                      }
                      key="2"
                    >

                      <LODReview />

                    </Tabs.TabPane>


                  ) : projectstatus > 1 && designation_id == 8 && (!isTL) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 text-center ${activeTab == 2 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">LOD Review</p>
                        </div>
                      }
                      key="2"
                    >

                      <ATLod />

                    </Tabs.TabPane>
                  )
                    :
                    ''
                  }



                  {formData.project_status > 2 && designation_id == 8 && isTL ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 4 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px"> Desk Review</p>
                        </div>
                      }
                      key="4"
                    >


                      <div>
                        <DraftFinding id={projectid} />
                      </div>


                    </Tabs.TabPane>

                  ) : formData.project_status > 2 && designation_id == 8 && (!isTL) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 4 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px"> Desk Review</p>
                        </div>
                      }
                      key="4"
                    >

                      <div>
                        <ATDraftFinding id={projectid} />
                      </div>


                    </Tabs.TabPane>
                  )
                    :
                    ''
                  }

                  {formData.project_status >= 3 && (draftFindingStatus >= 4 || (draftFindingStatus >= 1 && clientRemark)) && (designation_id == 8) && isTL ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 5 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Site Visit</p>
                        </div>
                      }
                      key="5"
                    >

                      <div className="col-12 border-0 bg-white">

                        {
                          siteVisitStatus >= 0 && siteVisitStatus < 7 ?
                            <SiteVisitType isTL={isTL} />
                            :
                            siteVisitStatus >= 7 && (siteVisitType == 1 || siteVisitType == 2) ?
                              <SiteVisit />

                              :
                              ''
                        }
                      </div>

                    </Tabs.TabPane>
                  )
                    :
                    formData.project_status >= 3 && (draftFindingStatus >= 4 || (draftFindingStatus >= 1 && clientRemark)) && (designation_id == 9) ? (
                      <Tabs.TabPane
                        tab={
                          <div
                            className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 5 ? "box_sh" : ""
                              }`}
                          >
                            <p className="font20px">Site Visit</p>
                          </div>
                        }
                        key="5"
                      >
                        <div className="col-12 border-0 bg-white">
                          <SiteVisitReview id={projectid} isTL={isTL} />
                        </div>
                      </Tabs.TabPane>
                    )
                      :
                      formData.project_status >= 3 && (draftFindingStatus >= 4 || (draftFindingStatus >= 1 && clientRemark)) && (designation_id == 8) && !isTL ? (
                        <Tabs.TabPane
                          tab={
                            <div
                              className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 5 ? "box_sh" : ""
                                }`}
                            >
                              <p className="font20px">Site Visit</p>
                            </div>
                          }
                          key="5"
                        >

                         

                          {
                            siteVisitStatus >= 0 && siteVisitStatus < 7 ?
                              <SiteVisitType />
                              :
                              siteVisitStatus >= 7 && (siteVisitType == 1 || siteVisitType == 2) ?
                                <ATSiteVisit />

                                :
                                ''
                          }

                        </Tabs.TabPane>
                      )
                        :
                        formData.project_status >= 3 && (draftFindingStatus >= 4 || (draftFindingStatus >= 1 && clientRemark)) && (designation_id == 4 || designation_id == 3) && siteVisitType == 2 ? (
                          <Tabs.TabPane
                            tab={
                              <div
                                className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 5 ? "box_sh" : ""
                                  }`}
                              >
                                <p className="font20px">Site Visit</p>
                              </div>
                            }
                            key="5"
                          >
                            <div className="col-12 border-0 bg-white">
                              {
                                designation_id == 4 ?
                                  <RemoteSiteVisitTMS />
                                  :
                                  designation_id == 3 ?
                                    <RemoteSiteVisitTM />
                                    :
                                    ''
                              }
                            </div>
                          </Tabs.TabPane>
                        )
                          :
                          ''
                  }

                  {formData.project_status >= 3 && siteVisitStatus == 12 && (designation_id == 8) && isTL ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 6 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Site Visit Completed</p>
                        </div>
                      }
                      key="6"
                    >

                      <div className="col-12 border-0 bg-white">
                        <SiteVisitCompleted id={projectid} isTL={isTL} />
                      </div>

                    </Tabs.TabPane>
                  ) :
                    formData.project_status >= 4 && (designation_id == 8) && !isTL && siteVisitStatus == 12 ? (
                      <Tabs.TabPane
                        tab={
                          <div
                            className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 6 ? "box_sh" : ""
                              }`}
                          >
                            <p className="font20px">Site Visit Completed</p>
                          </div>
                        }
                        key="6"
                      >

                        <div className="col-12 border-0 bg-white">
                          <ATSiteVisitCompleted id={projectid} isTL={isTL} />
                        </div>


                      </Tabs.TabPane>) : ''}

                  {(siteVisitStatus === 12 && formData.project_status >= 4 && designation_id != 9 && designation_id == 8) && (isTL) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 7 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px"> Site Visit Findings</p>
                        </div>
                      }
                      key="7"
                    >

                      <div className="col-12 border-0 bg-white">
                        <SiteFinding id={projectid} isTL={isTL} />
                      </div>


                    </Tabs.TabPane>
                  ) : (siteVisitStatus === 12 && formData.project_status >= 4 && designation_id != 9 && designation_id == 8) && (!isTL) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 7 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px"> Site Visit Findings</p>
                        </div>
                      }
                      key="7"
                    >


                      <div className="col-12 border-0 bg-white">
                        <ATSiteFinding id={projectid} isTL={isTL} />
                      </div>

                    </Tabs.TabPane>
                  )
                    :
                    ''
                  }


                  {siteFindingStatus === 6 && formData.project_status > 5 && (designation_id == 8) && draftFindingStatus == 6 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 8 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">DVR Round</p>
                        </div>
                      }
                      key="8"
                    >
                      {isTL ? (
                        <div className="col-12 border-0 bg-white ">
                          <TLDvr id={projectid} isTL={isTL} />
                        </div>
                      ) : (
                        <div className="col-12 border-0 bg-white ">
                          <ATDvr id={projectid} isTL={isTL} />
                        </div>
                      )

                      }

                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}


                  {formData.project_status > 6 && (designation_id == 7) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 9 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Tech. Review</p>
                        </div>
                      }
                      key="9"
                    >

                      <div className="col-12 border-0 bg-white ">
                        <TrRoundReview id={projectid} isTL={isTL} />
                      </div>

                    </Tabs.TabPane>

                  ) : formData.project_status > 6 && designation_id == 8 && (TrStatus > 1 && isTL) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 9 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Tech. Review</p>
                        </div>
                      }
                      key="9"
                    >

                      <div className="col-12 border-0 bg-white ">
                        <TrRound id={projectid} isTL={isTL} />
                      </div>

                    </Tabs.TabPane>
                  )
                    :
                    formData.project_status > 6 && designation_id == 8 && (TrStatus > 1 && !isTL) ?
                      (
                        <Tabs.TabPane
                          tab={
                            <div
                              className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 9 ? "box_sh" : ""
                                }`}
                            >
                              <p className="font20px">Tech. Review</p>
                            </div>
                          }
                          key="9"
                        >

                          <div className="col-12 border-0 bg-white ">
                            <ATTrRound id={projectid} isTL={isTL} />
                          </div>

                        </Tabs.TabPane>
                      )
                      :
                      ''
                  }

                  {formData.project_status > 7 && designation_id != 9 && (designation_id == 8) && isTL ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 10 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Consistency Check</p>
                        </div>
                      }
                      key="10"
                    >
                      <div className="col-12 border-0 bg-white ">
                        <CCReview id={projectid} isTL={isTL} />
                      </div>
                    </Tabs.TabPane>


                  ) : formData.project_status > 7 && designation_id != 9 && (designation_id == 7 && ccStatus >= 3) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 10 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Consistency Check</p>
                        </div>
                      }
                      key="10"
                    >
                      <div className="col-12 border-0 bg-white ">
                        <TrCCReview id={projectid} isTL={isTL} />
                      </div>
                    </Tabs.TabPane>



                  )
                    :
                    formData.project_status > 7 && designation_id != 9 && (designation_id == 8) && !isTL && ccStatus >= 1 ? (
                      <Tabs.TabPane
                        tab={
                          <div
                            className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 10 ? "box_sh" : ""
                              }`}
                          >
                            <p className="font20px">Consistency Check</p>
                          </div>
                        }
                        key="10"
                      >
                        <div className="col-12 border-0 bg-white ">
                          <ATCCReview id={projectid} isTL={isTL} />
                        </div>
                      </Tabs.TabPane>) : ''}

                  {
                    (formData.project_status > 7 && designation_id != 9 && designation_id == 8 && isCC) ? (

                      <Tabs.TabPane
                        tab={
                          <div
                            className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 11 ? "box_sh" : ""
                              }`}
                          >
                            <p className="font20px">Consistency Checker</p>
                          </div>
                        }
                        key="11"
                      >
                        <div className="col-12 border-0 bg-white ">
                          <CCReviewAction id={projectid} isTL={isTL} />
                        </div>
                      </Tabs.TabPane>
                    )
                      :
                      ''
                  }


                  {designation_id == 9 && ccStatus >= 1 && formData.project_status > 7 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 11 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Consistency Check Review</p>
                        </div>
                      }
                      key="11"
                    >
                      <div className="col-12 border-0 bg-white ">
                        <SBUCCReview id={projectid} isTL={isTL} />
                      </div>
                    </Tabs.TabPane>


                  ) : (
                    ""
                  )}

                  {projectstatus > 8 &&
                    ((designation_id == 8 && isTL) ||
                      designation_id == 3 ||
                      designation_id == 2 || designation_id == 11) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 12 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">RFI / RFR</p>
                        </div>
                      }
                      key="12"
                    >
                      {designation_id == 8 && isTL ? (
                        <div className="col-12 border-0 bg-white">
                          <TLRfi id={projectid} isTL={isTL} />
                        </div>
                      ) : designation_id == 3 ? (
                        <div className="col-12 border-0 bg-white">
                          <TMRfiReview id={projectid} isTL={isTL} />
                        </div>
                      ) : designation_id == 2 ? (
                        <div className="col-12 border-0 bg-white">
                          <MdRFIRound id={projectid} isTL={isTL} />
                        </div>
                      ) : designation_id == 11 ? (
                        <div className="col-12 border-0 bg-white">
                          <RegistrarRFI id={projectid} isTL={isTL} />
                        </div>
                      )
                        :
                       
                        ''
                      }
                    </Tabs.TabPane>
                  )

                    :

                 

                    projectstatus > 8 &&
                      ((designation_id == 8 && !isTL) ||
                        designation_id == 3 ||
                        designation_id == 2 || designation_id == 11) ? (
                      <Tabs.TabPane
                        tab={
                          <div
                            className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 12 ? "box_sh" : ""
                              }`}
                          >
                            <p className="font20px">RFI / RFR</p>
                          </div>
                        }
                        key="12"
                      >
                        

                        <div className="col-12 border-0 bg-white">
                          <ATRFI id={projectid} isTL={isTL} />
                        </div>
                      </Tabs.TabPane>
                    )

                      :
                      ''
                  }

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center ${activeTab == 3 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Project Summary</p>
                      </div>
                    }
                    key="3"
                  >
                    <div className="col-12 border-0 bg-white ">
                      <ActivityChain id={projectid} />
                    </div>
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center ${activeTab == 13 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Team Change Summary</p>
                      </div>
                    }
                    key="13"
                  >
                    <div className="col-12 border-0 bg-white ">
                      <ProjectTeamChangeSummary id={projectid} />
                    </div>
                  </Tabs.TabPane>

                  {projectstatus >= 11 ?(
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center ${activeTab == 15 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Kickout Summary</p>
                      </div>
                    }
                    key="15"
                  >
                    <div className="col-12 border-0 bg-white ">
                      <KickoutSummary id={projectid} />
                    </div>
                  </Tabs.TabPane>

                  ):""
                }
                  

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ATDeskReview;
