// import React, { useState } from "react";
// import { FileUploader } from "react-drag-drop-files";
// import PropTypes from 'prop-types';

// const fileTypes = [
//     "JPG",
//     "JPEG",
//     "PDF",
//     "RAR",
//     "XLS",
//     "XLSX",
//     "DOC",
//     "DOCX",
//     "ZIP",
//     "XLSM"
// ];

// const MultipleFileUploader = ({ dynamicMaxSize, onFilesChange }) => {
//     const [selectedFiles, setSelectedFiles] = useState([]);

//     const handleFileChange = (files) => {
//         const newFiles = [...selectedFiles, ...files];
//         setSelectedFiles(newFiles);
//         onFilesChange(newFiles);
//     };

//     const handleSizeError = () => {
//         // Handle the error case where a file exceeds the size limit
//     };

//     return (
//         <div>
//             <FileUploader
//                 handleChange={handleFileChange}
//                 name="multiple_files"
//                 types={fileTypes}
//                 multiple={true}
//                 maxSize={dynamicMaxSize}
//                 onSizeError={handleSizeError}
//             />
//             <div>
//                 {selectedFiles.length > 0 && selectedFiles.map((file, index) => (
//                     <div key={index}>{file.name}</div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// MultipleFileUploader.propTypes = {
//     dynamicMaxSize: PropTypes.number.isRequired,
//     onFilesChange: PropTypes.func.isRequired
// };

// export default MultipleFileUploader;




import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PropTypes from 'prop-types';
import { DeleteTwoTone } from '@ant-design/icons';

const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];

const MultipleFileUploader = ({onFilesChange }) => {

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [key, setKey] = useState(0);

    const handleFileChange = (files) => {

        const validFiles = [...files].filter(file => file.size <= dynamicMaxSize * 1024 * 1024);
        const invalidFiles = [...files].filter(file => file.size > dynamicMaxSize * 1024 * 1024);
        
        if (invalidFiles.length > 0) {
            setFileSizeError(true);
        } else {
            setFileSizeError(false);
        }

        const newFiles = [...selectedFiles, ...validFiles];

        setSelectedFiles(newFiles);
        onFilesChange(newFiles);
    };

    const handleFileDelete = (index) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
        onFilesChange(newFiles);
        setKey(prevKey => prevKey + 1); // Reset uploader by changing key
    };

    const handleSizeError = () => {
        setFileSizeError(true);
    };

    return (
        <div className="">
            <FileUploader
                key={key} // Add key to force re-render
                handleChange={handleFileChange}
                name="multiple_files"
                types={fileTypes}
                multiple={true}
                maxSize={dynamicMaxSize}
                onSizeError={handleSizeError}
            />
            {fileSizeError && (
                <span className="text-danger">
                    Some files are larger than the allowed size of {dynamicMaxSize} MB.
                </span>
            )}
            <div>
                {selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <DeleteTwoTone twoToneColor="#eb2f96" onClick={() => handleFileDelete(index)} style={{ marginRight: '10px' }} />
                        <span>{file.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

MultipleFileUploader.propTypes = {
    dynamicMaxSize: PropTypes.number.isRequired,
    onFilesChange: PropTypes.func.isRequired
};

export default MultipleFileUploader;
