import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER, dasboard_counter_url } from "../../config";
import axios from "axios";
import { toast } from 'react-toastify';

function ContractStatus({search,startDate,endDate})
{
    const [signedContractCount, setSignedContractCount] = useState(0);
    const [pendingContractCount, setPendingContractCount] = useState(0);
    const [dealLostCount, setDealLostCount] = useState(0);


   useEffect(() => {
    const fetchData = async () => {
      try {

        const payload = { 
          "fy": "",
          'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
          'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
        };

        const response = await axios.post(`${dasboard_counter_url}`,payload, API_HEADER);

        setSignedContractCount(response.data.data.project_signed_count);
        setPendingContractCount(response.data.data.project_pending_count);
        setDealLostCount(response.data.data.project_lost_count)
        
      } catch (error) {
        toast.error(error)
      }
    }
    fetchData()
},[search]);

const customColors = [
  "#c7522a", // Red open
  "#d68a58", // Blue won
  "#a4d658", // Green lost
 
];

    return(
        <React.Fragment>
            <div className="container-fluid">
               
                <Chart 
                type="pie"
                width={"100%"}
                height={300}

                series={ [pendingContractCount, signedContractCount, dealLostCount] }                

                options={{
                       
                       noData:{text:"Empty Data"},                        
                      
                      labels: ["Open", 'Won', 'Lost']  ,
                      colors: customColors,
                      legend:{
                        position: 'bottom'
                    },

                 }}
                >
                </Chart>
            </div>
        </React.Fragment>
    );
}
export default ContractStatus;