import React, { useState, useEffect } from "react";
import "../../src/App.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { dasboard_counter_url, API_HEADER } from "../config";
import DealLostchart from "../Components/Graphs/DealLostChart";
import ProposalMonthWise from "../Components/Graphs/ProposalMonthWise";
import ProposalMonthWiseValue from "../Components/Graphs/ProposalMonthWiseValue";
import ScopeWiseDealCount from "../Components/Graphs/ScopeWiseDealCount";
import ScopeWiseDealValue from "../Components/Graphs/ScopeWiseDealValue";
import Linechart from "../Components/Graphs/Linechart";
import ContractStatus from "../Components/Graphs/ContractStatus";
import TargetAnalysis from "../Components/Graphs/TargetAnalysis";
import YearlyTargetAnalysis from "../Components/Graphs/YearlyTargetAnalysis";
import { Button } from "bootstrap";
import { DatePicker, Spin } from "antd";
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'


const MainDashboard = () => {
  const navigate = useNavigate();
  const [signedContractValue, setSignedContractValue] = useState(0);
  const [pendingContractValue, setPendingContractValue] = useState(0);
  const [totalProposalValue, setTotalProposalValue] = useState(0);
  const [dealLostValue, setDealLostValue] = useState(0);

  const [signedContractCount, setSignedContractCount] = useState(0);
  const [pendingContractCount, setPendingContractCount] = useState(0);
  const [totalProposalCount, setTotalProposalCount] = useState(0);
  const [dealLostCount, setDealLostCount] = useState(0);
  const [createdYear, setCreatedYear] = useState([]);
  const [search,setSearch]=useState(false);

  const dateFormat = "DD/MM/YYYY";
  const [startDate,setstartDate]=useState(null);
  const [endDate,setendDate]=useState(null);
  const [loader,setLoader]=useState(false);

  const handleDownload = async () => {
    setLoader(true)

    const pdf = new jsPDF();
    const sections = document.querySelectorAll('.page-break'); // Use class to target sections

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const canvas = await html2canvas(section);
      const imgData = canvas.toDataURL('image/png');
      
      if (i > 0) {
        pdf.addPage(); // Add a new page for subsequent sections
      }
      
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed
    }
    
    pdf.save('document.pdf');
    setLoader(false)

  };


  const handleSearchByColumn = (value) => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        alert("Start Date cannot be after End Date.");
        setendDate(null);
        setstartDate(null);
        return;
      }
    }
    setSearch(!search);
  };  

  const resetFilters = () => {
    setendDate(null);
    setstartDate(null);
    setSearch(!search);
  }; 

  useEffect(() => {
    const fetchData = async () => {
      try {

        const payload = { 
          "fy": "",
          'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
          'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
        };

        const response = await axios.post(`${dasboard_counter_url}`, payload, API_HEADER);

        setCreatedYear(parseInt(response.data.fy_start.slice(0, 4)));
        setSignedContractValue(response.data.data.project_signed_value);
        setPendingContractValue(response.data.data.project_pending_value);
        setTotalProposalValue(response.data.data.project_value_fy);
        setDealLostValue(response.data.data.project_lost_value);

        setSignedContractCount(response.data.data.project_signed_count);
        setPendingContractCount(response.data.data.project_pending_count);
        setTotalProposalCount(response.data.data.project_total_count);
        setDealLostCount(response.data.data.project_lost_count);

      } catch (error) {
        toast.error(error);
      }
    };

    fetchData();
  }, [search]);


  const navigateToProposal = async (counter_status,title) => {
    navigate("/proposaltable", { state: { counter_status,title } });
  }

  const ActualSignedContractValue = signedContractValue;
  const ActualPendingContractValue =pendingContractValue;
  const ActualTotalProposalValue = totalProposalValue;
  const ActualDealLostValue = dealLostValue;

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <>   
      <Spin spinning={loader}>
 
      <div className="content dashboardcolor">  
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="mx-4 my-4 d-flex">
                <h3 className="font-weight-bold ml-3">Sales Dashboard </h3>
                <button
                  type="button"
                  class="btn btn-outline-success ml-auto"
                  onClick={handleClick}
                >
                  Home
                </button>

                <button className="mx-4 btn btn-primary" onClick={handleDownload}>Download</button>

              </div>
            </div>
          </div>


          <div className="row mx-4">

<div className="col-sm-3 col-md-2">
<div className="mb-3">
   <label className="text-capitalize textcolumntitle  fw-bold font12px">
       From Date
   </label>
     <DatePicker
          onChange={(date, dateString) => setstartDate(date)}
          placeholder="From Date"
          style={{ width: "100%" }}
          className="rounded-2"
          format={dateFormat}
          showTime={false}
          value={startDate}
          disabledDate={(current) => current && current > moment().endOf('day')}
     />
</div>

</div>

  <div className="col-sm-3 col-md-2">
  <div className="mb-3">
    <label className="text-capitalize textcolumntitle  fw-bold font12px">
      To Date
    </label>
  <DatePicker
       onChange={(date, dateString) => setendDate(date)}
       placeholder="To Date"
       style={{ width: "100%" }}
       className="rounded-2"
       format={dateFormat}
       showTime={false}
       value={endDate}
       disabledDate={(current) => current && current > moment().endOf('day')}
  />
  </div>
  
  </div>

  <div className="col-sm-3 col-md-2 mt-4">

            <button
              className="btn btn-success btn-md rounded-2 me-2"
              onClick={handleSearchByColumn}
            >
              Search
            </button>

            <button
              className="btn btn-danger btn-md rounded-2"
              onClick={resetFilters}
            >
              Reset
            </button>

  </div>

  <div className="col-sm-3 col-md-6 mt-4 justify-content-end">

<h4 style={{float:"right"}}><b>FY {createdYear}-{createdYear + 1}</b></h4>

</div>


</div>


        <div className="page-break">

          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-2 ">
              <div class="mycard_dash ">
                <div class="mycard-content text-center">
                  <h4>Sales Target Analysis</h4>
                  
                  <TargetAnalysis />
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-4 my-2">
            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(1,'Total Proposal'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size">Total Proposal </h6>
                  <hr />
                  <h2 >{totalProposalCount}</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-sm-12  my-4 ">
              <div class="mycard card-size ">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(2,'Signed Contract'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size"> Signed Contract</h6>
                  <hr />
                  <h2> {signedContractCount}</h2>
                  {/* <h5 className="sub-head">Number of Deals</h5> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(3,'Pending Proposal'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size"> Pending Proposal</h6>
                  <hr />
                  <h2>{pendingContractCount}</h2>
                  {/* <h5 className="sub-head">Number of Deals</h5> */}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(4,'Lost Deal'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size">Lost Deal </h6>
                  <hr />
                  <h2>{dealLostCount}</h2>
                  {/* <h5 className="sub-head">Number of Deals</h5> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-4 my-2">
            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(1,'Total Proposal Value'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size">Total Proposal Value </h6>
                  <hr />
                  <h2>&#8377; {ActualTotalProposalValue}</h2>
                  {/* <h5 className="sub-head">Proposal Value</h5> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(2,'Signed Contract Value'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size"> Signed Contract Value</h6>
                  <hr />
                  <h2>&#8377; {ActualSignedContractValue}</h2>
                  {/* <h5 className="sub-head">Deal Value</h5> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(3,' Pending Prop. Value'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size"> Pending Prop. Value</h6>
                  <hr />
                  <h2>&#8377; {ActualPendingContractValue}</h2>
                  {/* <h5 className="sub-head">Proposal Value</h5> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-sm-12 my-4 ">
              <div class="mycard card-size">
                <div class="mycard-content text-center" onClick={() => { navigateToProposal(4,'Lost Deal Value'); }} style={{cursor:"pointer"}}>
                  <h6 className="heading-size">Lost Deal Value </h6>
                  <hr />
                  <h2>&#8377; {ActualDealLostValue}</h2>
                  {/* <h5 className="sub-head">Deal Value</h5> */}
                </div>
              </div>
            </div>
          </div>


          <div className="row mx-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Program Wise Proposal Deal Count</h4>
                  <ScopeWiseDealCount search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
            </div>
            <div className="row mx-4">
            <div className="col-lg-12 col-md-12 col-sm-12 my-4 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Program Wise Proposal Deal Value</h4>
                  <ScopeWiseDealValue search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>

          </div>


          <div className="page-break">

          <div className="row mx-4">
            <div className=" col-lg-6 col-md-12 col-sm-12 my-4">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Monthly Proposals Count</h4>
                  <ProposalMonthWise />
                </div>
              </div>
            </div>
            <div className=" col-lg-6 col-md-12 col-sm-12 my-4 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Monthly Proposals Value</h4>
                  <ProposalMonthWiseValue />
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-4">
            <div className=" col-lg-6 col-md-12 col-sm-12 my-2">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Contract Status</h4>

                  <ContractStatus search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 my-2">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4> Deal Lost Reason </h4>

                  <DealLostchart search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row mx-4">
            <div className=" col-lg-8 col-md-12 col-sm-12 my-4">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Contracts Signed Monthly</h4>

                  <Linechart />
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-12 col-sm-12 my-4">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Yearly Target Analysis</h4>

                  <YearlyTargetAnalysis />
                </div>
              </div>
            </div>

            </div>

          </div>
        </div>
      </div>

      </Spin>
   
    </>
  );
};

export default MainDashboard;
