import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_HEADER, } from "../../config";
import { get_assesment_url, team_change_url, get_ta_tr_experts, get_sectoralscope_url, get_project_team_info, BASE_DOCUMENT, team_change_doc_url } from "../../config";
import {
  get_project_details_url,
} from "../../config";
import { get_trsbu_url } from "../../config";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { Alert } from "antd";
import LoaderComponent from "../../utlis/LoderComponent";
import { DeleteTwoTone } from "@ant-design/icons";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const TLSBUTeamChange = ({ teamId,isTL }) => {
  const { id } = useParams();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const navigate = useNavigate();

  const userdata = JSON.parse(sessionStorage.getItem("user"));

  const [projectid, setProjectId] = useState(null);


  const [atlist, setAtList] = useState([]);

  const [trlist, setTRList] = useState([]);


  const [isAT, setIsAT] = useState(false);
  const [member, setMember] = useState();
  const [projectstatus, setProjectStatus] = useState(null);
  const [status, SetStatus] = useState(null);



  const [teamLeader, setTeamLeader] = useState("");
  const [taExpert, setTAExpert] = useState([]);
  const [validator, setValidator] = useState("");
  const [financeExpert, setFinanceExpert] = useState("");
  const [localExpert, setLocalExpert] = useState("");
  const [methExpert, setMethExpert] = useState("");
  const [traineeExpert, setTraineeExpert] = useState("");
  const [technicalReviewer, setTechnicalReviewer] = useState("");
  const [expertTechnicalReviewer, setExpertTechnicalReviewer] = useState([]);
  const [teamChangeDoc, setTeamChangeDoc] = useState([]);
  const [tlRemarks, setTlRemarks] = useState('')
  const [sbuRemarks, setSbuRemarks] = useState('')



  const [fileteamChangeDocSizeError, SetFileteamChangeDocSizeError] = useState(false)
  const [teamDocName, setTeamDocName] = useState(null);
  const [teamDoc, setTeamDoc] = useState("");

  const [taTrExpert, setTATRExpert] = useState([]);
  const [mytaexpert, setMyTaexpert] = useState([]);
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);
  const [apiData, setAPIData] = useState('')
  const [selectedSector, setSelectedSector] = useState('')
  const [mysectorscope, setMySectorScope] = useState([]);
  const [record, setRecord] = useState("");

  const [teamChangeStatus, setTeamChangeStatus] = useState('')
  const [teamChangeId, setteamChangeId] = useState('')
  const [tmsRemarks, setTmsRemarks] = useState('')
  const [tmRemarks, setTmRemarks] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const [newCoi, setNewCoi] = useState([]);
  const [fileCOISizeError, setFileCOISizeError] = useState(false);
  const [coi, setCoi] = useState([]);
  const [coiname, setCoiName] = useState([]);

  const [f24File, setF24File] = useState("");
  const [f21File, setF21File] = useState("");
  const [otherDocFile, setOtherDocFile] = useState([]);

  const [f24Name, setF24Name] = useState(null);
  const [f24, setF24] = useState("");
  const [f21Name, setF21Name] = useState(null);
  const [f21, setF21] = useState("");

  const [formData, setFormData] = useState({
    team_leader: "",
    program: '',
    ta_expert: "",
    validator_verifier: "",
    finance_expert: "",
    local_expert: "",
    meth_expert: "",
    trainee_validator: "",
    technical_reviewer: "",
    expert_tr: "",
    sbu_head: "",
    pdd_documents: "",
    er_documents: "",
    mr_documents: "",
    evidence_name: [],
  });

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_team_info}/${ids}?team_id=${teamId}`,
        API_HEADER
      );

      const record1 = response.data.record.program
      setTeamChangeStatus(response.data.record.team_change_status)

      console.log(teamChangeStatus);
      if (response && response.data && response.data.teamsummary) {
        const record = response.data.teamsummary

        setProjectId(record.project_id);
        setAPIData(record)
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record1}`
        );
        setRecord(record)


        setAtList(responseteamleader.data.data);

        setTeamLeader(record.team_leader);
        setTAExpert(record.ta_expert);
        setValidator(record.validator_verifier);
        setFinanceExpert(record.finance_expert);
        setLocalExpert(record.local_expert);
        setMethExpert(record.meth_expert);
        setTraineeExpert(record.trainee_validator);
        setTechnicalReviewer(record.technical_reviewer)
        // setTeamDoc(record.team_change_document)
        // setTlRemarks(record.tl_team_remarks)

        setTlRemarks((response.data.record.team_change_status == 1 || response.data.record.team_change_status == 2 || response.data.record.team_change_status == 3 || response.data.record.team_change_status == 5) ? record.tl_team_remarks == '' : record.tl_team_remarks)
        setTmRemarks(record.tm_team_remarks)
        setTmsRemarks(record.team_tms_remarks)
        setSbuRemarks(record.team_sbu_remarks)

        setteamChangeId(record.id);
        setMember(record.team_change_status)

        let url8 = `${BASE_DOCUMENT}/projects/${id}/${record.team_change_document}`;
        setTeamDoc(url8);
        setTeamDocName(record.team_change_document);

        setFormData({
          expert_tr: record.expert_tr,
          status: record.status,
          program: record.program
        });

        setProjectStatus(record.project_status);
        SetStatus(record.status);

        let url2 = `${BASE_DOCUMENT}/projects/${record.project_id}/${record.f24_doc}`;
        setF24Name(url2);
        console.log("url2", url2)
        setF24(record.f24_doc);
  
        let url3 = `${BASE_DOCUMENT}/projects/${record.project_id}/${record.f21_doc}`;
        setF21Name(url3);
        setF21(record.f21_doc);
  
        const accumulatedFiles = record.coi_doc.split(",").reduce((acc, item) => {
          const filename = `${BASE_DOCUMENT}/documents/${record.project_id}/${item}`;
          return {
            ...acc,
            [item]: { url: filename }
          };
        }, {});
  
        setCoiName(accumulatedFiles);
        setCoi(Object.keys(accumulatedFiles));

      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    if (apiData) {

      const sectorscopes = apiData.sectoral_scope?.split(",").map(Number);
      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);

    }
  }, [apiData, mysectorscope])




  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };


  const fetchDataTeamChange = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_team_info}/${ids}?team_id=${teamId}`,
        API_HEADER
      );

      setTeamChangeStatus(response.data.record.team_change_status)
      setMember(response.data.record.team_change_status)

    } catch (error) { }
  };



  useEffect(() => {
    fetchDataTeamChange(id);
  }, [id]);



  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) { }
    };

    fetchDataTrData();
  }, []);
  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) { }
    };

    fetchSectoralScope();
  }, []);


  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=7`
        );
        setTATRExpert(responsetatrexpert.data.data.map((taexpert) => ({
          value: taexpert.id,
          label: taexpert.name,
        }))
        )

      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert.includes(taexpert.value)
      );
      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);


  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=8`
        );

        setTRExpert(responsetatrexperts.data.data.map((trexpert) => ({
          value: trexpert.id,
          label: trexpert.name,
        }))
        )
      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {


      const trExperts = apiData.expert_tr?.split(",").map(Number);

      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts.includes(taexpert.value)
      );

      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);

  const handleEditAssesmentTeamSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);

    const TAExpertData = mytaexpert.map((value) => value.value);
    const TRExpertData = myexperttr.map((value) => value.value);

    if (tlRemarks == null || tlRemarks == "<p><br></p>" || tlRemarks == "") {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", projectid);
        formDataToSend.append("team_leader", teamLeader);
        formDataToSend.append("validator_verifier", validator);
        formDataToSend.append("ta_expert", TAExpertData);
        formDataToSend.append("meth_expert", methExpert);
        formDataToSend.append("local_expert", localExpert);
        formDataToSend.append("finance_expert", financeExpert);
        formDataToSend.append("trainee_validator", traineeExpert);
        formDataToSend.append("technical_reviewer", technicalReviewer);
        formDataToSend.append("expert_tr", TRExpertData);
        formDataToSend.append("team_change_status", member);
        formDataToSend.append("tl_team_remarks", tlRemarks);
        formDataToSend.append("team_change_id", teamChangeId);
        formDataToSend.append("is_tl", 1);

        formDataToSend.append("f24_doc", f24File);
        formDataToSend.append("f21_doc", f21File);
        newCoi.map(fileList =>
          formDataToSend.append("coi_doc[]", fileList)
        );

        const response = await axios.post(
          `${team_change_doc_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (response.status === 200 && response.data.status == true) {
          toast.success("Team Change Request Sent Successfully");
          navigate(`/dashboard`);
        } else {
          fetchData();
        }
      }
      catch (error) {
        toast.error(error)
      }
      finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);

      }
    }
  };



  const handleTlRemarksChange = (content) => {
    setTlRemarks(content)
  }

  const handleFileCOIChange = (file) => {

    setNewCoi(prev => [...prev, ...Array.from(file)]);
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileCOISizeError(false)
    }

    setFileCOISizeError(false)

    setCoi(prevFiles => [...prevFiles, ...Array.from(file)]);
  };

  const handleCOISizeError = (file) => {
    setFileCOISizeError(true)
  }

  const handleCOIDelete = (newCoi) => {
    setNewCoi(prevFiles => prevFiles.filter(file => file !== newCoi));
  }


  return (
    <div>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />


      <form
        method="post"
        onSubmit={handleEditAssesmentTeamSubmit}
      >
        <fieldset disabled={!isTL}>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="teamleader"
                className="form-label lightgreen fs-6"
              >
                Team Leader
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="team_leader"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="team_leader"
                disabled
                value={teamLeader}

                onChange={(e) => setTeamLeader(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id === 1)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>


            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="taexpert"
                className="form-label lightgreen fs-6"
              >
                TA Expert
                <span style={{ color: "red" }}>*</span>
              </label>



              <MultiSelect
                options={taTrExpert}
                value={mytaexpert}
                onChange={setMyTaexpert}
                labelledBy="Select"
                // className="multi"
              />


            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="teamleader"
                className="form-label lightgreen fs-6"
              >
                Local Expert
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="local_expert"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="local_expert"
                value={localExpert}
                onChange={(e) => setLocalExpert(e.target.value)}
              >
                <option value={""}>To be added later</option>

                {atlist
                  .filter((option) => option.skill_id === 6)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="financeexpert"
                className="form-label lightgreen fs-6"
              >
                Finance Expert
              </label>

              <select
                id="finance_expert"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="finance_expert"
                value={financeExpert}
                // readOnly={true}
                onChange={(e) =>
                  setFinanceExpert(e.target.value)
                }
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id === 4)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>


            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="validator"
                className="form-label lightgreen fs-6"
              >
                Validator/verifier
              </label>

              <select
                id="validator_verifier"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="validator_verifier"
                value={validator}
                onChange={(e) => setValidator(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id === 2)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="methexpert"
                className="form-label lightgreen fs-6"
              >
                Meth Expert
              </label>

              <select
                id="meth_expert"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="meth_expert"
                value={methExpert}
                onChange={(e) => setMethExpert(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id === 5)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>


          </div>

          <div className="row">


            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="taexpert"
                className="form-label lightgreen fs-6"
              >
                Trainee Validator/verifier
              </label>

              <select
                id="trainee_validator"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="trainee_validator"
                value={traineeExpert}
                onChange={(e) =>
                  setTraineeExpert(e.target.value)
                }
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id == 3)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="technicalreviewer"
                className="form-label lightgreen fs-6"
              >
                Technical Reviewer
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="technical_reviewer"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="technical_reviewer"
                value={technicalReviewer}
                onChange={(e) => setTechnicalReviewer(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {trlist
                  .filter(
                    (option) => option.designation_id == 7
                  )
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>



            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="technicalreviewer"
                className="form-label lightgreen fs-6"
              >
                Expert to Technical Reviewer
                <span style={{ color: "red" }}>*</span>
              </label>



              <MultiSelect
                options={trExpert}
                value={myexperttr}
                onChange={setMyExpertTr}
                labelledBy="Select"
                // className="multi"

              />

            </div>

          </div>

        </fieldset>

        <div>
        <p className="textlightgreen fw-bold mb-3 fs-5">
                  Documents
                </p>
            <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Upload Document
                    </th>
                    {teamChangeStatus >= 2 ? (
                      <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                        Uploaded Document
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      COI Documents
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>

                    <td >

                      <FileUploader
                        handleChange={handleFileCOIChange}
                        name="coi_doc"
                        types={fileTypes}
                        multiple="true"
                        maxSize={dynamicMaxSize}
                        required={false}
                        onSizeError={handleCOISizeError}
                      />

                      {fileCOISizeError ? (
                        <span className="text-danger">
                          File size greater than {dynamicMaxSize} mb is not
                          allowed
                        </span>
                      ) : (
                        <span >
                          {newCoi.map((newCoi) => <div><span style={{ marginRight: '20px' }}>&nbsp;{<DeleteTwoTone twoToneColor="#eb2f96" onClick={() => handleCOIDelete(newCoi)} />}</span><span>{`File name: ${newCoi?.name}`}</span></div>)}
                        </span>
                      )}

                    </td>

                    <td>

                      {Object.keys(coiname).map((fileName) => (
                        <div key={fileName}>
                          <a target="_blank" href={coiname[fileName].url}>
                            {fileName}
                          </a>
                        </div>
                      ))}

                    </td>

                  </tr>
                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      F24 Document
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>


                    {/* <td className="p-3">
                  
                    <FileUploader
                      handleChange={handleFileF24Change}
                      name="f24_doc"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleF24SizeError}
                    />
                

                  {fileF24SizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} is not allowed
                    </span>
                  ) : (
                    <span>{file2 ? file2.name : ""}</span>
                  )}
                </td> */}

                    <FileUploaderComponent file={f24File} setFile={setF24File} />

                    <td>

                      <a href={f24Name} target="_blank">{f24}</a>

                    </td>
                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      F21 Document
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>


                    {/* <td className="p-3">
                 
                    <FileUploader
                      handleChange={handleFileF21Change}
                      name="f21_doc"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleF21SizeError}
                    />
                  

                  {fileF21SizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} is not allowed
                    </span>
                  ) : (
                    <span>{file3 ? file3.name : ""}</span>
                  )}
                </td> */}

                    <FileUploaderComponent file={f21File} setFile={setF21File} />

                    <td>
                      <a href={f21Name} target="_blank">{f21}</a>
                    </td>
                  </tr>


                </tbody>
              </table>

              </div>

        {(teamChangeStatus == 1 || teamChangeStatus == 2 || teamChangeStatus == 3 || teamChangeStatus == 5) ?
          <>
            <div className="row">
              <div className="col-6 my-4 ">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value=''>Select</option>

                  <option value={2}>Send to Team Members</option>
                  <option value={4}>Send to TM Support</option>

                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div>
                <label className="form-label lightgreen fs-6 mb-3">
                  Team Leader Remarks<span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={tlRemarks}
                  name="remarks"
                  onChange={handleTlRemarksChange}
                  style={{
                    // width: "68vw",
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {
              // teamChangeStatus >= 1 && sbuRemarks != null ?
              sbuRemarks &&
              <div className="row mt-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    SBU Remarks
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={sbuRemarks}
                    name="remarks"
                    readOnly={true}
                    // onChange={handleTlRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            }

            {
              // teamChangeStatus == 5 || teamChangeStatus == 6 ?
              tmsRemarks &&

              <div className="row my-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    TM Support Remark
                  </label>
                  <hr className="lightgreen" />
                  <div className="row  my-4">
                    <span >
                      {teamChangeStatus == 6 ? (
                        <Alert className="col-12" message="Approved" type="success" showIcon />
                      ) : teamChangeStatus == 5 ? (
                        <Alert className="col-12" message="Clarification Required" banner />
                      ) : (
                        ''
                      )}
                    </span>

                  </div>
                  <ReactQuill
                    theme="snow"
                    value={tmsRemarks}
                    name="remarks"
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

            }

            {tmRemarks &&
              <div className="row my-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    TM Remark
                  </label>
                  <hr className="lightgreen" />
                  <div className="row  my-4">
                    <span >
                      {teamChangeStatus == 8 ? (
                        <Alert className="col-12" message="Approved" type="success" showIcon />
                      ) : teamChangeStatus == 7 ? (
                        <Alert className="col-12" message="Clarification Required" banner />
                      ) : (
                        ''
                      )}
                    </span>

                  </div>

                  <ReactQuill
                    theme="snow"
                    value={tmRemarks}
                    name="remarks"
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>}


            <div className="col-12 d-flex justify-content-end gap-3 pr-0">

              <button className="btn  my-4 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit">
                Submit
              </button>
            </div>
          </>
          :
          <>
            <div className="row">
              <div className="col-6 my-4 ">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  disabled
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value=''>Select</option>

                  <option value={2}>Send to Team Members</option>
                  <option value={4}>Send to TM Support</option>

                </select>
              </div>
            </div>


            <div className="row mt-4">
              <div>
                <label className="form-label lightgreen fs-6 mb-3">
                  Team Leader Remarks<span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={tlRemarks}
                  name="remarks"
                  readOnly={true}
                  // onChange={handleTlRemarksChange}
                  style={{
                    // width: "68vw",
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {
              // teamChangeStatus >= 1 && sbuRemarks != null ?
              sbuRemarks &&
              <div className="row mt-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    SBU Remarks
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={sbuRemarks}
                    name="remarks"
                    readOnly={true}
                    // onChange={handleTlRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            }

            {
              // teamChangeStatus == 5 || teamChangeStatus == 6 ?
              tmsRemarks &&

              <div className="row my-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    TM Support Remark
                  </label>
                  <hr className="lightgreen" />
                  <div className="row  my-4">
                    <span >
                      {teamChangeStatus == 6 ? (
                        <Alert className="col-12" message="Approved" type="success" showIcon />
                      ) : teamChangeStatus == 5 ? (
                        <Alert className="col-12" message="Clarification Required" banner />
                      ) : (
                        ''
                      )}
                    </span>

                  </div>
                  <ReactQuill
                    theme="snow"
                    value={tmsRemarks}
                    name="remarks"
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

            }

            {tmRemarks &&
              <div className="row my-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    TM Remark
                  </label>
                  <hr className="lightgreen" />
                  <div className="row  my-4">
                    <span >
                      {teamChangeStatus == 8 ? (
                        <Alert className="col-12" message="Approved" type="success" showIcon />
                      ) : teamChangeStatus == 7 ? (
                        <Alert className="col-12" message="Clarification Required" banner />
                      ) : (
                        ''
                      )}
                    </span>

                  </div>

                  <ReactQuill
                    theme="snow"
                    value={tmRemarks}
                    name="remarks"
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>}


            {/* {
              teamChangeStatus == 7 || teamChangeStatus == 8 ?

                <div className="row my-4">
                  <div>
                    <label className="form-label lightgreen fs-6 mb-3">
                      TM Remark
                    </label>
                    <hr className="lightgreen" />
                    <div className="row  my-4">
                      <span >
                        {teamChangeStatus == 8 ? (
                          <Alert className="col-12" message="Approved" type="success" showIcon />
                        ) : teamChangeStatus == 7 ? (
                          <Alert className="col-12" message="Clarification Required" banner />
                        ) : (
                          ''
                        )}
                      </span>

                    </div>

                    <ReactQuill
                      theme="snow"
                      value={tmRemarks}
                      name="remarks"
                      readOnly={true}
                      style={{

                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>


                :
                ''
            } */}

          </>
        }
      </form>

    </div>
  )
}

export default TLSBUTeamChange
