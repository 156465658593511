import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, registrar_rfi_kickout_url } from "../../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../../config";
import { get_KickoutInfo_url } from "../../../config";
import LoaderComponent from "../../../utlis/LoderComponent";


const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const RegistrarKickoutRFIRound = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [remarkRegistarRFI, setRemarkRegistrarRFI] = useState("");
  const [rfiStatus, setRfiStatus] = useState("");
  const [action, setAction] = useState("");
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null);
  const [auditFinding, setAuditFinding] = useState("");
  const [fvrName, setFVRName] = useState(null);
  const [fvr, setFvr] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [otherDocName, setOtherDocName] = useState(null);
  const [otherDoc, setOtherDoc] = useState("");

  const [registrarRFIDateTo, setRegistrarRFIDateTo] = useState(null);

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { kickout } = response.data;

      setRfiStatus(record.kickout_status);
      setRegistrarRFIDateTo(kickout.rfi_registrar_uploading_date);
      setRemarkRegistrarRFI(kickout.rfi_registrar_remarks);

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.rfi_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_cpa_document}`;
      setCpaName(url2);
      setCpa(kickout.rfi_cpa_document);

      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_err_document}`;
      setERName(url3);
      setER(kickout.rfi_err_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_pdd_document}`;
      setPDDName(url4);
      setPdd(kickout.rfi_pdd_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_audit_finding}`;
      setAuditFindingName(url5);
      setAuditFinding(kickout.rfi_audit_finding);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_irr_document}`;
      setRRName(url6);
      setRR(kickout.rfi_irr_document);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_other_docs}`;
      setOtherDocName(url7);
      setOtherDoc(kickout.rfi_other_docs);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    // Return the date in the format yyyy-mm-dd
    return `${year}-${month}-${day}`;
  };

  const handleToDateChange = (e) => {
    const value = e.target.value;
    setRegistrarRFIDateTo(value);
  };

  const handleRemarksChange = (content) => {
    setRemarkRegistrarRFI(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (
      remarkRegistarRFI == null ||
      remarkRegistarRFI == "<p><br></p>" ||
      remarkRegistarRFI == ""
    ) {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        // setLoading(true);
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append(
          "rfi_registrar_uploading_date",
          registrarRFIDateTo
        );
        formDataToSend.append("registrar_rfi_remarks", remarkRegistarRFI);
        formDataToSend.append("project_status", action);

        const response = await axios.post(
          `${registrar_rfi_kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");

          setRemarkRegistrarRFI("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }
    }
  };

  return (
    <>
      {rfiStatus == 28 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Document Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td className="text-center lightgreen p-3">
                    FVR Reportdddddd
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={fvrName} target="_blank">
                      {fvr}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">2</td>
                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={erName} target="_blank">
                      {er}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">3</td>
                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>
                  <td className="text-center lightgreen p-3">
                    CPA
                  </td>

                  <td>
                    <a href={cpaName} target="_blank">
                      {cpa}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>
                  <td className="text-center lightgreen p-3">
                    IRR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={rrName} target="_blank">
                      {rr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>
                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={auditFindingName} target="_blank">
                      {auditFinding}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>
                  <td className="text-center lightgreen p-3">
                    Other Document
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={otherDocName} target="_blank">
                      {otherDoc}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Registrar Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="kickout"
                    name="kickout"
                    value={11}
                    onChange={handleActionChange}
                    className="text-danger"
                  />
                  <label className="text-danger mx-1" htmlFor="kickout">
                    Kick Out
                  </label>
                </span>

                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="issued"
                    name="kickout"
                    value={12}
                    onChange={handleActionChange}
                    className="text-success"
                  />
                  <label className="text-success mx-1" htmlFor="kickout">
                    Issued Registered
                  </label>
                </span>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkRegistarRFI}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkRegistarRFI }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-3 mb-3 ">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    RFI Submission Date<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-4 mb-3 ">
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="rfidate"
                    required
                    name="rfidate"
                    onChange={handleToDateChange}
                    min={getCurrentDate()}
                  />
                </div>
              </div>
            </div>
            <div className="container my-4 d-flex justify-content-end pr-0">
              <button
                className="btn my-4 px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </>
      ) : (
        <form
          method="post"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                  Document Name
                </th>

                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                  Uploaded Document
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>
                <td className="text-center lightgreen p-3">
                  FVR Report
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={fvrName} target="_blank">
                    {fvr}
                  </a>
                </td>
              </tr>
              <tr>
                <td class="text-center">2</td>
                <td className="text-center lightgreen p-3">
                  ER
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={erName} target="_blank">
                    {er}
                  </a>
                </td>
              </tr>
              <tr>
                <td class="text-center">3</td>
                <td className="text-center lightgreen p-3">
                  PDD/MR
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={pddName} target="_blank">
                    {pdd}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">4</td>
                <td className="text-center lightgreen p-3">
                  CPA
                </td>

                <td>
                  <a href={cpaName} target="_blank">
                    {cpa}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">5</td>
                <td className="text-center lightgreen p-3">
                  IRR
                </td>

                <td>
                  <a href={rrName} target="_blank">
                    {rr}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">5</td>
                <td className="text-center lightgreen p-3">
                  Audit Finding
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={auditFindingName} target="_blank">
                    {auditFinding}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">5</td>
                <td className="text-center lightgreen p-3">
                  Other Document
                </td>

                <td>
                  <a href={otherDocName} target="_blank">
                    {otherDoc}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          {/* <div>
            <p className="lightgreen fw-bold my-3 fs-6">Registrar Remarks</p>
            <hr className="mb-3 lightgreen" />

            <div className="row mt-4">
              <div className="col-12">
                <ReactQuill
                  theme="snow"
                  value={remarkRegistarRFI}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkRegistarRFI }}
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-3 mb-3 ">
                <label
                  htmlFor="visit Date"
                  className="form-label lightgreen fs-6"
                >
                  RFI Submission Date<span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="col-4 mb-3 ">
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="rfidate"
                  required
                  name="rfidate"
                  value={registrarRFIDateTo}
                  disabled
                />
              </div>
            </div>
          </div> */}

        </form>
      )}
    </>
  );
};

export default RegistrarKickoutRFIRound;
