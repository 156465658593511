import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER,workload_stage_wise_url } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function SBUWorkloadGraph({search,startDate,endDate})
{
   const [projectUndergoing, setProjectUndergoing]= useState([]);
   const [projectRFI, setprojectRFI]= useState([]);
   const [projectKickout, setprojectKickout]= useState([]);

   const [projectCompleted, setProjectCompleted]= useState([]);
   const [sbuName, setSBUName] = useState([]);
   const [programWiseName, setProgramWiseName] = useState([]);
   const [programWiseData, setProgramWiseData] = useState([]);

   const navigate = useNavigate();
   

   useEffect( ()=>{
       const projectundergoing=[];
       const projectcompleted=[];
       const projectrfi=[];
       const projectKickout=[];

       const sbuname=[];

       const getProposalScopeWise= async()=>{

        const payload = { 
            "fy": "",
            'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
            'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
          };

       const reqData= await axios.post(`${workload_stage_wise_url}`, payload,API_HEADER);
       const MyprogramwiseData = reqData.data.sbu_workloads
       
       for(let i=0; i< MyprogramwiseData.length; i++)
       {        
        projectundergoing.push(((MyprogramwiseData[i].project_undergoing)));
        projectcompleted.push(((MyprogramwiseData[i].project_completed)));
        projectrfi.push(((MyprogramwiseData[i].project_rfi)));
        projectKickout.push(((MyprogramwiseData[i].project_kickout)));
        sbuname.push(((MyprogramwiseData[i].name)));      
       }
       
       setProjectUndergoing(projectundergoing);
       setProjectCompleted(projectcompleted);
       setSBUName(sbuname);
       setProgramWiseData(MyprogramwiseData);
       setprojectRFI(projectrfi);
       setprojectKickout(projectKickout);

       }

       getProposalScopeWise();

   },[search]);


   const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {

    const seriesName = w.globals.seriesNames[seriesIndex];

    const programName = w.globals.labels[dataPointIndex];

    const matchedProgram = programWiseData.find((program) => program.name === programName);

    const sbu_head = matchedProgram ? matchedProgram.sbu_head : null;
  
    const title = w.globals.seriesNames[seriesIndex];

    if (sbu_head) {
      navigate('/programwiseprojecttable', { state: { title,workload_status:seriesName, sbu_head, programName} });
    }

  };

   return(
    <React.Fragment>
        <div className="container-fluid">
           
            <Chart
            type="bar"
            width={"100%"}
            height={400}
            series={[
                {
                    name:"Ongoing Projects",
                    data: projectUndergoing,
                    color: '#2776ae'
                },

                {
                    name:"RFI Completed",
                    data: projectRFI,
                    color: '#65ddf7'
                },

                {
                    name:"Under Kickout",
                    data: projectKickout,
                    color: '#f37324'
                },
                
                {
                    name:"Issued/Registered",
                    data: projectCompleted,
                    color: '#08415C'
                },
                
            ]}

            options={{
                // title:{
                //     text:"Proposal Month Wise"
                // },
                chart:{
                    toolbar: {
                        show: false,  // This will hide the icons on the top right corner
                      },
                    stacked:true,
                    events: {
                        dataPointSelection: handleChartClick,
                      },
                },
                
                plotOptions:{
                    bar:{
                        horizontal:false,
                        columnWidth:'40%',
                        dataLabels : {
                            total: {
                                enabled: true,
                                style: {
                                  fontSize: '11px',
                                  fontWeight: 900
                                },
                                
                              }
                          }
                      }
                },
                stroke: {
                    width: 1,
                },
                xaxis:{
                    title:{
                        text:"SBU Head",
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold'
                        }
                    },
                    categories : sbuName
                },
                yaxis:{
                    title:{
                        text:"Number of Projects",
                        style:{fontSize:'16px',fontWeight:'bold'}
                    },
                    labels:{
                        style: { fontSize: "15"},

                    }
                },
                legend:{
                    position: 'bottom',
                },
                dataLabels:{
                    enabled:false,
                },
                grid: {
                    show:true,
                    xaxis:{
                        lines:{
                            show:false
                        }
                    },
                    yaxis:{
                        lines:{
                            show:false
                        }
                    }

                },
                grid: {
                    borderColor: '#f1f1f1',
                    row: {
                      colors: ['transparent'], 
                      opacity: 1,
      
                    },
                  },

            }}

            />
        </div>
    </React.Fragment>
);
}
export default SBUWorkloadGraph;