import React from 'react'
import { Input, Table, Tabs, DatePicker, Button, Select } from "antd";

import { Tooltip as Tip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileCircleQuestion,
    faFileCircleCheck,
    faFileArrowDown,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
    API_HEADER,
    getDashboardData,
    at_tl_data_url,
    sbu_data_url,
    getCountryList,
    get_client_name_url,
    get_scope_url,
    get_program_url,
    get_kickout_url,
    dasboard_counter_url,
} from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import LegendTable from "../LegendTable";
import ProjectNameWithDelay from '../ProjectNameWithDelay';

const { Option } = Select;

const RegistrarDash = () => {


    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [rfiApproved, setRFIApproved] = useState(0);
    const [rfiCompleted, setRFICompleted] = useState(0);
    const [kickoutClarification, setKickOutClarification] = useState(0);
    const [issuedRegistered, setIssuedRegistered] = useState(0);
    let [loader, Setloader] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const getDashData = async () => {
        try {
            const result = await axios.get(`${getDashboardData}`, API_HEADER);
            const dashboard = result.data.dashboard;
            setRFIApproved(dashboard.status9);
            setRFICompleted(dashboard.status10);
            setKickOutClarification(dashboard.status11);
            setIssuedRegistered(dashboard.status12);
           
        } catch (error) {
        }
    };

    useEffect(() => {
        getDashData();
    }, []);

    const [statuskey, setStatus] = useState(20);
    const [kickoutStatus, setKickoutStatus] = useState('');
    const [projectstatus, setProjectStatus] = useState(9);

    const [alldata, setAlldata] = useState([]);

    const [activeKey, setActiveKey] = useState("1");

    const allData = async () => {
        try {
            let payload = {
                project_status: projectstatus,
                page: pagination.current,
                limit: pagination.pageSize,
                fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
                toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
                country: country ? country : null,
                client_id: client_id ? client_id : null,
                program: program ? program : null,
                scope: scope ? scope : null,
                search: search ? search : null,
                status:statuskey,
            
            };
            const response = await axios.post(
                `${at_tl_data_url}`,
                payload,
                API_HEADER
            );
            setAlldata(response.data.records.data);

            setPagination((prevPagination) => ({
                ...prevPagination,
                total: response.data.records.total,
            }));

            Setloader(false);
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const handleTabChange = (key) => {
        setFromDate(null);
        setToDate(null);
        setCountry(null);
        setClient_id(null);
        setProgram(null);
        setScope(null);
        setSearch(null);

        setActiveKey(key);

        setPagination((prevPagination) => ({
            ...prevPagination,
            current: 1,
        }));

        if (key == 1) {
            setStatus(20);
            setProjectStatus(9);
        
        } else if (key == 2) {
            setStatus('');
            setProjectStatus(10);
        } else if (key == 3) {
            setStatus('');
            setProjectStatus(11);
        }else if (key == 4) {
            setStatus('');
            setProjectStatus(12);
        }
    
        Setloader(true);
    };

    useEffect(() => {
        allData();
    }, [loader]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        Setloader(true);
    };

    const dateFormat = "DD/MM/YYYY";
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [country, setCountry] = useState(null);
    const [client_id, setClient_id] = useState(null);
    const [program, setProgram] = useState(null);
    const [search, setSearch] = useState(null);

    const handleSearch = (value) => {
        setSearch(value);
        Setloader(true);
    };

    const editFormForKickOut = async (id) => {
        navigate(`/kickoutmodule/${id}`)
    }

    const editFormForRFIApproved = async (id) => {
        navigate(`/atdeskreview/${id}`)
    }

    const handleFromDateChange = (date) => {
        setFromDate(date);
        Setloader(true);
    };
    
    const disabledFutureDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const handleToDateChange = (date) => {
        setToDate(date);
        Setloader(true);
    };

    const handleSearchByDateRange = (value) => {
        const currentDate = moment();
        if (fromDate && fromDate.isAfter(currentDate)) {
            toast.error("From date cannot be a future date");
        } else if (toDate && toDate.isAfter(currentDate)) {
            toast.error("To date cannot be a future date");
        } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
            toast.error("From date cannot be greater than to date");
        } else {
            Setloader(true);
        }
    };
    const handleClientNameSearch = (value) => {
        setClient_id(value);
        Setloader(true);
    };
    const handleProgramNameSearch = (value) => {
        setProgram(value);
        Setloader(true);
    };
    const handleCountrySearch = (value) => {
        setCountry(value);
        Setloader(true);
    };
    const handleScopeSearch = (value) => {
        setScope(value);
        Setloader(true);
    };

    const handleSearchAll = (value) => {
        setSearch(value);
        Setloader(true);
    };

    const [scope, setScope] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [scopeList, setScopeList] = useState([]);
    const [clientname, setClientname] = useState([]);
    const [programname, setProgramname] = useState([]);
    const getCountry = async () => {
        try {
            const result = await axios.get(`${getCountryList}`);
            setCountryList(result.data.data);
        } catch (error) {
        }
    };
    const getClientname = async () => {
        try {
            const result = await axios.get(`${get_client_name_url}`);
            setClientname(result.data.data);
        } catch (error) {
        }
    };
    const getProgramname = async () => {
        try {
            const result = await axios.get(`${get_program_url}`);
            setProgramname(result.data.data);
        } catch (error) {
        }
    };
    const getScope = async () => {
        try {
            const result = await axios.get(`${get_scope_url}`);
            setScopeList(result.data.data);
        } catch (error) {
        }
    };
    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        getCountry();
        getClientname();
        getProgramname();
        getScope();
    }, []);

    const columnRFIApproved = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
            defaultSortOrder: 'ascend',
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    EID
                </span>
            ),
            width : 90,

            render: (text, record) => {
                return (
                    <Tip title={record.earthood_id}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                      {record.earthood_id}
                    </span>
                  </Tip>
                );
            },
            sorter: (record1, record2) => {
                return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
            },
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Client Name
                </span>
            ),
            width : 140,
            render: (text, record) => (
                                <Tip title={record.client_name?.name}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.client_name?.name}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Project Name
                </span>
            ),
            width : 160,
            render: (text, record) => (
                <ProjectNameWithDelay record={record}/>
            ),
            sorter: (record1, record2) => {
                return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Scope
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.scope_name?.scope}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.scope_name?.scope}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Program
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.program_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.program_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Country
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.country_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.country_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Status
                </span>
            ),
            width: 140,
            render: (text, record) => {
                let color = "red";
                let msg = "";
                
                if (record.status == 20) {
                    msg = "MD approved RFI";
                    color = 'black'
                } else if (record.status == 21) {
                    msg = "Registrar approved and RFI completed";
                    color = 'green'
                }
                else {
                    msg = record.status;
                }

                return (
                
                    <Tip title={msg}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis" style={{ color }}>
                  {msg}
                </span>
              </Tip>
                );
            },

        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Action
                </span>
            ),
            key: "x",
            fixed: "right",
            width: 90,
            render: (text, record) => (
                <a className="">


                    <EditOutlined
                        onClick={() => editFormForRFIApproved(record.id)}
                        style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                    />
                </a>
            ),

        },
    ];

    const columnRFICompleted = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
            defaultSortOrder: 'ascend',
        },
       
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    EID
                </span>
            ),
            width : 90,
            render: (text, record) => {
                return (
                    <Tip title={record.earthood_id}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                      {record.earthood_id}
                    </span>
                  </Tip>
                );
            },
            sorter: (record1, record2) => {
                return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
            },
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Client Name
                </span>
            ),
            width : 140,
            render: (text, record) => (
                                <Tip title={record.client_name?.name}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.client_name?.name}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Project Name
                </span>
            ),
            width : 180,
            render: (text, record) => (
                <ProjectNameWithDelay record={record}/>

            ),
            sorter: (record1, record2) => {
                return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Scope
                </span>
            ),
            width : 100,
            render: (text, record) => (
                <Tip title={record.scope_name?.scope}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.scope_name?.scope}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Program
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.program_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.program_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Country
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.country_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.country_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Status
                </span>
            ),
            width: 140,
            render: (text, record) => {
                let color = "red";
                let msg = "";
                if (record.status == 20) {
                    msg = "MD approved RFI";
                    color = 'black'
                } else if (record.status == 21) {
                    msg = "Registrar approved and RFI completed";
                    color = 'green'
                }
                else {
                    msg = record.status;
                }

                return (
                    // <p className="px-lg-4 py-lg-2 rounded-5 text-sm" style={{ color }}>
                    //     {msg}
                    // </p>
                     <Tip title={msg}>
                     <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis" style={{ color }}>
                       {msg}
                     </span>
                   </Tip>
                );
            },

        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Action
                </span>
            ),
            key: "x",
            fixed: "right",
            width: 90,
            render: (text, record) => (
                <a className="">


                    <EyeOutlined
                        onClick={() => editFormForKickOut(record.id)}
                        style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                    />
                </a>
            ),

        },
    ];

    const columnKickOutClarification = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    EID
                </span>
            ),
            width : 90,

            render: (text, record) => {
                return (
                    <Tip title={record.earthood_id}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                      {record.earthood_id}
                    </span>
                  </Tip>
                );
            },
            sorter: (record1, record2) => {
                return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
            },
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Client Name
                </span>
            ),
            width : 140,
            render: (text, record) => (
                                <Tip title={record.client_name?.name}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.client_name?.name}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Project Name
                </span>
            ),
            width : 160,
            render: (text, record) => (
                // <span className="text-capitalize font14px textcolor">{record.project_name}</span>
                <ProjectNameWithDelay record={record}/>

            ),
            sorter: (record1, record2) => {
                return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Scope
                </span>
            ),
            width : 80,
            render: (text, record) => (
                <Tip title={record.scope_name?.scope}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.scope_name?.scope}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Program
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.program_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.program_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Country
                </span>
            ),
            width : 90,
            render: (text, record) => (
                <Tip title={record.country_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.country_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Status
                </span>
            ),
            width: 140,
            render: (text, record) => {
                let color = "red";
                let msg = "";
                if (record.kickout_status == 1) {
                    msg = "Sent to TL";
                    color = 'black'
                }
                else if (record.kickout_status == null || record.kickout_status == 0) {
                    msg = "Kick out not Started";
                }
                else if (record.kickout_status ==2) {
                    msg = "Team Member forwarded to TL";
                }
                else if (record.kickout_status == 3) {
                    msg = "TL sent to team members";
                }
                else if (record.kickout_status == 4) {
                    msg = "Sent to Client";
                }
                else if (record.kickout_status == 5) {
                    msg = "Client Resubmitted";
                }
                else if (record.kickout_status == 6) {
                    msg = "TL sent to TR";
                }
                else if (record.kickout_status == 7) {
                    msg = "TL sent for TR Round";
                }
                else if (record.kickout_status == 8) {
                    msg = "Sent to team member in TR Round";
                }
                else if (record.kickout_status == 9) {
                    msg = "Forward to TL in TR Round";
                }
                else if (record.kickout_status == 10) {
                    msg = "Sent to Client in TR Round";
                }

                else if (record.kickout_status == 11) {
                    msg = "Client Resubmitted in TR Round";
                }
                else if (record.kickout_status == 12) {
                    msg = "TR raised clarification";
                }
                else if (record.kickout_status == 13) {
                    msg = "TR approved";
                }
                else if (record.kickout_status == 14) {
                    msg = "Sent to SBU for CC allocation";
                }
                else if (record.kickout_status == 15) {
                    msg = "SBU head allocated the CC";
                }
                else if (record.kickout_status == 16) {
                    msg = "CC raised clarification";
                }
                else if (record.kickout_status == 17) {
                    msg = "TL forwarded to client";
                }
                else if (record.kickout_status == 18) {
                    msg = "client resubmitted";
                }
                else if (record.kickout_status == 19) {
                    msg = "TL resubmitted to CC";
                }
                else if (record.kickout_status == 20) {
                    msg = "TL forwarded to TR";
                }
                else if (record.kickout_status == 21) {
                    msg = "TR raised clarification";
                }
                else if (record.kickout_status == 22) {
                    msg = "TR approved cc round";
                }
                // else if (record.kickout_status == 23) {
                //     msg = "CC approved";
                // }
                // else if (record.kickout_status == 24) {
                //     msg = "TL submitted to TM for RFI";
                // }
                // else if (record.kickout_status == 25) {
                //     msg = "TM raised clarification in RFI round";
                // }
                // else if (record.kickout_status == 26) {
                //     msg = "TM approved RFI";
                // }

                // else if (record.kickout_status == 27) {
                //     msg = "MD raised clarification in RFI round";
                // }

                // else if (record.kickout_status == 28) {
                //     msg = "MD approved RFI";
                // }

                else if (record.kickout_status == 23) {
                    // msg = "CC approved";
                    msg = "TL to CC Reviewer";
          
                    
                }
                else if (record.kickout_status == 24) {
                    // msg = "TL submitted to TM for RFI";
                    msg = "CC Approved";
          
                }
                else if (record.kickout_status == 25) {
                    // msg = "TM raised clarification in RFI round";
                    msg = "AT Sent to TL";
          
                }
                else if (record.kickout_status == 26) {
                    // msg = "TM approved RFI";TL Sent to AT
                    msg = "TL Sent to AT";
                }
          
                else if (record.kickout_status == 27) {
                    // msg = "MD raised clarification in RFI round";
                    msg = "TL Sent to TM";
                }
          
                else if (record.kickout_status == 28) {
                    // msg = "MD approved RFI";
                    msg = "TM Raised Clarification";
                }
                else if (record.kickout_status == 29) {
                  msg = "TM Approved";
              }
              else if (record.kickout_status == 30) {
                msg = "MD Raised Clarification";
            }
            else if (record.kickout_status == 31) {
              msg = "MD Approved";
          } else if (record.kickout_status == 32) {
            msg = " Kickout RFI Completed";
        }
                return (
                  
                     <Tip title={msg}>
                     <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis" style={{ color }}>
                       {msg}
                     </span>
                   </Tip>
                );
            },

        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Action
                </span>
            ),
            dataIndex: "",
            key: "x",
            fixed: "right",
            width: 90,
            render: (text, record) => (
                <a className="">
                    {
                        record.kickout_status == null  || record.kickout_status == 31 ?
                            <EditOutlined
                                onClick={() => editFormForKickOut(record.id)}
                                style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                            />
                            :
                            <EyeOutlined
                                onClick={() => editFormForKickOut(record.id)}
                                style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                            />
                    }


                </a>
            ),
        },
    ];
  
    const columnKickOutApproved = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
        },
       
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    EID
                </span>
            ),
            width : 90,

            render: (text, record) => {
                return (
                    <Tip title={record.earthood_id}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                      {record.earthood_id}
                    </span>
                  </Tip>
                );
            },
            sorter: (record1, record2) => {
                return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
            },
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Client Name
                </span>
            ),
            width : 140,
            render: (text, record) => (
                                <Tip title={record.client_name?.name}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.client_name?.name}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.client_name?.name > record2.client_name?.name) ? 1 : (record1.client_name?.name === record2.client_name?.name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Project Name
                </span>
            ),
            width : 180,
            render: (text, record) => (
                <ProjectNameWithDelay record={record}/>
            ),
            sorter: (record1, record2) => {
                return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Scope
                </span>
            ),
            width : 100,
            render: (text, record) => (
                <Tip title={record.scope_name?.scope}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.scope_name?.scope}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.scope_name?.scope > record2.scope_name?.scope) ? 1 : (record1.scope_name?.scope === record2.scope_name?.scope) ? 0 : -1
            }
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Program
                </span>
            ),
            width : 80,
            render: (text, record) => (
                // <span className="text-capitalize font14px textcolorgreen">{record.program_name?.description}</span>
                <Tip title={record.program_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.program_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.program_name?.description > record2.program_name?.description) ? 1 : (record1.program_name?.description === record2.program_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Country
                </span>
            ),
            width : 90,

            render: (text, record) => (
                // <span className="text-capitalize font14px">{record.country_name?.description}</span>
                <Tip title={record.country_name?.description}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                  {record.country_name?.description}
                </span>
              </Tip>
            ),
            sorter: (record1, record2) => {
                return (record1.country_name?.description > record2.country_name?.description) ? 1 : (record1.country_name?.description === record2.country_name?.description) ? 0 : -1
            }
        },

        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Action
                </span>
            ),
            dataIndex: "",
            key: "x",
            fixed: "right",
            width: 80,
            render: (record) => (
                <EyeOutlined
                    onClick={() => editFormForRFIApproved(record.id)}
                    style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                />
            ),
        },
    ];
    return (
        <>

            <div className="container-fluid bg-white">
                <div className="row">
                    <div className="col-12">
                        <Tabs
                            defaultActiveKey="1"
                            centered
                            activeKey={activeKey}
                            onChange={handleTabChange}
                        >

                         
                             <Tabs.TabPane
                                tab={
                                    <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 1 ? 'box_tab' : ''}`}>
                                        <p className="font14px textlightblack text-capitalize text-wrap">
                                        RFI Approved
                                        </p>                                          
                                      <div className="paracenter">                                   
                                        <p className="textcolorblue stat_text">
                                        {rfiApproved}
                                        </p>
                                            <FontAwesomeIcon
                                                icon={faFileArrowDown}
                                                size="xl"
                                                className="iconcolor"
                                            />
                                        </div>
                                    </div>
                                }
                                key="1"
                            >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                                            <div className=" bg-white border-0 shadow-sm rounded-top-3">
                                                <div className="row border-0">
                                                    <div className="col-12">
                                                    <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                                            RFI Approved
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row gx-3 align-items-center p-2">

                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                client Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select client name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleClientNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {clientname.map((client, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={client.id}
                                                                        label={client.name}
                                                                    >
                                                                        {client.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="d-grid mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                Scope{" "}
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select scope"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleScopeSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>

                                                                {scopeList.map((scope, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={scope.id}
                                                                        label={scope.sector_name}
                                                                    >
                                                                        {scope.sector_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                program Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select program name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleProgramNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {programname.map((program, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={program.id}
                                                                        label={program.program_name}
                                                                    >
                                                                        {program.program_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                Country
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select country"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleCountrySearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {countryList.map((country, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={country.id}
                                                                        label={country.name}
                                                                    >
                                                                        {country.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-sm-4 col-md-3 mt-3 " style={{width:'100%'}}>
                                                        <Input.Search
                                                            allowClear
                                                            placeholder="Search By Project Name/EID"
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                            value={searchValue}
                                                            enterButton
                                                            onSearch={handleSearch}
                                                            style={{ width: "30%", }}
                                                            className="rounded-2 custom-search"
                                                        />                          </div>

                                                </div>
                                            </div>

                                            <Table
                                                scroll={{
                                                    x: 1000,
                                                }}
                                                columns={columnRFIApproved}
                                                loading={loader}
                                                dataSource={alldata}
                                                rowKey="proposal_id"
                                                pagination={pagination}
                                                onChange={handleTableChange}
                                                bordered
                                            />
                                                {
                                                alldata != '' ?
                                                    (<>
                                                        <LegendTable />
                                                    </>) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Tabs.TabPane>

                             <Tabs.TabPane
                                tab={
                                    <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 2 ? 'box_tab' : ''}`}>
                                        <p className="font14px textlightblack text-capitalize text-wrap">
                                        RFI Completed
                                        </p>                                          
                                      <div className="paracenter">                                   
                                        <p className="textcolorblue stat_text">
                                        {rfiCompleted}
                                        </p>
                                            <FontAwesomeIcon
                                                icon={faFileArrowDown}
                                                size="xl"
                                                className="iconcolor"
                                            />
                                        </div>
                                    </div>
                                }
                                key="2"
                            >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                                            <div className=" bg-white border-0 shadow-sm rounded-top-3">
                                                <div className="row border-0">
                                                    <div className="col-12">
                                                    <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                                            RFI Completed
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row gx-3 align-items-center p-2">

                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                client Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select client name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleClientNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {clientname.map((client, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={client.id}
                                                                        label={client.name}
                                                                    >
                                                                        {client.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="d-grid mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                Scope{" "}
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select scope"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleScopeSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>

                                                                {scopeList.map((scope, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={scope.id}
                                                                        label={scope.sector_name}
                                                                    >
                                                                        {scope.sector_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                program Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select program name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleProgramNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {programname.map((program, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={program.id}
                                                                        label={program.program_name}
                                                                    >
                                                                        {program.program_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                Country
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select country"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleCountrySearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {countryList.map((country, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={country.id}
                                                                        label={country.name}
                                                                    >
                                                                        {country.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-sm-4 col-md-3 mt-3" style={{width:'100%'}}>
                                                        <Input.Search
                                                            allowClear
                                                            placeholder="Search By Project Name/EID"
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                            value={searchValue}
                                                            enterButton
                                                            onSearch={handleSearch}
                                                            style={{ width: "30%", }}
                                                            className="rounded-2 custom-search"
                                                        />                          </div>

                                                </div>
                                            </div>

                                            <Table
                                                scroll={{
                                                    x: 1000,
                                                }}
                                                columns={columnRFICompleted}
                                                loading={loader}
                                                dataSource={alldata}
                                                rowKey="proposal_id"
                                                pagination={pagination}
                                                onChange={handleTableChange}
                                                bordered
                                            />
                                                {
                                                alldata != '' ?
                                                    (<>
                                                        <LegendTable />
                                                    </>) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Tabs.TabPane>

                         
                             <Tabs.TabPane
                                tab={
                                    <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 3 ? 'box_tab' : ''}`}>
                                        <p className="font14px textlightblack text-capitalize text-wrap">
                                        Under  KickOut
                                        </p>                                          
                                      <div className="paracenter">                                   
                                        <p className="textcolorblue stat_text">
                                        {kickoutClarification}
                                        </p>
                                            <FontAwesomeIcon
                                                icon={faFileCircleQuestion}
                                                size="xl"
                                                className="iconcolor"
                                            />
                                        </div>
                                    </div>
                                }
                                key="3"
                            >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                                            <div className="bg-white border-0 shadow-sm rounded-top-3">
                                                <div className="row border-0">
                                                    <div className="col-12">
                                                    <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                                            KickOut Clarification
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row gx-3 align-items-center p-2">

                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                client Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select client name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleClientNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {clientname.map((client, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={client.id}
                                                                        label={client.name}
                                                                    >
                                                                        {client.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="d-grid mb-3">
                                                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                                                                Scope{" "}
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select scope"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleScopeSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>

                                                                {scopeList.map((scope, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={scope.id}
                                                                        label={scope.sector_name}
                                                                    >
                                                                        {scope.sector_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                program Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select program name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleProgramNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {programname.map((program, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={program.id}
                                                                        label={program.program_name}
                                                                    >
                                                                        {program.program_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                                                                Country
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select country"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleCountrySearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {countryList.map((country, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={country.id}
                                                                        label={country.name}
                                                                    >
                                                                        {country.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                   

                                                    <div className="col-sm-4 col-md-3 mt-3" style={{width:'100%'}}>
                                                        <Input.Search
                                                            allowClear
                                                            placeholder="Search By Project Name/EID"
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                            value={searchValue}
                                                            enterButton
                                                            onSearch={handleSearch}
                                                            style={{ width: "30%", }}
                                                            className="rounded-2 custom-search"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <Table
                                                scroll={{
                                                    x: 1000,
                                                }}
                                                columns={columnKickOutClarification}
                                                loading={loader}
                                                dataSource={alldata}
                                                rowKey="proposal_id"
                                                pagination={pagination}
                                                onChange={handleTableChange}
                                                bordered
                                            />
                                                {
                                                alldata != '' ?
                                                    (<>
                                                        <LegendTable />
                                                    </>) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Tabs.TabPane>

                           
                             <Tabs.TabPane
                                tab={
                                    <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${activeKey == 4 ? 'box_tab' : ''}`}>
                                        <p className="font14px textlightblack text-capitalize text-wrap">
                                        Issued/Registered
                                        </p>                                          
                                      <div className="paracenter">                                   
                                        <p className="textcolorblue stat_text">
                                        {issuedRegistered}
                                        </p>
                                            <FontAwesomeIcon
                                                icon={faFileCircleCheck}
                                                size="xl"
                                                className="iconcolor"
                                            />
                                        </div>
                                    </div>
                                }
                                key="4"
                            >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                                            <div className="bg-white border-0 shadow-sm rounded-top-3">
                                                <div className="row border-0">
                                                    <div className="col-12">
                                                    <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                                            Issued/Registered
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row gx-3 align-items-center p-2">

                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                client Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select client name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleClientNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {clientname.map((client, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={client.id}
                                                                        label={client.name}
                                                                    >
                                                                        {client.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="d-grid mb-3">
                                                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                                                                Scope{" "}
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select scope"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleScopeSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>

                                                                {scopeList.map((scope, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={scope.id}
                                                                        label={scope.sector_name}
                                                                    >
                                                                        {scope.sector_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle  fw-bold font12px">
                                                                program Name
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select program name"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleProgramNameSearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {programname.map((program, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={program.id}
                                                                        label={program.program_name}
                                                                    >
                                                                        {program.program_name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-3">
                                                        <div className="mb-3">
                                                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                                                                Country
                                                            </label>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select country"
                                                                optionFilterProp="children"
                                                                filterOption={filterOption}
                                                                onChange={handleCountrySearch}
                                                                style={{ width: "100%", }}
                                                                className="rounded-2"
                                                            >
                                                                <Option value="">Select</Option>
                                                                {countryList.map((country, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        value={country.id}
                                                                        label={country.name}
                                                                    >
                                                                        {country.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    

                                                    <div className="col-sm-4 col-md-3 mt-3" style={{width:'100%'}}>
                                                        <Input.Search
                                                            allowClear
                                                            placeholder="Search By Project Name/EID"
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                            value={searchValue}
                                                            enterButton
                                                            onSearch={handleSearch}
                                                            style={{ width: "30%", }}
                                                            className="rounded-2 custom-search"
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <Table
                                                scroll={{
                                                    x: 1000,
                                                }}
                                                columns={columnKickOutApproved}
                                                loading={loader}
                                                dataSource={alldata}
                                                rowKey="proposal_id"
                                                pagination={pagination}
                                                onChange={handleTableChange}
                                                bordered
                                            />
                                                {
                                                alldata != '' ?
                                                    (<>
                                                        <LegendTable />
                                                    </>) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>


        </>
    )
}

export default RegistrarDash
