import React, { useEffect, useState } from 'react'
import { Tooltip as Tip } from "antd";

export default function ProjectStatusSchema({status}) {

  const [statuscode,setStatusCode]=useState('');

  useEffect(()=>{

    let msg='Inactive';

    if (status == 1) {
        msg = "Pending Docs Submission";
    }
    else if (status == 2) {
        msg = "Under LOD Review";
    } else if (status == 3) {
        msg = "Under Desk Review";
    } else if (status == 4) {
        msg = "Site Visit Completed";
    }else if (status == 5) {
        msg = "Site Visit Finding";
    } 
    else if (status == 6) {
        msg = "DVR Round";
    }
    else if (status == 7) {
        msg = "Technical Review";
    }else if (status == 8) {
        msg = "CC Review";
    }else if (status == 9) {
        msg = "Under RFI";
    }else if (status == 10) {
        msg = "RFI Completed";
    }else if (status == 11) {
        msg = "Under Kickout";
    }else if (status == 12) {
        msg = "Issued/Registered";
    }

    setStatusCode(msg);

  },[status])

  return (
    <>
    <Tip title={statuscode}>
       <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
         {statuscode}
       </span>
    </Tip>
    </>
  )
}
