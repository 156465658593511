import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { API_HEADER, get_dealLostReasons_url, get_sales_action_url, } from "../config";
const DealLost = (props) => {

    const projectid = props.proposalId
    const projectstatus = props.projectstatus

    const [dealLostremarks, setDealLostRemarks] = useState("");
    const [dealLostReason, setDealLostReason] = useState("");
    const [myDealLostReason, setMyDealLostReason] = useState([]);
    const [dealLost, setDealLost] = useState([])
                
    const location = useLocation();

    useEffect(() => {
      const fetchData = async () => {
        if (location.state && location.state.data) {
          const { data } = location.state;
        
          setDealLostReason(data.deal_lost_reason)
          setDealLostRemarks(data.deal_lost_remarks) 
          } 
        
      };
  
      fetchData();
    }, [location]);


    const handleRemarksChange = (content) => {
        setDealLostRemarks(content);
    };

    useEffect(() => {
        const fetchDealLostReasonsData = async () => {
          try {
            const responsedeallost = await axios.get(`${get_dealLostReasons_url}`);
            setMyDealLostReason(responsedeallost.data.data);
          } catch (error) {
          }
        };
    
        fetchDealLostReasonsData();
      }, []);
 
      
    const navigate = useNavigate()
    const handleDealLost = async (e) => {
        e.preventDefault()
        try {
            let payload = {
                proposal_id: projectid,
                type: 2,
                deal_lost_remarks: dealLostremarks,
                deal_lost_reason: dealLostReason
            };
            const response = await axios.post(
                `${get_sales_action_url}`,
                payload,
                API_HEADER
            );
            if (response.status === 200 && response.data.status == 1) {
                setDealLostRemarks("");
                toast.success("Reason and Remark added successfully");
                navigate("/dashboard");
            } else {
                console.error("Failed to submit");
                toast.error(response.data.message);
            }
        } catch (error) { }
    };


    return (


        <form
            method="post"
            onSubmit={handleDealLost}
            encType="json/form-data"
        >

            <div className="container">
                <div className="row">

                    <div className="col-lg-8 col-md-8 col-12 mb-3">
                        <label htmlFor="deallostreason" className="form-label lightgreen fs-6">
                            Deal Lost Reasons<span style={{ color: "red" }}>*</span>
                        </label>
                        {projectstatus !== 11?
                            <select
                                id="deal_reason_lost"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="deal_reason_lost"
                                required
                                value={dealLostReason}
                                onChange={(e) => setDealLostReason(e.target.value)}
                            >
                               <option value={""}>Select</option>
                                {myDealLostReason.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.reason}
                                  </option>
                                ))}
                            </select>
                            :
                            <select
                                id="deal_reason_lost"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="deal_reason_lost"
                                disabled
                                value={dealLostReason}
                                onChange={(e) => setDealLostReason(e.target.value)}
                            >
                                <option value={""}>Select</option>
                                {myDealLostReason.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.reason}
                                  </option>
                                ))}
                            </select>
                        }
                    </div>

                </div>

                <div className="row">
                    <div className="col-8">
                        <label htmlFor="deallostremarks" className="form-label lightgreen fs-6">
                            Remarks
                        </label>
                        {projectstatus !== 11 ?
                            <ReactQuill
                                theme="snow"
                                value={dealLostremarks}
                                name="deallostremarks"
                                dangerouslySetInnerHTML={{ __html: dealLostremarks }}
                                onChange={handleRemarksChange}

                                style={{
                                    width: "100%",
                                    height: "70%",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                            :
                            <ReactQuill
                                theme="snow"
                                value={dealLostremarks}
                                name="deallostremarks"
                                readOnly={true}
                                dangerouslySetInnerHTML={{ __html: dealLostremarks }}
                                onChange={handleRemarksChange}

                                style={{
                                    width: "100%",
                                    height: "70%",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        }
                    </div>

                    <div className="col-8 mt-3 d-flex justify-content-start gap-3">
                    {projectstatus !== 11 ?
                        <button
                            type="submit"
                            className="btn btn-success mt-5"
                        >
                        Submit
                        </button>
                        :
                        ''
                    }
                    </div>

                </div>

            </div>


        </form >
    )
}

export default DealLost



                